import * as React                      from "react";
import AboutUsView                     from "../Views/Pages/AboutUsView";
import { inject, observer }            from "mobx-react";
import { IAboutUsPageData, PageStore } from "../Store/PageStore";
import { observable }                  from "mobx";

interface AboutUsViewModelProps {
    PageStore?: PageStore;
}

@inject(PageStore.NAME_STORE)
@observer
class AboutUsViewModel extends React.Component<AboutUsViewModelProps, {}> {
    @observable
    private data: IAboutUsPageData;

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.data = await this.pageStore.getCurrentAboutUs();
    };

    public render(): React.ReactNode {
        return (
            <AboutUsView
                gallery={this.data ? this.data.gallery : undefined}
                galleryCarousel={this.data ? this.data.galleryCarousel : undefined}
                aboutUs={this.data ? this.data.aboutUs : undefined}
            />
        );
    }
}

export default AboutUsViewModel;
