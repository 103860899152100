import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import { Container }                      from "@material-ui/core";
import Box                                from "@material-ui/core/Box";
import Carousel                           from "../Components/Carousel/Carousel";
import { Col, Row }                       from "reactstrap";
import { GalleryListViewModel }           from "../../ViewModel/Gallery/GalleryListViewModel";
import { Gallery }                        from "../../Models/Gallery/Gallery";
import { AboutUs }                        from "../../Models/AboutUs/AboutUs";
import Seo                                from "../Components/Header/Seo";

interface AboutUsViewProps extends WithNamespaces {
    prefix?: string;
    gallery: Gallery | undefined;
    galleryCarousel: Gallery | undefined;
    aboutUs: AboutUs | undefined;
}

@InjectPrefix
class AboutUsView extends React.Component<AboutUsViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  aboutUs,
                  gallery,
                  galleryCarousel,
                  t,
              } = this.props;

        return (
            <div className={`${this.prefix}-p-gallery`}>
                <Seo seoData={aboutUs ? aboutUs.getSeoData() : undefined}/>
                <Carousel
                    gallery={galleryCarousel}
                />
                <Container>
                    <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("about-us")}
                    </h1>
                    <hr/>
                    <Row>
                        <Col xs={12} sm={8}>
                            <Box my={2}>
                                <div dangerouslySetInnerHTML={{__html: aboutUs ? aboutUs.getDescription() : ""}}/>
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <GalleryListViewModel gallery={gallery}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withNamespaces("page")(AboutUsView);
