import { WithNamespaces, withNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { Order }                          from "../../../Models/Order/Order";
import User                               from "../../../Models/User/User";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import Container                          from "@material-ui/core/Container";
import Status                             from "../../Components/Order/Status";
import DetailsOrder                       from "../../Components/Order/DetailsOrder";
import TableProducts                      from "../../Components/Table/TableProducts";
import moment                             from "moment";
import formatDate                         from "../../../config/formatDate";
import TableProductsFooter                from "../../Components/Table/TableProductsFooter";

interface IOrderDetailViewProps extends WithNamespaces {
    user: User;
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
class OrderDetailView extends React.Component<IOrderDetailViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, order} = this.props;

        return (
            <Container className={`${this.prefix}-p-order-detail`}>
                <Status order={order}/>
                <hr/>
                <h4 className="title">
                    {t("order.order")} {order.getIdentifier()} | <span>{t("order.order-date")} {moment(order.getCreatedAt()).format(formatDate.shortDateYear)}</span>
                </h4>
                <DetailsOrder order={order}/>
                <TableProducts
                    productItemCars={order.getProductStore()}
                    order={order}
                    hideTotal
                    notEdit
                    children={
                        <TableProductsFooter
                            hideTransport={order.getMethodTransport().getPickUp()}
                            shipping={order.getCalculateShipping() ? order.getCalculateShipping().getPrice() : 0}
                            subTotal={order.getSubTotal()}
                            total={order.getTotal()}
                        />
                    }
                />
            </Container>
        );
    }
}

export default withNamespaces("page")(OrderDetailView);