import * as React           from "react";
import ContactUsView        from "../Views/Pages/ContactUsView";
import { inject, observer } from "mobx-react";
import { GalleryStore }     from "../Store/GalleryStore";
import { observable }       from "mobx";
import { Gallery }          from "../Models/Gallery/Gallery";
import { galleryPages }     from "../config/GalleryPages";
import { PageStore }        from "../Store/PageStore";
import { Page }             from "../Models/Page/Page";
import { pages }            from "../config/Pages";

interface ContactUsViewModelProps {
    GalleryStore?: GalleryStore;
    PageStore?: PageStore;
}

@inject(GalleryStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class ContactUsViewModel extends React.Component<ContactUsViewModelProps, {}> {
    @observable
    private gallery: Gallery;

    @observable
    private page: Page | undefined;

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    protected async getGalleryPage() {
        this.gallery = await this.galleryStore.getGalleryPage(galleryPages.contact);
    }

    public componentDidMount = async (): Promise<void> => {
        this.getGalleryPage();
        this.page = await this.pageStore.getPage(pages.contact);
    };

    public render(): React.ReactNode {
        return (
            <ContactUsView
                gallery={this.gallery}
                page={this.page}
            />
        );
    }
}

export default ContactUsViewModel;
