import { inject, observer }       from "mobx-react";
import { withRouter }             from "react-router-dom";
import * as React                 from "react";
import { Container }              from "typedi";
import { observable }             from "mobx";
import SimpleReactValidator       from "simple-react-validator";
import { withNamespaces }         from "react-i18next";
import IBaseProps                 from "../Props/IBaseProps";
import { UserStore }              from "../../Store/UserStore";
import { FormStore }              from "../../Store/FormStore";
import { RegisterStore }          from "../../Store/RegisterStore";
import AuthService                from "../../Service/AuthService";
import ForgetPasswordView         from "../../Views/Pages/ForgetPassword/ForgetPasswordView";
import User                       from "../../Models/User/User";
import { ForgetPassword }         from "../../Models/ForgetPassword/ForgetPassword";
import Box                        from "@material-ui/core/Box";
import CircularProgress           from "@material-ui/core/CircularProgress";
import { PageNames, renderRoute } from "../../Routes/routes";

interface IForgetPasswordFormViewModelProps extends IBaseProps {
    UserStore?: UserStore;
    FormStore?: FormStore;
    RegisterStore?: RegisterStore;
}

@inject(
    UserStore.NAME_STORE,
    FormStore.NAME_STORE,
    RegisterStore.NAME_STORE
)
@observer
class ForgetPasswordViewModel extends React.Component<IForgetPasswordFormViewModelProps, {}> {
    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private sending: boolean = false;

    @observable
    private loading: boolean = true;

    @observable
    private response: boolean = false;

    @observable
    private sent: boolean = false;

    @observable
    private user: User;

    @observable
    private forgetPassword: ForgetPassword = new ForgetPassword();

    protected get authService(): AuthService {
        return Container.get(AuthService);
    }

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    protected get registerStore(): RegisterStore {
        return this.props.RegisterStore as RegisterStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    constructor(props: IForgetPasswordFormViewModelProps) {
        super(props);

        const {t} = this.props;

        this.validator = this.formStore.getNewValidator(t);
    }

    public componentDidMount = async () => {
        const token = this.props.match.params.token,
              user  = await this.registerStore.getUserByToken(token);

        if (user) {
            this.user = user;
            this.forgetPassword.setUserId(user.getId());
            this.forgetPassword.setToken(token);
        }

        this.loading = false;
    };

    protected submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate();

            return;
        }

        this.sending = true;
        await this.registerStore.changePassword(this.forgetPassword);
        this.sent    = true;
        this.sending = false;

        setTimeout(() => {
            this.props.history.push(renderRoute(PageNames.loginPage));
        }, 4000);
    };

    public render(): React.ReactNode {
        if (this.loading)
            return (
                <Box justifyContent="center" className="d-flex m-4">
                    <CircularProgress color="secondary"/>
                </Box>
            );

        return (
            <ForgetPasswordView
                validator={this.validator}
                submit={this.submit}
                sending={this.sending}
                response={this.response}
                sent={this.sent}
                forgetPassword={this.forgetPassword}
                user={this.user}
            />
        );
    }
}

export default withRouter(withNamespaces("forms")(ForgetPasswordViewModel));