import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Col, Row }                       from "reactstrap";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import User                               from "../../../Models/User/User";
import FormDirectionViewModel             from "../../../ViewModel/FormDirection/FormDirectionViewModel";
import { Direction }                      from "../../../Models/User/Direction";
import CardDirectionChooseViewModel       from "../../../ViewModel/Card/CardDirectionChooseViewModel";
import { Card }                           from "@material-ui/core";
import { Add as AddIcon }                 from "@material-ui/icons";
import { Order }                          from "../../../Models/Order/Order";

interface IChooseDirectionViewProps extends WithNamespaces {
    user: User;
    newDirection: boolean;
    setNewDirection: (val: boolean) => void;
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
class ChooseDirectionView extends React.Component<IChooseDirectionViewProps, {}> {
    protected get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderDirection = (): React.ReactNode[] => {
        const {
                  user
              } = this.props;

        return user.getDirections().map((direction: Direction) => {
            return (
                <Col xs={12} sm={6} md={4} key={direction.getUID()}>
                    <CardDirectionChooseViewModel direction={direction}/>
                </Col>
            );
        });
    };

    protected addDirection = () => {
        const {setNewDirection} = this.props;
        setNewDirection(true);

        setTimeout(() => {
            const item     = `${this.prefix}-form-contact-us`,
                  itemNode = document.getElementsByClassName(item)[0] as HTMLElement;

            if (itemNode) {
                scrollTo({
                             behavior: "smooth",
                             left    : 0,
                             top     : itemNode.offsetTop - 130,
                         });
            }
        }, 100);
    };

    public render(): React.ReactNode {
        const {t, newDirection} = this.props;

        return (
            <div>
                <h2 className="title">
                    {t("order.select-direction")}
                </h2>
                <Row className="container-cards">
                    <Col xs={12} sm={6} md={4}>
                        <Card variant="outlined" className="card add-new">
                            <a href="javascript:void(0);" onClick={this.addDirection}>
                                <AddIcon className="icon"/>
                                <span>Agregar una nueva direccion</span>
                            </a>
                        </Card>
                    </Col>
                    {this.renderDirection()}
                </Row>
                {
                    newDirection
                    ? (
                        <div className="form">
                            <FormDirectionViewModel/>
                        </div>
                    )
                    : <React.Fragment/>
                }
            </div>
        );
    }
}

export default withNamespaces("page")(ChooseDirectionView);