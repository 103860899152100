import NumberFormat from "react-number-format";
import * as React   from "react";

export default ({value, renderText}: { value: number, renderText?: (val: string) => React.ReactNode | string }) => {
    return (
        <NumberFormat
            displayType="text"
            value={value}
            thousandSeparator={true}
            prefix={"¢"}
            decimalScale={2}
            renderText={renderText ? renderText : (formattedValue: string) => formattedValue}
        />
    );
}