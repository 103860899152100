import { inject, observer }                from "mobx-react";
import { PageStore }                       from "../../Store/PageStore";
import { ProductStore }                    from "../../Store/ProductStore";
import * as React                          from "react";
import { Product }                         from "../../Models/Product/Product";
import ProductsRelationship                from "../../Views/Components/ProductRelationship/ProductsRelationship";
import { Container }                       from "typedi";
import SnackBarService                     from "../../Service/SnackBarService";
import SnackBarAddedProductViewModel       from "../SnackBar/SnackBarAddedProductViewModel";
import { CarStore }                        from "../../Store/CarStore";
import ModalService                        from "../../Service/ModalService";
import { observable }                      from "mobx";
import { ListCheckedProductsRelationship } from "../../types/ListCheckedProductsRelationship";

interface IProductsViewModelProps {
    PageStore?: PageStore;
    ProductStore?: ProductStore;
    CarStore?: CarStore;
    productsRelationship: Product[];
    product: Product;
}

@inject(PageStore.NAME_STORE, ProductStore.NAME_STORE, CarStore.NAME_STORE)
@observer
class ProductRelationshipViewModel extends React.Component<IProductsViewModelProps, {}> {
    @observable
    protected listChecked: ListCheckedProductsRelationship = {};

    constructor(props: IProductsViewModelProps) {
        super(props);
        const {productsRelationship, product} = props;

        this.listChecked[product.getId()] = {status: true, product};

        productsRelationship.forEach((item: Product) => {
            this.listChecked[item.getId()] = {status: true, product: item};
        });
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected actionAddCar = () => {
        const {product} = this.props;

        for (const listCheckedKey in this.listChecked) {
            const item = this.listChecked[listCheckedKey];

            if (item.status) {
                this.carStore.addProduct(product, 1);
            }
        }

        Container.get(SnackBarService).open(SnackBarAddedProductViewModel, {
            product
        });
        Container.get(ModalService).closeModal();
    };

    protected handleChangeList = (id: string, status: boolean) => {
        this.listChecked[id].status = status;
    };

    public render(): React.ReactNode {
        const {
                  productsRelationship,
                  product
              } = this.props;

        if (productsRelationship.length === 0) return <React.Fragment/>;

        return (
            <ProductsRelationship
                productsRelationship={productsRelationship}
                product={product}
                actionAddCar={this.actionAddCar}
                handleChangeList={this.handleChangeList}
                listChecked={this.listChecked}
            />
        );
    }
}


export default ProductRelationshipViewModel;