import scssConfiguration from "./scssConfiguration";
import createMuiTheme    from "@material-ui/core/styles/createMuiTheme";

export const theme = createMuiTheme({
                                        palette: {
                                            background: {
                                                default: scssConfiguration.quaternaryColor
                                            },
                                            primary   : {
                                                // light: will be calculated from palette.primary.main,
                                                main: scssConfiguration.primaryColor,
                                                // dark: will be calculated from palette.primary.main,
                                                // contrastText: will be calculated to contrast with
                                                // palette.primary.main
                                            },
                                            secondary : {
                                                // light: '#0066ff',
                                                main: scssConfiguration.secondaryColor,
                                                // dark: will be calculated from palette.secondary.main,
                                                // contrastText: '#ffcc00',
                                            }
                                            // error: will use the default color
                                        },
                                        shape: {
                                            borderRadius: 0
                                        },
                                    });
