import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Product }                        from "../../../Models/Product/Product";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Col, Row }                       from "reactstrap";
import CircularProgress                   from "@material-ui/core/CircularProgress";
import Button                             from "@material-ui/core/Button";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Box                                from "@material-ui/core/Box";
import { CardProductViewModel }          from "../../../ViewModel/Card/CardProductViewModel";

interface IProductListViewProps extends WithNamespaces {
    products: Product[];
    showMore: () => void;
    currentPage: number;
    pages: number;
    loading: boolean;
    prefix?: string;
    openSlugProduct: string;
}

@InjectPrefix
@observer
export class ProductListView extends React.Component<IProductListViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private renderLoading = (): React.ReactNode => {
        const {
                  t,
                  showMore,
                  loading,
                  currentPage,
                  pages
              } = this.props;

        if (loading)
            return (<CircularProgress color="secondary"/>);

        if (currentPage > pages || (currentPage === 1 && pages === 1))
            return (<React.Fragment/>);

        return (
            <Button variant="contained" color="primary" onClick={showMore}>
                {t("global:show-more")}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  products,
                  openSlugProduct,
              } = this.props;

        return (
            <div className={`${this.prefix}-products-list`}>
                <h4 className="title">Productos</h4>
                <Row className={"list-wrap"}>
                    {products.map((product: Product) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={product.getSlug()} className="item">
                                <CardProductViewModel
                                    product={product}
                                    openSlugProduct={openSlugProduct}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <Box justifyContent="center" className="d-flex mb-4">
                    {this.renderLoading()}
                </Box>
            </div>
        );
    }
}

export default withNamespaces("page")(ProductListView);