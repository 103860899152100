import {
    WithNamespaces,
    withNamespaces
}                                             from "react-i18next";
import SimpleReactValidator                   from "simple-react-validator";
import * as React                             from "react";
import InjectPrefix                           from "../../../Decorators/InjectPrefix";
import { observer }                           from "mobx-react";
import { Button, Card, Container, TextField } from "@material-ui/core";
import CircularProgress                       from "@material-ui/core/CircularProgress";
import { ForgetPassword }                     from "../../../Models/ForgetPassword/ForgetPassword";
import { ERROR_CLASS }                        from "../../../Store/FormStore";
import ErrorOutlineIcon                       from "@material-ui/icons/ErrorOutline";
import User                                   from "../../../Models/User/User";

interface IForgetPasswordFormViewProps extends WithNamespaces {
    prefix?: string;
    validator: SimpleReactValidator;
    submit: (form: React.Component) => void;
    sending: boolean;
    response: boolean;
    forgetPassword: ForgetPassword;
    sent: boolean;
    user: User;
}

@InjectPrefix
@observer
class ForgetPasswordView extends React.Component<IForgetPasswordFormViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  forgetPassword,
                  sending,
              }       = this.props,
              text    = sending ? (<CircularProgress color="primary"/>) : t("global:submit"),
              canSend = !(sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={`mt-2 btn-register`}
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    protected renderForm = () => {
        const {
                  t,
                  validator,
                  response,
                  sent,
                  forgetPassword,
              } = this.props;
        validator.message("password-confirm-repeat", forgetPassword.getConfirmPassword(), `in:${forgetPassword.getPassword()}`);

        return (
            <>
                <h1 className="h2">
                    {t("global:restore-my-password")}
                </h1>
                <form>
                    <TextField
                        id="form-name"
                        label={t("forms:password")}
                        fullWidth
                        required
                        value={forgetPassword.getPassword() || ""}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            forgetPassword.setPassword(evt.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        InputLabelProps={{error: !!validator.message("password", forgetPassword.getPassword(), "required|min:8|field-password-no-regex")}}
                    />
                    {validator.message("password", forgetPassword.getPassword(), "required|min:8|field-password-no-regex")}
                    <TextField
                        id="form-password-confirm"
                        label={t("forms:password-confirm")}
                        fullWidth
                        required
                        value={forgetPassword.getConfirmPassword()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            forgetPassword.setConfirmPassword(evt.target.value);
                        }}
                        type="password"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{error: !!validator.message("password-confirm", forgetPassword.getConfirmPassword(), "required")}}
                    />
                    {
                        !validator.fieldValid("password-confirm-repeat") &&
                        (
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:field-password-confirm-repeat")}
                            </div>
                        )
                    }
                </form>
                {
                    sent
                    ? <p>{t(`global:forget-password-${response ? "success" : "not-found"}`)}</p>
                    : <React.Fragment/>
                }
                <div>
                    {this.renderButton()}
                </div>
            </>
        );
    };

    protected renderUserDidntFound() {
        const {
                  t,
                  sent
              } = this.props;

        if (sent) return <React.Fragment/>;

        return (
            <>
                <h1 className="h2">
                    {t("global:token-invalid-or-not-exist")}
                </h1>
            </>
        );
    }

    public render(): React.ReactNode {
        const {
                  user,
                  sent,
                  t
              } = this.props;

        return (
            <Container className={`${this.prefix}-p-forget-password`}>
                <Card className={"forget-password-card"}>
                    {user && !sent ? this.renderForm() : this.renderUserDidntFound()}
                    {sent ? <div className="password-changed">
                        <h3>
                            {t("global:password-changed-successful")}
                        </h3>
                    </div> : <React.Fragment/>}
                </Card>
            </Container>
        );
    }
}

export default withNamespaces("page")(ForgetPasswordView);