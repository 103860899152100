import * as React          from "react";
import {
    WithNamespaces,
    withNamespaces
}                          from "react-i18next";
import InjectPrefix        from "../../Decorators/InjectPrefix";
import { Container }       from "@material-ui/core";
import Carousel            from "../Components/Carousel/Carousel";
import { OurServices }     from "../Components/OurServices/OurServices";
import OurBrands           from "../Components/OurBrands/OurBrands";
import LastPromotions      from "../Components/LastPromotions/LastPromotions";
import { Gallery }         from "../../Models/Gallery/Gallery";
import { Promotion }       from "../../Models/Promotion/Promotion";
import { SeoData }         from "../../Models/Page/SeoData";
import Seo                 from "../Components/Header/Seo";
import {
    Col,
    Row
}                          from "reactstrap";
import { faWhatsapp }      from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { social }          from "../../config/social";
import LastProducts        from "../Components/LastProducts/LastProducts";
import { Product }         from "../../Models/Product/Product";
import ProductViewModel    from "../../ViewModel/Home/ProductViewModel";
import { Page }            from "../../Models/Page/Page";

interface HomeViewProps extends WithNamespaces {
    prefix?: string;
    gallery: Gallery | undefined;
    galleryBrands: Gallery | undefined;
    promotions: Promotion[] | undefined;
    products: Product[] | undefined;
    seoData: SeoData | undefined;
    page: Page | undefined;
}

@InjectPrefix
class HomeView extends React.Component<HomeViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  promotions,
                  gallery,
                  galleryBrands,
                  products,
                  seoData,
                  page
              } = this.props;

        return (
            <div className={`${this.prefix}-p-home`}>
                <Seo seoData={seoData}/>
                <Carousel
                    gallery={gallery}
                />
                <Container>
                    <div className="w-100 text-center mb-5">
                        <h2 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                            Maderas Camacho S.A
                        </h2>
                    </div>
                    <div className="disclaimer">
                        <p>Maderas Camacho S.A es una empresa dedicada a la venta de maderas, materiales para la
                            construccion y articulos de ferreteria en general</p>
                        <p>Nuestro amplio inventario nos ha hecho ganadores de una gran confianza de nuestros clientes,
                            razon por la cual ademas de atender el sector de la construccion, podemos suplir otros
                            segmentos como industria, talleres automitrices, electrico, empresas dedicadas al transporte
                            publico y de carga ademas de empresas dedicadas al mantenimiento de condominios y
                            fabricas.</p>
                    </div>
                    {page && <ProductViewModel
                        page={page}
                    />}
                    <LastProducts
                        products={products}
                        className="mb-6"
                    />
                    <Row className="disclaimer painting align-items-center">
                        <Col xs={12} md={6} className="w-100 d-flex justify-content-center">
                            <img src="/assets/img/brands/cemento-progreso.png" alt="" className="d-block mb-5"/>
                        </Col>
                        <Col xs={12} md={6} className="painting-disclaimer">
                            <a
                                href={`https://wa.me/${social.whatsAppLanco}?text=${""}`}
                                target="_blank"
                                className={`${this.prefix}-primary-color`}
                            >
                                <FontAwesomeIcon icon={faWhatsapp}/>
                            </a>
                        </Col>
                    </Row>
                    <LastPromotions
                        promotions={promotions}
                    />
                    <OurServices/>
                    <OurBrands
                        galleryBrands={galleryBrands}
                    />
                </Container>
            </div>
        );
    }
}

export default withNamespaces("page")(HomeView);
