import InjectPrefix       from "../../../Decorators/InjectPrefix";
import { observer }       from "mobx-react";
import * as React         from "react";
import { ProductItemCar } from "../../../Models/ProductItemCar/ProductItemCar";
import {
    Hidden,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
}                         from "@material-ui/core";
import {
    withNamespaces,
    WithNamespaces
}                         from "react-i18next";
import { Order }          from "../../../Models/Order/Order";
import NumberFormat       from "../NumberFormat/NumberFormat";
import { Color }          from "../../../Models/Color/Color";
import { Product }        from "../../../Models/Product/Product";

interface ITableProductsProps extends WithNamespaces {
    productItemCars: ProductItemCar[];
    removeProduct?: (product: Product) => ProductItemCar[];
    hideTotal?: boolean;
    order?: Order;
    notEdit?: boolean;
    prefix?: string;
}

@InjectPrefix
@observer
class TableProducts extends React.Component<ITableProductsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderProductsList = (): React.ReactNode[] => {
        const {
                  productItemCars,
                  removeProduct,
                  t,
                  notEdit,
                  hideTotal,
              }                        = this.props,
              items: React.ReactNode[] = [];
        let total                      = 0;

        productItemCars.map((item: ProductItemCar) => {
            const product = item.getProduct();
            total += product.getPrice() * item.getQuantity();

            items.push(
                <TableRow className="item" key={item.getIdentifier()}>
                    <TableCell className="product">
                        <img src={product.getImageFullPath()} className="image" alt=""/>
                        <div>
                            <span className="name">{product.getName()}</span>
                            {removeProduct ? <a
                                href="javascript:void(0);"
                                onClick={() => {
                                    removeProduct(product);
                                    this.forceUpdate();
                                }}
                                className="remove"
                            >Quitar</a> : <React.Fragment/>}
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        {notEdit
                            ? <span className="quantity">{item.getQuantity()}</span>
                            : <Select
                                id="demo-simple-select"
                                value={item.getQuantity()}
                                onChange={(evt: React.ChangeEvent<{ name?: string; value: number }>) => {
                                    item.setQuantity(evt.target.value);
                                    this.forceUpdate();
                                }}
                            >
                                {Array.from(Array(100).keys()).map(item => {
                                    return (
                                        <MenuItem key={item} value={item + 1}>{item + 1}</MenuItem>
                                    );
                                })}
                            </Select>
                        }
                    </TableCell>
                    {
                        item.getColor()
                            ? <TableCell align="left">
                                <div className="d-flex align-items-center color-td">
                                    <span className="color-item" style={{backgroundColor: item.getColor()?.getRgb()}}/>
                                    <div>
                                        <p className="name">{item.getColor()?.getName()}</p>
                                        <p className="color-code">{item.getColor()?.getCode()}</p>
                                    </div>
                                </div>
                            </TableCell>
                            : <TableCell/>
                    }
                    <TableCell align="right">
                        <NumberFormat
                            value={product.getPrice()}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={product.getPrice() * item.getQuantity()}
                        />
                    </TableCell>
                </TableRow>
            );
        });

        if (!hideTotal)
            items.push(
                <TableRow className="item total" key="total">
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell align="center">
                        {t("subtotal")}
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={total}
                        />
                    </TableCell>
                </TableRow>
            );

        return items;
    };

    protected renderMobileProductsList = (): React.ReactNode[] => {
        const {
                  productItemCars,
                  removeProduct,
                  t,
                  notEdit,
              }                        = this.props,
              items: React.ReactNode[] = [];
        let total                      = 0;

        productItemCars.map((item: ProductItemCar) => {
            const product = item.getProduct();
            total += product.getPrice() * item.getQuantity();
            items.push(
                <Table className={`${this.prefix}-table-products`} key={item.getIdentifier()}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} align={"center"}>
                                <div className="product">
                                    <img src={product.getImageFullPath()} className="image" alt=""/>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">
                                {t("producto")}
                            </TableCell>
                            <TableCell className="">
                                <div>
                                    <span className="name">{product.getName()}</span>
                                    {removeProduct ? <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                            removeProduct(product);
                                            this.forceUpdate();
                                        }}
                                        className="remove"
                                    >Quitar</a> : <React.Fragment/>}
                                </div>
                            </TableCell>
                        </TableRow>
                        {
                            item.getColor() ?
                                (
                                    <TableRow>
                                        <TableCell align="right">
                                            {t("color")}
                                        </TableCell>
                                        <TableCell>
                                            <div className="d-flex align-items-center color-td">
                                                <span className="color-item"
                                                      style={{backgroundColor: item.getColor()?.getRgb()}}
                                                />
                                                <div>
                                                    <p className="name">{item.getColor()?.getName()}</p>
                                                    <p className="color-code">{item.getColor()?.getCode()}</p>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                                : <TableRow/>
                        }
                        <TableRow>
                            <TableCell align="right">
                                {t("quantity")}
                            </TableCell>
                            <TableCell>
                                {notEdit
                                    ? <span className="quantity">{item.getQuantity()}</span>
                                    : <Select
                                        id="demo-simple-select"
                                        value={item.getQuantity()}
                                        onChange={(evt: React.ChangeEvent<{ name?: string; value: number }>) => {
                                            item.setQuantity(evt.target.value);
                                            this.forceUpdate();
                                        }}
                                    >
                                        {Array.from(Array(100).keys()).map(item => {
                                            return (
                                                <MenuItem key={item} value={item + 1}>{item + 1}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">
                                {t("price-unity")}
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    value={product.getPrice()}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className="row-subtotal">
                            <TableCell align="right">
                                {t("subtotal")}
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    value={product.getPrice() * item.getQuantity()}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        });

        return items;
    };

    public renderDesktop = (): React.ReactNode => {
        const {t, children, productItemCars} = this.props;

        return (
            <Table className={`${this.prefix}-table-products`}>
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell>
                            {t("producto")}
                        </TableCell>
                        <TableCell align="center">
                            {t("quantity")}
                        </TableCell>
                        <TableCell align="center">
                            {t("color")}
                        </TableCell>
                        <TableCell align="right">
                            {t("price-unity")}
                        </TableCell>
                        <TableCell align="right">
                            {t("subtotal")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderProductsList()}
                    {children}
                </TableBody>
            </Table>
        );
    };

    public renderMobile = (): React.ReactNode => {
        const {children, hideTotal, t, productItemCars} = this.props;
        let item                                        = <React.Fragment/>;

        if (!hideTotal)
            item = (
                <Table className={`${this.prefix}-table-products`}>
                    <TableBody>
                        <TableRow className="item total">
                            <TableCell align="right">
                                {t("subtotal")}
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    value={productItemCars.reduce((accum: number, item: ProductItemCar) => {
                                        return accum + (item.getProduct().getPrice() * item.getQuantity());
                                    }, 0)}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );

        return (
            <>
                {this.renderMobileProductsList()}
                {item}
                {children}
            </>
        );
    };

    public render = (): React.ReactNode => {
        return (
            <>
                <Hidden only={["md", "lg", "xl"]}>
                    {this.renderMobile()}
                </Hidden>
                <Hidden only={["xs", "sm"]}>
                    {this.renderDesktop()}
                </Hidden>
            </>
        );
    };
}

export default withNamespaces("car")(TableProducts);