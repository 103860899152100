export const globalPagerOptions = {
    MAX_PER_PAGE: 10,
    MAX_PRODUCTS_PER_PAGE: 50,
};

export interface IPaginationData<T> {
    data: T[];
    pages: number;
}

export const promotionPagerOptions = {
    MAX_PER_PAGE: 8,
};

export const tutorialPagerOptions = {
    MAX_PER_PAGE: 6,
};

export interface IClientPagination {
    totalItems: number,
    currentPage: number,
    pageSize: number,
    totalPages: number,
    startPage: number,
    endPage: number,
    startIndex: number,
    endIndex: number,
    pages: number[]
}

export interface IClientPaginated<T> {
    paginated: T[],
    pagination: IClientPagination
}

export class ClientPagination<T> {
    private items: T[];
    private currentPage: number
    private pageSize: number;
    private maxPages: number;

    constructor(items: T[] = [],
                currentPage: number = 1,
                pageSize: number = 10,
                maxPages: number = 10) {
        this.items = items;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.maxPages = maxPages;
    }

    public toPagination = (): IClientPagination  => {
        const totalItems = this.items.length;
        // calculate total pages
        const totalPages = Math.ceil(totalItems / this.pageSize);

        // ensure current page isn't out of range
        if (this.currentPage < 1) {
            this.currentPage = 1;
        } else if (this.currentPage > totalPages) {
            this.currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= this.maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(this.maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(this.maxPages / 2) - 1;
            if (this.currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = this.maxPages;
            } else if (this.currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - this.maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = this.currentPage - maxPagesBeforeCurrentPage;
                endPage = this.currentPage + maxPagesAfterCurrentPage;
            }
        }

        // calculate start and end item indexes
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = Math.min(startIndex + this.pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    public paginate = (): IClientPaginated<T> => {
      const pagination = this.toPagination();
      return {
        pagination,
        paginated: this.items.slice(pagination.startIndex, pagination.endIndex)
      };
    }
}
