import {
    Container,
    Service
} from "typedi";
import {
    AxiosInstance,
    AxiosPromise,
    AxiosResponse
} from "axios";
import AxiosService from "./AxiosService";
import API_CONST from "../config/API_CONST";

@Service()
export default class AjaxService {
    private getAxios(): AxiosInstance {
        return Container.get(AxiosService).axios;
    }

    protected replaceURL(url: string, params: object = {}) {
        for (const key in params) {
            url = url.replace(`:${key}`, params[key]);
        }

        return url;
    }

    public getMe(): AxiosPromise<any> {
        return this.getAxios().get(API_CONST.GET_ME);
    }

    public getGalleryPage(name: string): AxiosPromise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_GALLERY_PAGE, {name}));
    }

    public getHomePage(): AxiosPromise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_HOME_PAGE);
    }

    public async getPaginatePromotions(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_PROMOTIONS, {page, size}));
    }

    public async getPromotionSlug(slug: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PROMOTION_BY_SLUG, {slug}));
    }

    public async getPaginateTutorials(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_TUTORIALS, {page, size}));
    }

    public async getTutorialSlug(slug: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_TUTORIAL_BY_SLUG, {slug}));
    }

    public async getCurrentAboutUs(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_ABOUT_US_PAGE);
    }

    public async postContactUs(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_CONTACT_US, data);
    }

    public async getPageName(name: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGE_BY_NAME, {name}));
    }

    public async getPaginateOccupations(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_OCCUPATION, {page, size}));
    }

    public async getPaginateRecommendationPerson(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_RECOMMENDATION_PERSON, {page, size}));
    }

    public async getExistUserByEmail(email: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_EXIST_USER_BY_EMAIL, {email}));
    }

    public async getUserByToken(token: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_USER_BY_TOKEN, {token}));
    }

    public async postForgetPassword(email: string): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_FORGET_PASSWORD, {email});
    }

    public async putChangePassword(data: any): Promise<AxiosResponse> {
        return this.getAxios().put(API_CONST.POST_CHANGE_FORGET_PASSWORD, data);
    }

    public async postRegister(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_REGISTER, data);
    }

    public async getCategories(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_CATEGORY_ALL);
    }

    public async getMethodTransports(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_METHOD_TRANSPORTS);
    }

    public async getSearchProducts(page: number, size: number, data: any): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_SEARCH_PRODUCTS, {page, size}), {
            params: data
        });
    }

    public async getProductBySlug(slug: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCT_BY_SLUG, {slug}));
    }

    public async getProductsRelationship(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCTS_RELATIONSHIP, {id}));
    }

    public async postDirection(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_DIRECTION_USER, data);
    }

    public async updateDirection(data: any): Promise<AxiosResponse> {
        return this.getAxios().put(API_CONST.PUT_DIRECTION_USER, data);
    }

    public async deleteDirection(data: any): Promise<AxiosResponse> {
        return this.getAxios().delete(API_CONST.DELETE_DIRECTION_USER, {
            data
        });
    }

    public async getCalculatePriceShipping(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_DIRECTION_CALCULATE_PRICE, data);
    }

    public async postOrder(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_ORDER, data);
    }

    public async postBillElectronic(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_BILL_ELECTRONIC_USER, data);
    }

    public async updateBillElectronic(data: any): Promise<AxiosResponse> {
        return this.getAxios().put(API_CONST.PUT_BILL_ELECTRONIC_USER, data);
    }

    public async deleteBillElectronic(data: any): Promise<AxiosResponse> {
        return this.getAxios().delete(API_CONST.DELETE_BILL_ELECTRONIC_USER, {
            data
        });
    }

    public async getMyOrders(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_MY_ORDERS);
    }

    public async getOrderByIdentifier(identifier: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_ORDER_BY_IDENTIFIER, {identifier}));
    }

    public async updateProfileUser(data: any): Promise<AxiosResponse> {
        return this.getAxios().put(API_CONST.PUT_PROFILE_USER, data);
    }

    public async getProductsByIdsList(ids: string | string[]): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCTS_LIST_BY_IDS, {ids: typeof ids === "string" ? ids : ids.join("|")}));
    }

    public async getColorPaletteById(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_COLOR_PALETTE_BY_ID, {id}));
    }
}
