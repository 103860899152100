const prefix     = `api`;
const prefixOpen = `api/open/v1`;

export default {
    DELETE_BILL_ELECTRONIC_USER       : `${prefixOpen}/user/bill-electronic`,
    DELETE_DIRECTION_USER             : `${prefixOpen}/user/direction`,
    GET_ABOUT_US_PAGE                 : `${prefixOpen}/about-us`,
    GET_CATEGORY_ALL                  : `${prefixOpen}/category/all`,
    GET_COLOR_PALETTE_BY_ID           : `${prefixOpen}/color-palette/:id`,
    GET_EXIST_USER_BY_EMAIL           : `${prefixOpen}/user/exist/:email`,
    GET_GALLERY_PAGE                  : `${prefixOpen}/gallery/page/:name`,
    GET_HOME_PAGE                     : `${prefixOpen}/home`,
    GET_ME                            : `/api/v1/users/me`,
    GET_METHOD_TRANSPORTS             : `${prefixOpen}/method-transport/`,
    GET_MY_ORDERS                     : `${prefixOpen}/order/me/all`,
    GET_ORDER_BY_IDENTIFIER           : `${prefixOpen}/order/:identifier`,
    GET_PAGE_BY_NAME                  : `${prefixOpen}/page/:name`,
    GET_PAGINATE_OCCUPATION           : `${prefixOpen}/occupation/paginate/:page/:size`,
    GET_PAGINATE_PROMOTIONS           : `${prefixOpen}/promotion/paginate/:page/:size`,
    GET_PAGINATE_RECOMMENDATION_PERSON: `${prefixOpen}/recommendation-person/paginate/:page/:size`,
    GET_PAGINATE_TUTORIALS            : `${prefixOpen}/tutorial/paginate/:page/:size`,
    GET_PRODUCTS_LIST_BY_IDS          : `${prefixOpen}/product/in/id/:ids`,
    GET_PRODUCTS_RELATIONSHIP         : `${prefixOpen}/product/relationship/:id`,
    GET_PRODUCT_BY_SLUG               : `${prefixOpen}/product/:slug`,
    GET_PROMOTION_BY_SLUG             : `${prefixOpen}/promotion/:slug`,
    GET_SEARCH_PRODUCTS               : `${prefixOpen}/product/paginate/:page/:size`,
    GET_TUTORIAL_BY_SLUG              : `${prefixOpen}/tutorial/:slug`,
    GET_USER_BY_TOKEN                 : `${prefixOpen}/user/token/:token`,
    POST_ACCESS_TOKEN                 : `${prefix}/oauth/token`,
    POST_BILL_ELECTRONIC_USER         : `${prefixOpen}/user/bill-electronic`,
    POST_CHANGE_FORGET_PASSWORD       : `${prefixOpen}/user/password`,
    POST_CONTACT_US                   : `${prefixOpen}/contact-us`,
    POST_DIRECTION_CALCULATE_PRICE    : `${prefixOpen}/direction/calculate/price`,
    POST_DIRECTION_USER               : `${prefixOpen}/user/direction`,
    POST_FORGET_PASSWORD              : `${prefixOpen}/user/forget-password`,
    POST_LOGOUT                       : `${prefix}/oauth/invalidate`,
    POST_ORDER                        : `${prefixOpen}/order`,
    POST_REGISTER                     : `${prefixOpen}/user/register`,
    PUT_BILL_ELECTRONIC_USER          : `${prefixOpen}/user/bill-electronic`,
    PUT_DIRECTION_USER                : `${prefixOpen}/user/direction`,
    PUT_PROFILE_USER                  : `${prefixOpen}/user/profile`,
};
