import * as React                         from "react";
import { observer }                       from "mobx-react";
import User                               from "../../../Models/User/User";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Col, Container, Row }            from "reactstrap";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Card }                           from "@material-ui/core";
import { Add as AddIcon }                 from "@material-ui/icons";
import FormDirectionViewModel             from "../../../ViewModel/FormDirection/FormDirectionViewModel";
import { Direction }                      from "../../../Models/User/Direction";
import CardDirectionViewModel             from "../../../ViewModel/Card/CardDirectionViewModel";

interface IMyDirectionsViewProps extends WithNamespaces {
    user: User;
    prefix?: string;
}

@InjectPrefix
@observer
class MyDirectionsView extends React.Component<IMyDirectionsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderDirections = () => {
        const {user} = this.props;
        return user.getDirections().map((direction: Direction) => {
            return (
                <Col xs={12} sm={6} md={4} key={direction.getUID()}>
                    <CardDirectionViewModel direction={direction}/>
                </Col>
            );
        });
    };

    protected addDirection = () => {
        const item     = `${this.prefix}-form-contact-us`,
              itemNode = document.getElementsByClassName(item)[0] as HTMLElement;

        if (itemNode) {
            scrollTo({
                         behavior: "smooth",
                         left    : 0,
                         top     : itemNode.offsetTop - 130,
                     });
        }
    };

    public render(): React.ReactNode {
        return (
            <div className={`${this.prefix}-p-my-directions`}>
                <Container>
                    <h1 className="text-center w-100 mt-5 mb-5 d-block">Mis Direcciones</h1>

                    <Row className="container-cards">
                        <Col xs={12} sm={6} md={4}>
                            <Card variant="outlined" className="card add-new">
                                <a href="javascript:void(0);" onClick={this.addDirection}>
                                    <AddIcon className="icon"/>
                                    <span>Agregar una nueva direccion</span>
                                </a>
                            </Card>
                        </Col>
                        {this.renderDirections()}
                    </Row>

                    <div className="form">
                        <FormDirectionViewModel/>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withNamespaces("directions")(MyDirectionsView);