import React         from "react";
import { Container } from "typedi";
import { observer }  from "mobx-react";
import SnackBarService  from "../../Service/SnackBarService";

@observer
class ManageSnackBarViewModel extends React.Component<{}, {}> {
    public render(): React.ReactNode {
        return Container.get(SnackBarService).printSnackBar() || <></>;
    }
}

export default ManageSnackBarViewModel;
