import * as React         from "react";
import { observer }       from "mobx-react";
import { withNamespaces } from "react-i18next";
import {
    Col,
    Container,
    Row
}                         from "reactstrap";
import User               from "../../../Models/User/User";
import InjectPrefix       from "../../../Decorators/InjectPrefix";
import {
    Card,
    CardContent,
}                         from "@material-ui/core";
import {
    Link,
    withRouter
}                         from "react-router-dom";
import {
    AccountBox as AccountBoxIcon,
    ListAlt as ListAltIcon,
    Receipt as ReceiptIcon,
    Room as RoomIcon,
}                         from "@material-ui/icons";
import IBaseProps         from "../../../ViewModel/Props/IBaseProps";
import {
    PageNames,
    renderRoute
}                         from "../../../Routes/routes";
import { Order }          from "../../../Models/Order/Order";

interface IProfileViewProps extends IBaseProps {
    user: User;
    orders: Order[];
    prefix?: string;
}

@InjectPrefix
@observer
class ProfileView extends React.Component<IProfileViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private goTo = (route: string) => {
        this.props.history.push(route);
    };

    public render(): React.ReactNode {
        const {user, t, orders} = this.props;

        return (
            <div className={`${this.prefix}-p-profile`}>
                <div className="welcome">
                    <Container>
                        <Row>
                            <Col xs={12} md={8}>
                                <h4 className="font-weight-bold">
                                    {t("profile.hello")} {`${user.getFirstName()} ${user.getLastName()}`}
                                </h4>
                            </Col>
                            <Col xs={12} md={4}>
                                <h5 className="font-weight-bold">{t("profile.packages")}: {orders.length}</h5>
                                <Link to={renderRoute(PageNames.myOrdersPage)}>{t("profile.see-packages")}</Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="content-items">
                    <h2 className="question">
                        {t("profile.who-actions")}
                    </h2>
                    <Row>
                        <Col xs={12} md={4} className="mb-4">
                            <Card
                                onClick={() => { this.goTo(renderRoute(PageNames.myOrdersPage)); }}
                                className="item"
                            >
                                <CardContent>
                                    <h5><ListAltIcon/> {t("profile.mine-packages")}</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs="12" md="4" className="mb-4">
                            <Card
                                onClick={() => { this.goTo(renderRoute(PageNames.myDirectionsPage)); }}
                                className="item"
                            >
                                <CardContent>
                                    <h5><RoomIcon/> {t("profile.mine-directions")}</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs="12" md="4" className="mb-4">
                            <Card
                                onClick={() => { this.goTo(renderRoute(PageNames.editProfilePage)); }}
                                className="item"
                            >
                                <CardContent>
                                    <h5><AccountBoxIcon/> {t("profile.edit-profile")}</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs="12" md="4" className="mb-4">
                            <Card
                                onClick={() => { this.goTo(renderRoute(PageNames.billElectronicPage)); }}
                                className="item"
                            >
                                <CardContent>
                                    <h5><ReceiptIcon/> {t("profile.bill-electronic")}</h5>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(withNamespaces("page")(ProfileView));