import * as React           from "react";
import { inject, observer } from "mobx-react";
import { PageStore }        from "../../Store/PageStore";
import { UserStore }        from "../../Store/UserStore";
import ProfileView          from "../../Views/Pages/User/ProfileView";
import User                 from "../../Models/User/User";
import { observable }       from "mobx";
import Box                  from "@material-ui/core/Box";
import CircularProgress     from "@material-ui/core/CircularProgress";
import EditProfileView      from "../../Views/Pages/User/EditProfileView";

interface IEditProfileViewModelProps {
    PageStore?: PageStore;
    UserStore?: UserStore;
}

@inject(UserStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class EditProfileViewModel extends React.Component<IEditProfileViewModelProps, {}> {
    @observable
    private loading: boolean = true;

    public componentDidMount = async () => {
        this.loading = false;
    };

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public render(): React.ReactNode {
        if (this.loading) return (
            <Box justifyContent="center" className="d-flex m-4">
                <CircularProgress color="secondary"/>
            </Box>
        );

        return (
            <EditProfileView
                user={this.userStore.getUser() as User}
            />
        );
    }
}

export default EditProfileViewModel;