import BaseStore                                                      from "./Base/BaseStore";
import { deserialize }                                                from "serializr";
import { Container }                                                  from "typedi";
import AjaxService                                                    from "../Service/AjaxService";
import { observable }                                                 from "mobx";
import { Tutorial }                                                  from "../Models/Tutorial/Tutorial";
import { IPaginationData, tutorialPagerOptions } from "../config/pagination";

export class TutorialStore extends BaseStore {
    public static readonly NAME_STORE: string = "TutorialStore";

    @observable
    private tutorials: Tutorial[] = [];

    @observable
    private tutorial: Tutorial;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getTutorial(): Tutorial {
        return this.tutorial;
    }

    public setTutorial(value: Tutorial): this {
        this.tutorial = value;

        return this;
    }

    public getTutorials(): Tutorial[] {
        return this.tutorials;
    }

    public setTutorials(value: Tutorial[]): this {
        this.tutorials = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Tutorial>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateTutorials(
            this.getCurrentPage(),
            tutorialPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Tutorial, item)),
            pages: dataResponse.pages
        } as IPaginationData<Tutorial>;
    }

    public async getBySlug(slug: string): Promise<Tutorial> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getTutorialSlug(slug);

        return deserialize(Tutorial, dataResponse);
    }
}
