import * as React           from "react";
import { inject, observer } from "mobx-react";
import { UserStore }        from "../../Store/UserStore";
import BillElectronicView   from "../../Views/Pages/User/BillElectronicView";
import User                 from "../../Models/User/User";

interface IBillElectronicViewModelProps {
    UserStore?: UserStore;
}

@inject(UserStore.NAME_STORE)
@observer
class BillElectronicViewModel extends React.Component<IBillElectronicViewModelProps, {}> {
    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public render(): React.ReactNode {
        return (
            <BillElectronicView
                user={this.userStore.getUser() as User}
            />
        );
    }
}

export default BillElectronicViewModel;