import BaseStore      from "./Base/BaseStore";
import { observable } from "mobx";
import SnackBar       from "../Models/SnackBar/SnackBar";

class SnackBarStore extends BaseStore {
    public static readonly NAME_STORE: string = "SnackBarStore";

    @observable
    private snackBar: SnackBar;

    public init() {
        this.needPersistData = false;
        this.setSnackBar(new SnackBar());
    }

    public setSnackBar(modal: SnackBar) {
        this.snackBar = modal;
    }

    public getSnackBar(): SnackBar {
        return this.snackBar;
    }
}

export { SnackBarStore };
