import * as React        from "react";
import { Page }          from "../../Models/Page/Page";
import { observable }    from "mobx";
import CircularProgress  from "@material-ui/core/CircularProgress";
import ProductView       from "../../Views/Components/Home/ProductView";
import { Product }       from "../../Models/Product/Product";
import { deserialize }   from "serializr";
import { ExtraDataHome } from "../../Models/Page/ExtraDataHome";
import {
    inject,
    observer
}                        from "mobx-react";
import { ProductStore }  from "../../Store/ProductStore";
import { Box }           from "@material-ui/core";

interface IProductViewModel {
    ProductStore?: ProductStore;
    page: Page;
}

@inject(ProductStore.NAME_STORE)
@observer
class ProductViewModel extends React.Component<IProductViewModel, {}> {
    @observable
    private loading: boolean    = true;
    private products: Product[] = [];

    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    public componentDidMount = async () => {
        const extraData = deserialize(ExtraDataHome, this.props.page.getExtraData() || {});
        this.products   = await this.productStore.getProductsByIdsList(extraData.getProductIds());
        this.loading    = false;
    }

    public render(): React.ReactNode {

        if (this.loading)
            return (
                <Box justifyContent="center" className="d-flex mb-4">
                    <CircularProgress color="secondary"/>
                </Box>
            );

        return (<ProductView
            products={this.products}
        />);

    }
}

export default ProductViewModel;
