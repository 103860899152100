import { serializable } from "serializr";

export default class PageImage {
    @serializable
    private page: string;

    @serializable
    private backgroundImage: string;

    /**
     * Getter page
     * @return {string}
     */
    public getPage(): string {
        return this.page;
    }

    /**
     * Getter backgroundImage
     * @return {string}
     */
    public getBackgroundImage(): string {
        return this.backgroundImage;
    }

    /**
     * Setter page
     * @param {string} value
     */
    public setPage(value: string) {
        this.page = value;
    }

    /**
     * Setter backgroundImage
     * @param {string} value
     */
    public setBackgroundImage(value: string) {
        this.backgroundImage = value;
    }

}
