import React, { ElementType } from "react";
import { Container, Service } from "typedi";
import { ApplicationStore }   from "../Store/ApplicationStore";
import { ModalStore }         from "../Store/ModalStore";
import Modal                  from "../Models/Modal/Modal";

@Service()
class ModalService {
    private propsInject: any = {};

    public getPropsInject(): any {
        return this.propsInject;
    }

    public setPropsInject(props: any) {
        this.propsInject = props;
    }

    private getApplicationStore(): ApplicationStore {
        return Container.get(ApplicationStore);
    }

    private getModalStore(): ModalStore {
        return this.getApplicationStore().getStore(ModalStore);
    }

    private getModal(): Modal {
        return this.getModalStore().getModal();
    }

    public printLayoutModal() {
        const COMPONENT = this.getModalStore().getModal().getCurrentModal();
        return COMPONENT ? <COMPONENT {...this.getPropsInject()}/> : COMPONENT;
    }

    public openModal<T extends ElementType>(T: React.ComponentType, props: React.ComponentProps<T>) {
        this.setPropsInject(props);
        this.getModal()
            .setOpenModal(true)
            .setCurrentModal(T);
    }

    public closeModal = () => {
        this.getModalStore().getModal()
            .setCurrentModal(undefined)
            .setOpenModal(false);

        this.setPropsInject({});
    };
}

export default ModalService;
