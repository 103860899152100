import { observer }                                         from "mobx-react";
import * as React                                           from "react";
import { CalculateShipping }                                from "../../../Models/CalculateShipping/CalculateShipping";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

interface IShowDirectionDataProps {
    calculateShipping: CalculateShipping;
}

@observer
class ShowDirectionData extends React.Component<IShowDirectionDataProps, {}> {
    public render(): React.ReactNode {
        const {REACT_APP_ENV} = process.env;

        if (REACT_APP_ENV && ["dev", "uat"].indexOf(REACT_APP_ENV.toLowerCase()) === -1) {
            return <React.Fragment/>;
        }

        const {calculateShipping} = this.props;

        if (!calculateShipping) {
            return <React.Fragment/>;
        }

        return (
            <div className="mb-5">
                <h6 className="font-weight-bold">
                    Esta tabla solo aparece en el ambiente de stage (pruebas), ya en el servidor de produccion no
                    aparece. Esto es unicamente para hacer debug.
                </h6>
                <Table>
                    <TableBody className="table-head">
                        <TableRow className="item">
                            <TableCell>
                                KMs
                            </TableCell>
                            <TableCell>
                                {calculateShipping.getKms()}
                            </TableCell>
                        </TableRow>
                        <TableRow className="item">
                            <TableCell>
                                Precio
                            </TableCell>
                            <TableCell>
                                {calculateShipping.getPrice()}
                            </TableCell>
                        </TableRow>
                        <TableRow className="item">
                            <TableCell>
                                Peso
                            </TableCell>
                            <TableCell>
                                {calculateShipping.getWeight()}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export {
    ShowDirectionData
};