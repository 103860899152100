import {
    inject,
    observer
}                            from "mobx-react";
import * as React            from "react";
import { Redirect }          from "react-router";
import { CarStore }          from "../../Store/CarStore";
import SelectDirectionView   from "../../Views/Pages/Car/SelectDirectionView";
import User                  from "../../Models/User/User";
import { Order }             from "../../Models/Order/Order";
import { UserStore }         from "../../Store/UserStore";
import {
    observable,
    reaction
}                            from "mobx";
import CircularProgress      from "@material-ui/core/CircularProgress";
import Box                   from "@material-ui/core/Box";
import {
    ERROR_CLASS,
    FormStore
}                            from "../../Store/FormStore";
import { withNamespaces }    from "react-i18next";
import { scrollToElement }   from "../../Utils/common";
import { withRouter }        from "react-router-dom";
import IBaseProps            from "../Props/IBaseProps";
import {
    PageNames,
    renderRoute
}                            from "../../Routes/routes";
import { CalculateShipping } from "../../Models/CalculateShipping/CalculateShipping";

interface ISelectDirectionViewModelProps extends IBaseProps {
    CarStore?: CarStore;
    FormStore?: FormStore;
    UserStore?: UserStore;
}

export enum CollapseStep {
    EMPTY                = "EMPTY",
    METHOD_TRANSPORT     = "METHOD_TRANSPORT",
    CHOOSE_DIRECTION     = "CHOOSE_DIRECTION",
    METHOD_PAY           = "METHOD_PAY",
    DATA_BILL_ELECTRONIC = "DATA_BILL_ELECTRONIC",
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE, FormStore.NAME_STORE)
@observer
class SelectDirectionViewModel extends React.Component<ISelectDirectionViewModelProps, {}> {
    @observable
    private collapseStep: CollapseStep = CollapseStep.CHOOSE_DIRECTION;

    private errorDOMElement: HTMLElement | null;

    @observable
    private loading: boolean = true;

    @observable
    private loadingCalculateShipping: boolean = true;

    public getCollapseStep = (): CollapseStep => {
        return this.collapseStep;
    };

    public constructor(props: ISelectDirectionViewModelProps) {
        super(props);
        this.formStore.setValidator(this.formStore.getNewValidator(props.t));
    }

    public setCollapseStep = (value: CollapseStep) => {
        this.collapseStep = value;
    };

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }


    protected getLoadingCalculateShipping = (): boolean => {
        return this.loadingCalculateShipping;
    };

    protected setLoadingCalculateShipping = (value: boolean) => {
        this.loadingCalculateShipping = value;
    };

    public componentDidMount = async () => {
        if (!this.carStore.getOrder())
            this.carStore.setOrder(new Order());

        await this.calculatePriceShipping();

        const order = this.carStore.getOrder();

        await this.carStore.refreshProductStore();

        reaction(() => {
            return this.carStore.getProductStore().map(item => item.getQuantity());
        }, this.calculatePriceShipping);

        reaction(() => {
            return order.getMethodTransportId();
        }, this.calculatePriceShipping);

        reaction(() => {
            return order.getDirection();
        }, this.calculatePriceShipping);

        this.loading = false;
    };

    protected calculatePriceShipping = async () => {
        const order             = this.carStore.getOrder(),
              direction         = order.getDirection(),
              methodTransportId = order.getMethodTransportId();

        if (!direction || !methodTransportId) {
            this.setLoadingCalculateShipping(false);
            return;
        }

        if (order.getMethodTransport().getPickUp()) {
            this.setLoadingCalculateShipping(false);
            return;
        }

        if (!direction.getLat()) {
            this.setLoadingCalculateShipping(false);
            // @ts-ignore
            order.setDirection(undefined);
            order.setCalculateShipping(undefined as unknown as CalculateShipping);
            this.carStore.resetStore();
            window.location.reload();
            return;
        }

        this.setLoadingCalculateShipping(true);
        const data = await this.carStore.getCalculatePriceShipping({
            destiny          : direction.getLatLngString(),
            methodTransportId: methodTransportId,
            productItemCar   : this.carStore.getProductStore(),
        });

        this.carStore.setCalculateShipping(data);
        this.carStore.getOrder().setCalculateShipping(data);
        this.setLoadingCalculateShipping(false);
    };

    private setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    private openCollapseByError = () => {
        const validator = this.formStore.getValidator(),
              fields    = validator.fields,
              {t}       = this.props;

        for (const key in fields) {
            if (fields.hasOwnProperty(key) && !fields[key]) {
                switch (key) {
                    case "direction":
                        this.setCollapseStep(CollapseStep.CHOOSE_DIRECTION);
                        break;
                    case t("car:method-transport"):
                        this.setCollapseStep(CollapseStep.METHOD_TRANSPORT);
                        break;
                    case t("car:method-pay"):
                        this.setCollapseStep(CollapseStep.METHOD_TRANSPORT);
                        break;
                }
            }
        }
    };

    private scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) {
            scrollToElement(this.errorDOMElement, 1000, 400);
        }
    };

    protected continueAction = () => {
        this.purgeValidator();
        const validator = this.formStore.getValidator(),
              valid     = validator.allValid(),
              {history} = this.props;

        if (!valid) {
            validator.showMessages();
            this.openCollapseByError();
            this.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                setTimeout(this.scrollOnError, 200);
            });

            return;
        }

        history.push(renderRoute(PageNames.reviewOrderPage));
    };

    public purgeValidator = () => {
        this.formStore.getValidator().purgeFields();
        this.forceUpdate();
    }

    public render(): React.ReactNode {
        if (!this.carStore.getOrder() || this.loading) {
            return (
                <Box justifyContent="center" className="d-flex m-4">
                    <CircularProgress color="secondary"/>
                </Box>
            );
        }

        if (this.carStore.getProductStore().length === 0) {
            return (<Redirect to={renderRoute(PageNames.carPage)}/>);
        }

        return (
            <SelectDirectionView
                user={this.userStore.getUser() as User}
                order={this.carStore.getOrder()}
                collapseStep={this.getCollapseStep()}
                setCollapseStep={this.setCollapseStep}
                productItemCars={this.carStore.getProductStore()}
                removeProduct={this.carStore.removeProduct}
                continueAction={this.continueAction}
                validator={this.formStore.getValidator()}
                calculateShipping={this.carStore.getCalculateShipping()}
                subTotalProducts={this.carStore.getSubTotalProducts(this.carStore.getProductStore())}
                loadingCalculateShipping={this.getLoadingCalculateShipping()}
                purgeValidator={this.purgeValidator}
            />
        );
    }
}

export default withRouter(withNamespaces("forms")(SelectDirectionViewModel));