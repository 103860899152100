import * as React                         from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";

interface BackTopProps extends WithNamespaces {
    prefix?: string;
}

@InjectPrefix
class BackTop extends React.Component<BackTopProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private goTop = () => {
        window.scrollTo({
                            behavior: "smooth",
                            left    : 0,
                            top     : 0
                        });
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <a className={`back-top ${this.prefix}-primary-color-light-theme text-center`}
               onClick={this.goTop}>
                <span>{t("back-to-top")}</span>
            </a>
        );
    }
}

export default withNamespaces("footer")(BackTop);
