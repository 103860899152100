import * as React                         from "react";
import { observer }                       from "mobx-react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import SimpleReactValidator               from "simple-react-validator";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
}                                  from "@material-ui/core";
import { BillElectronic }          from "../../../Models/User/BillElectronic";
import { TYPE_DNI }                from "../../../config/typeDNI";

interface IFormBillElectronicViewProps extends WithNamespaces {
    billElectronic: BillElectronic;
    send: boolean;
    sending: boolean;
    validator: SimpleReactValidator;
    submit: (form: React.Component) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class FormBillElectronicView extends React.Component<IFormBillElectronicViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  send,
                  sending,
              } = this.props;

        let text = t("submit");

        if (sending) text = (<CircularProgress color="primary"/>);
        if (send) text = t("bill-electronic-saved");

        const canSend = !(send || sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className="mt-2"
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  billElectronic,
                  t,
                  validator,
              } = this.props;

        return (
            <form className={`${this.prefix}-form-contact-us mb-4`} noValidate autoComplete="off">
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="type-dni" variant="outlined">
                        {t("type-dni")}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        required
                        fullWidth
                        value={billElectronic.getTypeDni() || ""}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: TYPE_DNI }>) => {
                            billElectronic.setTypeDni(event.target.value);
                        }}
                        inputProps={{
                            id  : "type-dni",
                            name: "type-dni",
                        }}
                    >
                        {
                            Object.keys(TYPE_DNI).map((item: string) => {
                                return (
                                    <MenuItem value={item} key={item}>
                                        {t(`dni:${item}`)}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                    {validator.message(t("type-dni"), billElectronic.getTypeDni(), "required")}
                </FormControl>
                <TextField
                    id="form-dni"
                    label={t("dni")}
                    fullWidth
                    required
                    value={billElectronic.getDni()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        billElectronic.setDni(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("dni"), billElectronic.getDni(), "required")}}
                />
                <TextField
                    id="form-fullName"
                    label={t("fullName")}
                    fullWidth
                    required
                    value={billElectronic.getFullName()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        billElectronic.setFullName(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("fullName"), billElectronic.getFullName(), "required")}}
                />
                {validator.message(t("fullName"), billElectronic.getFullName(), "required")}
                <TextField
                    id="form-email"
                    label={t("email")}
                    fullWidth
                    required
                    type="email"
                    value={billElectronic.getEmail()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        billElectronic.setEmail(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("email"), billElectronic.getEmail(), "required")}}
                />
                {validator.message(t("email"), billElectronic.getEmail(), "required|email")}
                <TextField
                    id="form-phone"
                    label={t("phone")}
                    fullWidth
                    required
                    value={billElectronic.getPhone()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        billElectronic.setPhone(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("phone"), billElectronic.getPhone(), "required")}}
                />
                {validator.message(t("phone"), billElectronic.getPhone(), "required")}
                <TextField
                    id="form-direction"
                    label={t("direction")}
                    fullWidth
                    required
                    value={billElectronic.getDirection()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        billElectronic.setDirection(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    multiline
                    InputLabelProps={{error: !!validator.message(t("email"), billElectronic.getDirection(), "required")}}
                />
                {validator.message(t("direction"), billElectronic.getDirection(), "required")}
                <FormControlLabel
                    className="d-block"
                    control={
                        <Checkbox
                            checked={billElectronic.getPrincipal()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                billElectronic.setPrincipal(event.target.checked);
                            }}
                            name="principal"
                            color="primary"
                        />
                    }
                    label={t("principal-bill-electronic")}
                />
                {this.renderButton()}
            </form>
        );
    }
}

export default withNamespaces("forms")(FormBillElectronicView);
