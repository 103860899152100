import { serializable } from "serializr";
import { observable }   from "mobx";

export class Direction {
    @serializable
    @observable
    private dni: string = "";

    @serializable
    @observable
    private direction: string = "";

    @serializable
    @observable
    private fullName: string = "";

    @serializable
    @observable
    private uid: string;

    @serializable
    @observable
    private lat: string;

    @serializable
    @observable
    private lng: string;

    @serializable
    @observable
    private province: string = "";

    @serializable
    @observable
    private canton: string = "";

    @serializable
    @observable
    private phone: string = "";

    @serializable
    @observable
    private principal: boolean = false;

    public getDni(): string {
        return this.dni;
    }

    public setDni(value: string) {
        this.dni = value;
    }

    public getDirection(): string {
        return this.direction;
    }

    public setDirection(value: string) {
        this.direction = value;
    }

    public getProvincie(): string {
        return this.province;
    }

    public setProvince(value: string) {
        this.province = value;

        return this;
    }

    public getCanton(): string {
        return this.canton;
    }

    public setCanton(value: string) {
        this.canton = value;
    }

    public getPhone(): string {
        return this.phone;
    }

    public setPhone(value: string) {
        this.phone = value;
    }

    public getPrincipal(): boolean {
        return this.principal;
    }

    public setPrincipal(value: boolean) {
        this.principal = value;
    }

    public getLat(): string {
        return this.lat;
    }

    public setLat(value: string) {
        this.lat = value;
    }

    public getLng(): string {
        return this.lng;
    }

    public getUID(): string {
        return this.uid;
    }

    public setLng(value: string) {
        this.lng = value;
    }

    public getFullName(): string {
        return this.fullName;
    }

    public setFullName(value: string) {
        this.fullName = value;
    }

    public getLatLngString(): string {
        return `${this.getLat()},${this.getLng()}`;
    }
}