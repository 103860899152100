import * as React                         from "react";
import { observer }                       from "mobx-react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import SimpleReactValidator               from "simple-react-validator";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
}                                         from "@material-ui/core";
import { Direction }                      from "../../../Models/User/Direction";
import MapSelectDirectionViewModel        from "../../../ViewModel/MapSelectDirection/MapSelectDirectionViewModel";
import { locations }                      from "../../../config/location";

interface IFormDirectionViewProps extends WithNamespaces {
    direction: Direction;
    send: boolean;
    sending: boolean;
    validator: SimpleReactValidator;
    submit: (form: React.Component) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class FormDirectionView extends React.Component<IFormDirectionViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  send,
                  sending,
              } = this.props;

        let text = t("submit");

        if (sending) text = (<CircularProgress color="primary"/>);
        if (send) text = t("direction-saved");

        const canSend = !(send || sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className="mt-2"
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  direction,
                  t,
                  validator,
              } = this.props;

        return (
            <form className={`${this.prefix}-form-contact-us mb-4`} noValidate autoComplete="off">
                <TextField
                    id="form-dni"
                    label={t("dni")}
                    fullWidth
                    required
                    value={direction.getDni()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        direction.setDni(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("dni"), direction.getDni(), "required")}}
                />
                <TextField
                    id="form-fullName"
                    label={t("fullName")}
                    fullWidth
                    required
                    value={direction.getFullName()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        direction.setFullName(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("fullName"), direction.getFullName(), "required")}}
                />
                {validator.message(t("fullName"), direction.getFullName(), "required")}
                <TextField
                    id="form-phone"
                    label={t("phone")}
                    fullWidth
                    required
                    value={direction.getPhone()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        direction.setPhone(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message(t("phone"), direction.getPhone(), "required")}}
                />
                {validator.message(t("phone"), direction.getDni(), "required")}
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="province" variant="outlined">
                        {t("province")}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        required
                        fullWidth
                        value={direction.getProvincie() || ""}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) => {
                            direction.setProvince(event.target.value);
                            direction.setCanton("");
                        }}
                        inputProps={{
                            id  : "province",
                            name: "province",
                        }}
                    >
                        {
                            Object.keys(locations).map((item: string) => {
                                return (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                    {validator.message(t("province"), direction.getProvincie(), "required")}
                </FormControl>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="canton" variant="outlined">
                        {t("canton")}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        required
                        fullWidth
                        value={direction.getCanton() || ""}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) => {
                            direction.setCanton(event.target.value);
                        }}
                        inputProps={{
                            id  : "canton",
                            name: "canton",
                        }}
                    >
                        {
                            Object.keys(locations[direction.getProvincie()].cantones).map((item: string) => {
                                return (
                                    <MenuItem value={locations[direction.getProvincie()].cantones[item]} key={item}>
                                        {locations[direction.getProvincie()].cantones[item]}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                    {validator.message(t("canton"), direction.getCanton(), "required")}
                </FormControl>
                <MapSelectDirectionViewModel
                    direction={direction}
                />
                {validator.message(t("direction"), direction.getLat(), "required")}
                <TextField
                    id="form-direction-text-area"
                    label={t("direction")}
                    fullWidth
                    required
                    value={direction.getDirection()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        direction.setDirection(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    multiline={true}
                    rows={6}
                    InputLabelProps={{error: !!validator.message(t("direction"), direction.getDirection(), "required")}}
                />
                {validator.message(t("direction"), direction.getDirection(), "required")}
                <FormControlLabel
                    className="d-block"
                    control={
                        <Checkbox
                            checked={direction.getPrincipal()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                direction.setPrincipal(event.target.checked);
                            }}
                            name="principal"
                            color="primary"
                        />
                    }
                    label={t("principal-direction")}
                />
                {this.renderButton()}
            </form>
        );
    }
}

export default withNamespaces("forms")(FormDirectionView);
