import { observer } from "mobx-react";
import * as React from "react";
import InjectPrefix from "../../../Decorators/InjectPrefix";
import { ProductItemCar } from "../../../Models/ProductItemCar/ProductItemCar";
import { Container } from "reactstrap";
import { Button, } from "@material-ui/core";
import { Product } from "../../../Models/Product/Product";
import {
    Link,
    withRouter
} from "react-router-dom";
import { withNamespaces } from "react-i18next";
import IBaseProps from "../../../ViewModel/Props/IBaseProps";
import TableProducts from "../../Components/Table/TableProducts";
import {
    PageNames,
    renderRoute
} from "../../../Routes/routes";

interface ICarViewProps extends IBaseProps {
    productItemCars: ProductItemCar[];
    removeProduct: (product: Product) => ProductItemCar[];
    prefix?: string;
}

@InjectPrefix
@observer
class CarView extends React.Component<ICarViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected showButtons = () => {
        const {
                  productItemCars,
                  t,
              } = this.props;

        if (!(productItemCars.length > 0)) return (<React.Fragment/>);

        return (
            <div className="cta-container">
                <Button
                    className="btn-primary"
                    component={Link}
                    to={renderRoute(PageNames.selectDirectionsPage)}
                    color="primary"
                    variant="contained"
                >
                    {t("continue")}
                </Button>
            </div>
        );
    };

    public render(): React.ReactNode {
        const {
                  productItemCars,
                  removeProduct,
                  t,
              } = this.props;

        return (
            <div className={`${this.prefix}-p-car`}>
                <Container>
                    <h2 className="title">
                        {t("car-to-buy")}
                    </h2>
                    {productItemCars.length > 0
                        ? <TableProducts
                            productItemCars={productItemCars}
                            removeProduct={removeProduct}
                        />
                        : <h3>{t("empty-car")}</h3>}
                    {this.showButtons()}
                </Container>
            </div>
        );
    }
}

export default withRouter(withNamespaces("car")(CarView));
