import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { PrincipalSearch }                from "../../../Models/Product/PrincipalSearch";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { Paper, TextField, Chip }         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Button                             from "@material-ui/core/Button";
import { Search }                         from "@material-ui/icons";
import { Category }                       from "../../../Models/Product/Category";
import { TextFieldProps }                 from "@material-ui/core/TextField";

interface ISearchProductProps extends WithNamespaces {
    principalSearch: PrincipalSearch;
    search: () => void;
    loading: boolean;
    removeCategory: (category: Category) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class SearchProduct extends React.Component<ISearchProductProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected getChips = (): React.ReactNode => {
        const elements: React.ReactNode[] = [],
              {
                  principalSearch,
                  removeCategory,
                  t,
                  i18n,
              }                           = this.props;

        principalSearch.getSubCategoriesMap().forEach((item: Category) => {
            const key  = `categories:${item.getName().toLowerCase()}`,
                  text = i18n.exists(key)
                         ? t(key)
                         : item.getName();
            elements.push(
                <Chip
                    label={text}
                    key={item.getId()}
                    onDelete={() => {
                        removeCategory(item);
                    }}
                    className="chip"
                />
            );
        });

        return elements;
    };

    protected handleKeyDown = (e: TextFieldProps) => {
        const {
                  search,
                  loading,
              } = this.props;

        if (e.key === "Enter") {
            if (!loading) search();
        }
    };

    public render(): React.ReactNode {
        const {
                  t,
                  principalSearch,
                  search,
                  loading,
              } = this.props;
        return (
            <Paper elevation={1} className={`${this.prefix}-search-product`}>
                <div className="form-group-search">
                    <TextField
                        id="form-search"
                        label={t("search-products")}
                        value={principalSearch.getText() || ""}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            principalSearch.setText(evt.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        className="input-search"
                        onKeyDown={this.handleKeyDown}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className="btn-search"
                        onClick={() => {
                            if (!loading) search();
                        }}
                        disabled={loading}
                    >
                        <Search className="icon"/>
                    </Button>
                </div>
                <div className="chips-content">
                    {this.getChips()}
                </div>
            </Paper>
        );
    }

}

export default withNamespaces("forms")(SearchProduct);