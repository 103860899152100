import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button           from "@material-ui/core/Button";
import Box              from "@material-ui/core/Box";
import { Container }    from "@material-ui/core";
import { Col, Row }     from "reactstrap";
import InjectPrefix     from "../../Decorators/InjectPrefix";
import Carousel         from "../Components/Carousel/Carousel";
import CardPromotion    from "../Components/Card/CardPromotion";
import { Promotion }    from "../../Models/Promotion/Promotion";
import { Gallery }      from "../../Models/Gallery/Gallery";
import { Page }         from "../../Models/Page/Page";
import Seo              from "../Components/Header/Seo";

interface PromotionsViewProps extends WithNamespaces {
    prefix?: string;
    promotions: Promotion[];
    showMore: () => void;
    loading: boolean;
    currentPage: number;
    pages: number;
    gallery: Gallery;
    page: Page | undefined;
}

@InjectPrefix
class PromotionsView extends React.Component<PromotionsViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private renderLoading = (): React.ReactNode => {
        const {
                  t,
                  showMore,
                  loading,
                  currentPage,
                  pages
              } = this.props;

        if (loading)
            return (<CircularProgress color="secondary"/>);

        if (currentPage > pages || (currentPage === 1 && pages === 1))
            return (<React.Fragment/>);

        return (
            <Button variant="outlined" color="secondary" onClick={showMore}>
                {t("global:show-more")}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  promotions,
                  gallery,
                  page
              } = this.props;

        return (
            <React.Fragment>
                <Seo seoData={page ? page.getSeoData() : undefined}/>
                <Carousel
                    gallery={gallery}
                />
                <Container>
                    <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("promotions")}
                    </h1>
                    <hr/>
                    <Row className={`${this.prefix}-container-cards`}>
                        {promotions.map((promotion: Promotion, key: number) => {
                            return (
                                <Col xs={12} sm={4} lg={3} key={key} className="mb-3">
                                    <CardPromotion
                                        promotion={promotion}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Box justifyContent="center" className="d-flex mb-4">
                        {this.renderLoading()}
                    </Box>
                </Container>
            </React.Fragment>
        );
    }
}

export default withNamespaces("page")(PromotionsView);
