import InjectPrefix                                                    from "../../../Decorators/InjectPrefix";
import { observer }                                                    from "mobx-react";
import * as React                                                      from "react";
import User                                                            from "../../../Models/User/User";
import { Order }                                                       from "../../../Models/Order/Order";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";
import { MethodsPay }                                                  from "../../../config/MethodsPay";
import { withNamespaces, WithNamespaces }                              from "react-i18next";
import NoteMethodTransfer
                                                                       from "../../Components/NoteMethodTransfer/NoteMethodTransfer";
import NoteMethodCard
                                                                       from "../../Components/NoteMethodCard/NoteMethodCard";

interface IChooseMethodToPayViewProps extends WithNamespaces {
    methodsToPay: MethodsPay[];
    onChangeMethodToPay: (value: MethodsPay) => void;
    user: User;
    order: Order;
    purgeValidator?: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class ChooseMethodToPayView extends React.Component<IChooseMethodToPayViewProps, {}> {
    protected renderMethodToPay = (): React.ReactNode => {
        const {
                  methodsToPay,
                  onChangeMethodToPay,
                  order,
                  t
              } = this.props;

        return (
            <FormControl component="fieldset">
                <RadioGroup aria-label="method-transport"
                            name="method-transport"
                            value={order.getMethodPay()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                onChangeMethodToPay(methodsToPay.find(item => item === value) as MethodsPay);
                            }}>
                    {
                        methodsToPay.map((methodToPay: MethodsPay) => {
                            return (
                                <FormControlLabel value={methodToPay}
                                                  control={<Radio/>}
                                                  label={t(methodToPay)}
                                                  key={methodToPay}
                                />
                            );
                        })
                    }
                </RadioGroup>

            </FormControl>
        );
    };

    protected renderNote = () => {
        const {
                  order,
              } = this.props;

        switch (order.getMethodPay()) {
            // case MethodsPay.CARD:
            //     return (<NoteMethodCard/>);
            case MethodsPay.TRANSFER:
                return (<NoteMethodTransfer/>);

            default:
                return (<React.Fragment/>);
        }
    };

    public render(): React.ReactNode {
        const {
                  order,
                  t,
                  purgeValidator,
              } = this.props;

        return (
            <div>
                <h2 className="title">
                    Seleccion una metodo de pago
                </h2>
                {this.renderMethodToPay()}
                {this.renderNote()}
                {/*<NoteMethodCard/>*/}
                {
                    <FormControlLabel
                        className="d-block"
                        control={
                            <Checkbox
                                checked={order.getIsBillElectronic()}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.checked;
                                    order.setIsBillElectronic(value);

                                    if (!value) {
                                        // @ts-ignore
                                        order.setBillElectronic(undefined);
                                    }
                                    if (purgeValidator)
                                        purgeValidator();
                                }}
                                name="principal"
                                color="primary"
                            />
                        }
                        label={t("car:need-bill-electronic")}
                    />
                }
            </div>
        );
    }
}

export default withNamespaces("methods-pay")(ChooseMethodToPayView);