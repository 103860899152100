import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { Product }                        from "../../../Models/Product/Product";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Checkbox                           from "@material-ui/core/Checkbox";
import { Box }                            from "@material-ui/core";
import NumberFormat                       from "../NumberFormat/NumberFormat";

interface IProductRelationshipProps extends WithNamespaces {
    product: Product;
    checked: boolean;
    handleChange: (item: boolean) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class ProductRelationship extends React.Component<IProductRelationshipProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  checked,
                  handleChange,
                  product,
                  t,
              } = this.props;

        return (
            <li>
                <Checkbox
                    checked={checked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event.target.checked);
                    }}
                />
                <span>
                    {product.getName()}
                </span>
                <NumberFormat
                    value={product.getPrice()}
                    renderText={(formattedValue: string) =>
                        <Box component={"span"} className="price">
                            {formattedValue}
                        </Box>
                    }
                />
            </li>
        );
    }
}

export default withNamespaces("global")(ProductRelationship);