import * as React                    from "react";
import { inject, observer }          from "mobx-react";
import { RecommendationPersonStore } from "../Store/RecommendationPersonStore";
import { GalleryStore }              from "../Store/GalleryStore";
import { Gallery }                   from "../Models/Gallery/Gallery";
import { observable }                from "mobx";
import { galleryPages }              from "../config/GalleryPages";
import { Page }                      from "../Models/Page/Page";
import { PageStore }                 from "../Store/PageStore";
import { pages }                     from "../config/Pages";
import RecommendationPersonsView     from "../Views/Pages/RecommendationPersonsView";
import { OccupationStore }           from "../Store/OccupationStore";
import { Occupation }                from "../Models/Occupation/Occupation";

interface ProfileViewModelProps {
    RecommendationPersonStore?: RecommendationPersonStore;
    GalleryStore?: GalleryStore;
    PageStore?: PageStore;
    OccupationStore?: OccupationStore;
}

@inject(RecommendationPersonStore.NAME_STORE, GalleryStore.NAME_STORE, PageStore.NAME_STORE, OccupationStore.NAME_STORE)
@observer
class RecommendationPersonsViewModel extends React.Component<ProfileViewModelProps, {}> {
    @observable
    private gallery: Gallery;

    @observable
    private page: Page | undefined;

    @observable
    private occupationsMap: Map<string, Occupation> = new Map<string, Occupation>();

    get occupationStore(): OccupationStore {
        return this.props.OccupationStore as OccupationStore;
    }

    get recommendationPersonStore(): RecommendationPersonStore {
        return this.props.RecommendationPersonStore as RecommendationPersonStore;
    }

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    constructor(props: ProfileViewModelProps) {
        super(props);
        this.recommendationPersonStore.setRecommendationPersons([]);
    }

    private showMore = () => {
        this.getPage(this.recommendationPersonStore.getCurrentPage() + 1);
    };

    protected async getGalleryPage() {
        this.gallery = await this.galleryStore.getGalleryPage(galleryPages.recommendationPerson);
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
        this.getGalleryPage();
        this.page = await this.pageStore.getPage(pages.recommendationPerson);
        const response = await this.occupationStore.setCurrentPage(1).getPaginate();

        this.occupationStore
            .setPages(response.pages)
            .setOccupations(response.data);

        response.data.map((item: Occupation) => this.occupationsMap.set(item.getId(), item));
    };

    public getPage = async (page: number) => {
        this.recommendationPersonStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.recommendationPersonStore.getPaginate();

        this.recommendationPersonStore
            .setPages(response.pages)
            .setRecommendationPersons([...this.recommendationPersonStore.getRecommendationPersons(), ...response.data])
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        return (
            <RecommendationPersonsView
                recommendationPersons={this.recommendationPersonStore.getRecommendationPersons()}
                showMore={this.showMore}
                loading={this.recommendationPersonStore.getSpinner()}
                currentPage={this.recommendationPersonStore.getCurrentPage()}
                pages={this.recommendationPersonStore.getPages()}
                gallery={this.gallery}
                page={this.page}
                occupationsMap={this.occupationsMap}
            />
        );
    }
}

export default RecommendationPersonsViewModel;
