import { observer }                           from "mobx-react";
import * as React                             from "react";
import { Button, Card, Container, TextField } from "@material-ui/core";
import InjectPrefix                           from "../../../Decorators/InjectPrefix";
import { withNamespaces, WithNamespaces }     from "react-i18next";
import SimpleReactValidator                   from "simple-react-validator";
import CircularProgress                       from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon                       from "@material-ui/icons/ErrorOutline";
import { ERROR_CLASS }                        from "../../../Store/FormStore";
import SocialLoginViewModel                   from "../../../ViewModel/SocialLogin/SocialLoginViewModel";
import { Login }                              from "../../../Models/Form/Login";
import { Link }                               from "react-router-dom";
import { PageNames, renderRoute }             from "../../../Routes/routes";

interface IRegisterViewProps extends WithNamespaces {
    prefix?: string;
    login: Login;
    validator: SimpleReactValidator;
    send: boolean;
    existUser: boolean;
    sending: boolean;
    passwordIncorrect: boolean;
    submit: (form: React.Component) => void;
}

@InjectPrefix
@observer
class LoginView extends React.Component<IRegisterViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  sending,
              }       = this.props,
              text    = sending ? (<CircularProgress color="primary"/>) : t("global:login"),
              canSend = !(sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={`mt-2 btn-register`}
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  login,
                  validator,
                  existUser,
                  passwordIncorrect,
              } = this.props;

        return (
            <Container className={`${this.prefix}-p-register`}>
                <Card className={"register-card"}>
                    <h1>
                        {t("global:login")}
                    </h1>
                    <form autoComplete="off">
                        <TextField
                            id="form-email"
                            label={t("forms:email")}
                            fullWidth
                            required
                            value={login.getUsername()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                login.setUsername(evt.target.value);
                            }}
                            type="email"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("email", login.getUsername(), "required|email")}}
                        />
                        <TextField
                            id="form-password"
                            label={t("forms:password")}
                            fullWidth
                            required
                            value={login.getPassword()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                login.setPassword(evt.target.value);
                            }}
                            type="password"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("password", login.getPassword(), "required")}}
                        />
                        {
                            !existUser &&
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:user-not-exist")}
                            </div>
                        }
                        {
                            passwordIncorrect &&
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:password-incorrect")}
                            </div>
                        }
                    </form>
                    <div>
                        {this.renderButton()}
                    </div>
                    <hr/>
                    <Link to={renderRoute(PageNames.forgetPasswordPage)} className={"forget-password"}>
                        {t("forms:forget-password")}
                    </Link>
                    <SocialLoginViewModel/>
                </Card>
            </Container>
        );
    }
}

export default withNamespaces("page")(LoginView);