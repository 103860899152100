import * as React                         from "react";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import BackTop                            from "./BackTop";
import { Container, List }                from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { PageNames, renderRoute }         from "../../../Routes/routes";
import { Link }                           from "react-router-dom";
import { Social }                         from "../Social/Social";

const classNames = require("classnames");

interface FooterProps extends WithNamespaces {
    prefix?: string;
    currentPage: string;
}

@InjectPrefix
class Footer extends React.Component<FooterProps, {}> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        const {t, currentPage} = this.props;

        return (
            <footer className={`${this.prefix}-footer ${this.prefix}-primary-color-background`}>
                <BackTop/>
                <Container>
                    <div className="row content">
                        <div className="col-12 col-sm-6">
                            <h5 className="">{t("information")}</h5>
                            <p>
                                {t("content")}
                            </p>
                        </div>
                        <div className="col-12 col-sm-6">
                            <h5 className="">{t("global:pages")}</h5>
                            <List component="nav" aria-label="Secondary" className={`${this.prefix}-footer-nav`}>
                                {[
                                    PageNames.homePage,
                                    PageNames.promotionsPage,
                                    PageNames.tutorialPage,
                                    PageNames.whoWePage,
                                    PageNames.contactPage
                                ].map(item => <li key={item}>
                                    <Link
                                        to={renderRoute(item)}
                                        color="inherit"
                                        className={classNames({active: item === currentPage})}
                                    >
                                        {t(`menu:${item}`)}
                                    </Link>
                                </li>)}
                            </List>
                        </div>
                    </div>
                </Container>
                <div className="copyright">
                    <Container>
                        <p>
                            {t("all-rights-reserved", {year: new Date().getFullYear()})} | <Link
                            to={renderRoute(PageNames.policyPrivacy)}>{t(`menu:${PageNames.policyPrivacy}`)}.</Link>
                        </p>
                        <Social/>
                        <p>
                            {t("global:company")}
                        </p>
                    </Container>
                </div>
            </footer>
        );
    }
}

export default withNamespaces("footer")(Footer);
