import * as React             from "react";
import { Container }          from "typedi";
import {
    Dialog,
    DialogActions,
    IconButton,
}                             from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import ModalService           from "../../../Service/ModalService";
import { ModalStore }         from "../../../Store/ModalStore";
import { ApplicationStore }   from "../../../Store/ApplicationStore";
import InjectPrefix           from "../../../Decorators/InjectPrefix";

interface ModalLayoutProps {
    className?: string;
    buttons?: React.ReactNode[];
    closeButton?: boolean;
    closeModal?: () => void;
    fullScreen?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    prefix?: string;
}

@InjectPrefix
export class ModalLayout extends React.Component<ModalLayoutProps, {}> {
    public get prefix(): string {
        return this.props.prefix as string;
    }

    public get modalStore(): ModalStore {
        return Container.get(ApplicationStore).getStore(ModalStore);
    }

    public closeModal = () => {
        const {closeModal} = this.props;
        Container.get(ModalService).closeModal();

        if (closeModal) closeModal();
    };

    protected closeButtonRender = (): React.ReactNode => {
        return (
            <div className="close-button">
                <IconButton aria-label="close" onClick={this.closeModal} className="icon-close">
                    <CloseIcon/>
                </IconButton>
            </div>
        );
    };

    public render(): React.ReactNode {
        const {
                  children,
                  className = "",
                  buttons,
                  closeButton,
                  maxWidth,
                  fullScreen
              } = this.props;

        return (
            <Dialog
                className={`${this.prefix}-modal-layout ${className}`}
                open={this.modalStore.getModal().getOpenModal()}
                onClose={this.closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
                maxWidth={maxWidth}
            >
                {closeButton ? this.closeButtonRender() : <React.Fragment/>}
                {children}
                {buttons && <DialogActions>
                    {buttons}
                </DialogActions>}
            </Dialog>
        );
    }
}
