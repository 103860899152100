import { Helmet }           from "react-helmet";
import React                from "react";
import { SeoData }          from "../../../Models/Page/SeoData";
import { inject, observer } from "mobx-react";
import { PageStore }        from "../../../Store/PageStore";
import { observable }       from "mobx";
import { Page }             from "../../../Models/Page/Page";

interface ISeoProps {
    seoData: SeoData | undefined;
    PageStore?: PageStore;
}

@inject(PageStore.NAME_STORE)
@observer
class Seo extends React.Component<ISeoProps, {}> {

    @observable
    protected seoData: Page | undefined;

    constructor(props: ISeoProps) {
        super(props);
    }

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.seoData = await this.pageStore.getPageDefault();
    };

    private renderSeo = (seoData: SeoData) => {
        if (!seoData) return (<React.Fragment/>);

        return (
            <Helmet>
                <title>Maderas Camacho - {seoData.getTitle()}</title>
                <meta name="robots" content="index,follow"/>
                <link rel="canonical" href={seoData.getOgUrl()}/>
                <meta name="google" content="nositelinkssearchbox"/>
                <meta name="description" content={seoData.getDescription()}/>
                <meta name="keywords" content={seoData.getKeywords().join(",")}/>

                <meta property="og:title" content={seoData.getOgTitle()}/>
                <meta property="og:type" content={seoData.getOgType()}/>
                <meta property="og:url" content={seoData.getOgUrl()}/>
                <meta property="og:image" content={seoData.getOgImageFullPath()}/>
                <meta property="og:site_name" content={seoData.getOgSiteName()}/>
                <meta property="og:description" content={seoData.getOgDescription()}/>

                <meta name="twitter:card" content={seoData.getTwitterCard()}/>
                <meta name="twitter:site" content={seoData.getTwitterSite()}/>
                <meta name="twitter:title" content={seoData.getTwitterTitle()}/>
                <meta name="twitter:description" content={seoData.getTwitterDescription()}/>
                <meta name="twitter:creator" content={seoData.getTwitterCreator()}/>
                <meta name="twitter:image:src" content={seoData.getTwitterImageFullPath()}/>

                <meta itemProp="author" content={seoData.getGoogleAuthor()}/>
                <meta itemProp="publisher" content={seoData.getGooglePublisher()}/>
                <meta itemProp="name" content={seoData.getGoogleName()}/>
                <meta itemProp="description" content={seoData.getGoogleDescription()}/>
                <meta itemProp="image" content={seoData.getGoogleImageFullPath()}/>
                <script type="application/ld+json">{"Maderas Camacho"}</script>
            </Helmet>
        );
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {seoData} = this.props;

        if (seoData)
            return this.renderSeo(seoData);

        if (this.seoData)
            return this.renderSeo(this.seoData.getSeoData());

        return (<React.Fragment/>);
    }
}

export default Seo;
