import { observable }   from "mobx";
import { serializable } from "serializr";

class ForgetPassword {
    @observable
    @serializable
    private userId: string;

    @observable
    @serializable
    private token: string;

    @observable
    @serializable
    private password: string;

    @serializable
    @observable
    private confirmPassword: string;

    public getUserId(): string {
        return this.userId;
    }

    public setUserId(value: string) {
        this.userId = value;
    }

    public getToken(): string {
        return this.token;
    }

    public setToken(value: string) {
        this.token = value;
    }

    public getPassword(): string {
        return this.password;
    }

    public setPassword(value: string) {
        this.password = value;
    }

    public getConfirmPassword(): string {
        return this.confirmPassword || "";
    }

    public setConfirmPassword(value: string) {
        this.confirmPassword = value;
    }
}

export {
    ForgetPassword
};