import { list, primitive, serializable } from "serializr";
import { observable }                    from "mobx";
import { IImageSelect }                  from "../Gallery/GalleryItem";

// tslint:disable:variable-name
export class SeoData {
    @serializable
    @observable
    private title: string = "";

    @serializable
    @observable
    private description: string = "";

    @serializable(list(primitive()))
    @observable
    private keywords: string[] = [];

    @serializable
    @observable
    private og_title: string = "";

    @serializable
    @observable
    private og_type: string = "";

    @serializable
    @observable
    private og_url: string = "";

    @serializable
    @observable
    private og_image: string = "";

    @serializable
    @observable
    private og_site_name: string = "";

    @serializable
    @observable
    private og_description: string = "";

    @serializable
    @observable
    private twitter_card: string = "";

    @serializable
    @observable
    private twitter_site: string = "";

    @serializable
    @observable
    private twitter_title: string = "";

    @serializable
    @observable
    private twitter_description: string = "";

    @serializable
    @observable
    private twitter_creator: string = "";

    @serializable
    @observable
    private twitter_image: string = "";

    @serializable
    @observable
    private google_author: string = "";

    @serializable
    @observable
    private google_publisher: string = "";

    @serializable
    @observable
    private google_name: string = "";

    @serializable
    @observable
    private google_description: string = "";

    @serializable
    @observable
    private google_image: string = "";

    @observable
    private google_image_select: IImageSelect;

    @observable
    private twitter_image_select: IImageSelect;

    @observable
    private og_image_select: IImageSelect;

    constructor() {
        this.setKeywords([]);
    }

    // tslint:enable:variable-name

    public getTitle(): string {
        return this.title || "";
    }

    public setTitle(value: string) {
        this.title = value;
    }

    public getDescription(): string {
        return this.description || "";
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getKeywords(): string[] {
        return this.keywords;
    }

    public setKeywords(value: string[]) {
        this.keywords = value;
    }

    public getOgTitle(): string {
        return this.og_title || "";
    }

    public setOgTitle(value: string) {
        this.og_title = value;
    }

    public getOgType(): string {
        return this.og_type || "";
    }

    public setOgType(value: string) {
        this.og_type = value;
    }

    public getOgUrl(): string {
        return this.og_url || "";
    }

    public setOgUrl(value: string) {
        this.og_url = value;
    }

    public getOgImage(): string {
        return this.og_image || "";
    }

    public setOgImage(value: string) {
        this.og_image = value;
    }

    public getOgSiteName(): string {
        return this.og_site_name || "";
    }

    public setOgSiteName(value: string) {
        this.og_site_name = value;
    }

    public getOgDescription(): string {
        return this.og_description || "";
    }

    public setOgDescription(value: string) {
        this.og_description = value;
    }

    public getTwitterCard(): string {
        return this.twitter_card || "";
    }

    public setTwitterCard(value: string) {
        this.twitter_card = value;
    }

    public getTwitterSite(): string {
        return this.twitter_site || "";
    }

    public setTwitterSite(value: string) {
        this.twitter_site = value;
    }

    public getTwitterTitle(): string {
        return this.twitter_title || "";
    }

    public setTwitterTitle(value: string) {
        this.twitter_title = value;
    }

    public getTwitterDescription(): string {
        return this.twitter_description || "";
    }

    public setTwitterDescription(value: string) {
        this.twitter_description = value;
    }

    public getTwitterCreator(): string {
        return this.twitter_creator || "";
    }

    public setTwitterCreator(value: string) {
        this.twitter_creator = value;
    }

    public getTwitterImage(): string {
        return this.twitter_image || "";
    }

    public setTwitterImage(value: string) {
        this.twitter_image = value;
    }

    public getGoogleAuthor(): string {
        return this.google_author || "";
    }

    public setGoogleAuthor(value: string) {
        this.google_author = value;
    }

    public getGooglePublisher(): string {
        return this.google_publisher || "";
    }

    public setGooglePublisher(value: string) {
        this.google_publisher = value;
    }

    public getGoogleName(): string {
        return this.google_name || "";
    }

    public setGoogleName(value: string) {
        this.google_name = value;
    }

    public getGoogleDescription(): string {
        return this.google_description || "";
    }

    public setGoogleDescription(value: string) {
        this.google_description = value;
    }

    public getGoogleImage(): string {
        return this.google_image || "";
    }

    public setGoogleImage(value: string) {
        this.google_image = value;
    }

    public getGoogleImageSelect(): IImageSelect {
        return this.google_image_select;
    }

    public setGoogleImageSelect(value: IImageSelect) {
        this.google_image_select = value;
    }

    public getTwitterImageSelect(): IImageSelect {
        return this.twitter_image_select;
    }

    public setTwitterImageSelect(value: IImageSelect) {
        this.twitter_image_select = value;
    }

    public getOgImageSelect(): IImageSelect {
        return this.og_image_select;
    }

    public setOgImageSelect(value: IImageSelect) {
        this.og_image_select = value;
    }

    public getGoogleImageFullPath(): string {
        return this.getGoogleImage() ? `${process.env.REACT_APP_ENDPOINT_BACKEND}uploads/seo/${this.getGoogleImage()}` : "";
    }

    public getTwitterImageFullPath(): string {
        return this.getTwitterImage() ? `${process.env.REACT_APP_ENDPOINT_BACKEND}uploads/seo/${this.getTwitterImage()}` : "";
    }

    public getOgImageFullPath(): string {
        return this.getOgImage() ? `${process.env.REACT_APP_ENDPOINT_BACKEND}uploads/seo/${this.getOgImage()}` : "";
    }
}
