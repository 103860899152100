import { custom, serializable } from "serializr";
import { observable }           from "mobx";
import { date }                 from "../../Serializer/date";

export class Occupation {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private slug: string = "";

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getId(): string {
        return this._id;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }
}