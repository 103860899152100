import "reflect-metadata";
import "./index.scss";
import * as React           from "react";
import * as ReactDOM        from "react-dom";
import { BrowserRouter }    from "react-router-dom";
import * as Sentry          from "@sentry/react";
import { BrowserTracing }   from "@sentry/tracing";
import { I18nextProvider }  from "react-i18next";
import {
    Provider,
    onError
}                           from "mobx-react";
import { Container }        from "typedi";
import Layout               from "./Views/Components/Layout/Layout";
import i18next              from "./Utils/i18n/i18n";
import { ApplicationStore } from "./Store/ApplicationStore";
import ReactGA              from "react-ga";
import DevTools             from "mobx-react-devtools";


const applicationStore = Container.get(ApplicationStore);

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() === "production") {

    Sentry.init({
        dsn         : "https://062cf7d58b9849d192b92eba421207e1@o1423837.ingest.sentry.io/6771592",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    ReactGA.initialize("UA-154585533-1", {});
}

(async () => {
    await applicationStore.initStorage();
    const app = (
        <Provider {...applicationStore.getStores()}>
            <I18nextProvider i18n={i18next} initialLanguage={"es"}>
                <BrowserRouter>
                    <Layout/>
                </BrowserRouter>
                {process.env.REACT_APP_ENV === "dev" ? <DevTools/> : undefined}
            </I18nextProvider>
        </Provider>
    );

    onError(error => {
        console.log("error mobx", error);
    });

    ReactDOM.render(app, document.getElementById("root") as HTMLElement);
})();

ReactDOM.render((<div> Loading... </div>), document.getElementById("root") as HTMLElement);
