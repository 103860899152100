import * as React                 from "react";
import { observer }               from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { Col, Container, Row }    from "reactstrap";
import User                       from "../../../Models/User/User";
import InjectPrefix               from "../../../Decorators/InjectPrefix";
import {
    Card,
    CardContent,
}                                 from "@material-ui/core";
import { Link, withRouter }       from "react-router-dom";
import {
    ListAlt as ListAltIcon,
    Room as RoomIcon,
    AccountBox as AccountBoxIcon,
    Receipt as ReceiptIcon,
}                                 from "@material-ui/icons";
import IBaseProps                 from "../../../ViewModel/Props/IBaseProps";
import { PageNames, renderRoute } from "../../../Routes/routes";
import { Order }                  from "../../../Models/Order/Order";
import EditProfileFormViewModel   from "../../../ViewModel/User/EditProfileFormViewModel";

interface IEditProfileViewProps extends IBaseProps {
    user: User;
    prefix?: string;
}

@InjectPrefix
@observer
class EditProfileView extends React.Component<IEditProfileViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private goTo = (route: string) => {
        this.props.history.push(route);
    };

    public render(): React.ReactNode {
        const {user, t} = this.props;

        return (
            <div className={`${this.prefix}-p-edit-profile`}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h4 className="font-weight-bold">
                                {t("profile.edit-profile")}
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <EditProfileFormViewModel
                                user={user}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(withNamespaces("page")(EditProfileView));