import Modal          from "../Models/Modal/Modal";
import BaseStore      from "./Base/BaseStore";
import { observable } from "mobx";

class ModalStore extends BaseStore {
    public static readonly NAME_STORE: string = "ModalStore";

    @observable
    private modal: Modal;

    init() {
        this.needPersistData = false;
        this.setModal(new Modal());
    }

    public setModal(modal: Modal) {
        this.modal = modal;
    }

    public getModal(): Modal {
        return this.modal;
    }
}

export { ModalStore };
