import {
    WithNamespaces,
    withNamespaces
}                                             from "react-i18next";
import SimpleReactValidator                   from "simple-react-validator";
import * as React                             from "react";
import InjectPrefix                           from "../../../Decorators/InjectPrefix";
import { observer }                           from "mobx-react";
import { Button, Card, Container, TextField } from "@material-ui/core";
import CircularProgress                       from "@material-ui/core/CircularProgress";

interface IForgetPasswordFormViewProps extends WithNamespaces {
    prefix?: string;
    validator: SimpleReactValidator;
    submit: (form: React.Component) => void;
    email: string;
    setEmail: (val: string) => void;
    sending: boolean;
    response: boolean;
    sent: boolean;
}

@InjectPrefix
@observer
class ForgetPasswordFormView extends React.Component<IForgetPasswordFormViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  sending,
                  sent,
              }       = this.props,
              text    = sending ? (<CircularProgress color="primary"/>) : t("global:submit"),
              canSend = !(sending);

        if (sent)
            return (<React.Fragment/>);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={`mt-2 btn-register`}
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  email,
                  setEmail,
                  validator,
                  response,
                  sent
              } = this.props;

        return (
            <Container className={`${this.prefix}-p-forget-password`}>
                <Card className={"forget-password-card"}>
                    <h1 className="h2">
                        {t("global:restore-my-password")}
                    </h1>
                    <form>
                        <TextField
                            id="form-name"
                            label={t("forms:email")}
                            fullWidth
                            required
                            value={email || ""}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(evt.target.value);
                            }}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("email", email, "required|email")}}
                        />
                        {validator.message("email", email, "required|email")}
                    </form>
                    {
                        sent
                        ? <p>{t(`global:forget-password-${response ? "success" : "not-found"}`)}</p>
                        : <React.Fragment/>
                    }
                    <div>
                        {this.renderButton()}
                    </div>
                </Card>
            </Container>
        );
    }
}

export default withNamespaces("page")(ForgetPasswordFormView);