import { observer } from "mobx-react";
import * as React   from "react";
import {
    withNamespaces,
    WithNamespaces
}                   from "react-i18next";
import { Box }                 from "@material-ui/core";
import { TranslationFunction } from "i18next";
import { getContrastingColor } from "../../../Utils/color";
import { Color }               from "../../../Models/Color/Color";

interface IColorSelectedProps extends WithNamespaces {
    pickedColor: Color;
}

@observer
class ColorSelected extends React.Component<IColorSelectedProps, {}> {
    private resolveColorName = (color: Color, t: TranslationFunction): string => {
        return !!color ? color.getName() : t("none");
    }

    public render(): React.ReactNode {
        const {
                  pickedColor,
                  t
              } = this.props;

        return (
            <div className="selected-color">
                <Box component={"span"}>
                    {t("pick-color")}
                </Box>
                <div
                    style={{
                        background: pickedColor ? pickedColor.getRgb() : "transparent",
                        color     : getContrastingColor(pickedColor ? pickedColor.getRgb() : "#fff"),
                    }}
                    className="color"
                >
                    {pickedColor ? pickedColor.getCode() : t("none")}
                </div>
                <Box component={"span"}>
                    {this.resolveColorName(pickedColor, t)}
                </Box>
            </div>

        );
    }
}

export default withNamespaces("color")(ColorSelected);