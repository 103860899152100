import { observer }        from "mobx-react";
import * as React          from "react";
import { Color }           from "../../../Models/Color/Color";
import { ColorPalette }    from "../../../Models/Color/ColorPalette";
import ColorSelected       from "./ColorSelected";
import InjectPrefix        from "../../../Decorators/InjectPrefix";
import { arrayIntoChunks } from "../../../Utils/array";
import SwatchesColor       from "./SwatchesColor";

interface IColorPickerBarProps {
    colorPalette: ColorPalette;
    pickedColor?: Color;
    onChange: (color: Color) => void;
    className?: string;
    prefix?: string;
}

@InjectPrefix
@observer
class ColorPickerBar extends React.Component<IColorPickerBarProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  colorPalette,
                  pickedColor = Color.NONE,
                  onChange,
                  className,
              } = this.props;

        if (!colorPalette) return (<React.Fragment/>);

        const colors: Color[][] = arrayIntoChunks<Color>(colorPalette.getColors(), 7);

        return (
            <div className={`${this.prefix}-color-picker color-picker-bar ${className || ""}`}>
                <ColorSelected
                    pickedColor={pickedColor}
                />
                <div className="color-picker swatches body row">
                    {
                        colors.map((group: Color[], key: number) => (
                            <div
                                className="group col-6 col-sm-3 col-md-2"
                                key={key}
                            >
                                {group.map((color) => (
                                    <SwatchesColor
                                        key={color.getCode()}
                                        color={color}
                                        active={color.getRgb() === pickedColor.getRgb()}
                                        onClick={onChange}
                                    />
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default (ColorPickerBar);