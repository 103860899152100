import * as React             from "react";
import Footer                 from "../../Views/Components/Footer/Footer";
import { inject, observer }   from "mobx-react";
import { ConfigurationStore } from "../../Store/ConfigurationStore";

@inject(ConfigurationStore.NAME_STORE)
@observer
class FooterViewModel extends React.Component<{
    ConfigurationStore?: ConfigurationStore;
}, {}> {

    get show(): boolean {
        return this.configurationStore.getConfiguration().getShowFooter();
    }

    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    /**
     * render
     */
    public render(): React.ReactNode {

        return (
            this.show ? <Footer
                currentPage={this.configurationStore.getCurrentPage()}
            /> : <></>
        );
    }
}

export default FooterViewModel;
