import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Col, Row }                       from "reactstrap";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import User                               from "../../../Models/User/User";
import { Direction }                      from "../../../Models/User/Direction";
import { Card }                           from "@material-ui/core";
import { Add as AddIcon }                 from "@material-ui/icons";
import { Order }                          from "../../../Models/Order/Order";
import FormBillElectronicViewModel        from "../../../ViewModel/FormBillElectronic/FormBillElectronicViewModel";
import CardBillElectronicChooseViewModel  from "../../../ViewModel/Card/CardBillElectronicChooseViewModel";
import { BillElectronic }                 from "../../../Models/User/BillElectronic";

interface IChooseDirectionViewProps extends WithNamespaces {
    user: User;
    newBillElectronic: boolean;
    setNewBillElectronic: (val: boolean) => void;
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
class ChooseBillElectronicView extends React.Component<IChooseDirectionViewProps, {}> {
    protected get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderDirection = (): React.ReactNode[] => {
        const {
                  user
              } = this.props;

        return user.getBillElectronics().map((billElectronic: BillElectronic) => {
            return (
                <Col xs={12} sm={6} md={4} key={billElectronic.getUID()}>
                    <CardBillElectronicChooseViewModel billElectronic={billElectronic}/>
                </Col>
            );
        });
    };

    protected addDirection = () => {
        const {setNewBillElectronic} = this.props;
        setNewBillElectronic(true);

        setTimeout(() => {
            const item     = `${this.prefix}-form-contact-us`,
                  itemNode = document.getElementsByClassName(item)[0] as HTMLElement;

            if (itemNode) {
                scrollTo({
                             behavior: "smooth",
                             left    : 0,
                             top     : itemNode.offsetTop - 130,
                         });
            }
        }, 100);
    };

    public render(): React.ReactNode {
        const {t, newBillElectronic} = this.props;

        return (
            <div>
                <h2 className="title">
                    {t("order.select-bill-electronic")}
                </h2>
                <Row className="container-cards">
                    <Col xs={12} sm={6} md={4}>
                        <Card variant="outlined" className="card add-new">
                            <a href="javascript:void(0);" onClick={this.addDirection}>
                                <AddIcon className="icon"/>
                                <span>Agregar datos para la factura electronica</span>
                            </a>
                        </Card>
                    </Col>
                    {this.renderDirection()}
                </Row>
                {
                    newBillElectronic
                    ? (
                        <div className="form">
                            <FormBillElectronicViewModel/>
                        </div>
                    )
                    : <React.Fragment/>
                }
            </div>
        );
    }
}

export default withNamespaces("page")(ChooseBillElectronicView);