import { serializable } from "serializr";
import { observable }   from "mobx";

export class CreditCard {
    @serializable
    @observable
    private cvc: string = "";

    @serializable
    @observable
    private expiry: string = "";

    @serializable
    @observable
    private focused: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private cardNumber: string = "";

    public getCvc = (): string => {
        return this.cvc || "";
    };

    public setCvc = (value: string) => {
        this.cvc = value;
    };

    public getExpiry = (): string => {
        return this.expiry || "";
    };

    public setExpiry = (value: string) => {
        this.expiry = value;
    };

    public getFocused = (): string => {
        return this.focused || "";
    };

    public setFocused = (value: string) => {
        this.focused = value;
    };

    public getName = (): string => {
        return this.name || "";
    };

    public setName = (value: string) => {
        this.name = value;
    };

    public getCardNumber = (): string => {
        return this.cardNumber || "";
    };

    public setCardNumber = (value: string) => {
        this.cardNumber = value;
    };
}
