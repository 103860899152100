import { ApplicationStore, ObjectType } from "../ApplicationStore";
import moment                           from "moment";

export default abstract class BaseStore {
    public static readonly NAME_STORE: string;

    private static readonly HOURS_CLEAR_CACHE: number = 4;  //  Hours to clear cache

    protected needPersistData: boolean;
    private _applicationStore: ApplicationStore;
    protected createdStoreAt: Date;

    get applicationStore(): ApplicationStore {
        return this._applicationStore;
    }

    set applicationStore(value: ApplicationStore) {
        this._applicationStore = value;
    }

    protected init() {}

    public validateDateStoreToClear() {
        if (this.createdStoreAt && (moment().diff(this.createdStoreAt, "hours") >= BaseStore.HOURS_CLEAR_CACHE)) {
            this.initDate();
            this.resetStore();
        }
    }


    /**
     * Update the store creation date
     * using current date
     *
     * @private
     * @memberof BaseStore
     */
    private initDate() {
        this.createdStoreAt = moment().toDate();
    }

    public resetStore() {}

    public constructor(applicationStore: ApplicationStore) {
        this.applicationStore = applicationStore;
        this.init();

        if (typeof this.needPersistData === "undefined")
            throw `The storage: ${this.constructor.name} need set property this.needPersistData to false / true`;
    }

    public getNeedPersistData(): boolean {
        return this.needPersistData;
    }

    public getStore<T>(store: ObjectType<T>): T {
        return this.applicationStore.getStore(store);
    }
}