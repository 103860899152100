import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import Container                          from "@material-ui/core/Container";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import CircularProgress                   from "@material-ui/core/CircularProgress";
import { Col, Row }                       from "reactstrap";
import CardTutorial                       from "../Components/Card/CardTutorial";
import Box                                from "@material-ui/core/Box";
import Button                             from "@material-ui/core/Button";
import { Tutorial }                       from "../../Models/Tutorial/Tutorial";
import { Gallery }                        from "../../Models/Gallery/Gallery";
import Carousel                           from "../Components/Carousel/Carousel";
import { Page }                           from "../../Models/Page/Page";
import Seo                                from "../Components/Header/Seo";

interface TutorialViewProps extends WithNamespaces {
    prefix?: string;
    tutorials: Tutorial[];
    showMore: () => void;
    loading: boolean;
    currentPage: number;
    pages: number;
    gallery: Gallery;
    page: Page | undefined;
}

@InjectPrefix
class TutorialView extends React.Component<TutorialViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderTutorials = () => {
        const {tutorials} = this.props;

        return tutorials.map((tutorial: any) => {
            return (
                <Col xs={12} sm={4} lg={4} key={tutorial._id}>
                    <CardTutorial
                        tutorial={tutorial}
                    />
                </Col>
            );
        });
    };

    private renderLoading = (): React.ReactNode => {
        const {
                  t,
                  showMore,
                  loading,
                  currentPage,
                  pages
              } = this.props;

        if (loading)
            return (<CircularProgress color="secondary"/>);

        if (currentPage > pages || (currentPage === 1 && pages === 1))
            return (<React.Fragment/>);

        return (
            <Button variant="outlined" color="secondary" onClick={showMore}>
                {t("global:show-more")}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {t, gallery, page} = this.props;

        return (
            <>
                <Seo seoData={page ? page.getSeoData() : undefined}/>
                <Carousel
                    gallery={gallery}
                />
                <Container className={`${this.prefix}-p-tutorial`}>
                    <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("tutorials")}
                    </h1>
                    <hr/>
                    <Row className={`${this.prefix}-container-cards`}>
                        {this.renderTutorials()}
                    </Row>
                    <Box justifyContent="center" className="d-flex mb-4">
                        {this.renderLoading()}
                    </Box>
                </Container>
            </>
        );
    }
}

export default withNamespaces("page")(TutorialView);
