import { observer }                       from "mobx-react";
import * as React                         from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
}                                         from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { ISort, PrincipalSearch }         from "../../../Models/Product/PrincipalSearch";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";

interface IOrderProductsViewProps extends WithNamespaces {
    principalSearch: PrincipalSearch;
    search: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class OrderProductsView extends React.Component<IOrderProductsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, principalSearch, search} = this.props;

        return (
            <FormControl fullWidth variant="outlined" className={`${this.prefix}-c-order-products-by`}>
                <InputLabel htmlFor="order" variant="outlined">
                    {t("sort-by")}
                </InputLabel>
                <Select
                    variant="outlined"
                    required
                    fullWidth
                    value={principalSearch.getSort()}
                    onChange={({target: {value}}: React.ChangeEvent<{ name?: string; value: ISort }>) => {
                        principalSearch.setSortDirection(value === "createdAt" ? -1 : 1);
                        principalSearch.setSort(value);
                        search();
                    }}
                    inputProps={{
                        id  : "order",
                        name: "order",
                    }}
                >
                    {
                        [
                            "order",
                            "name",
                            "createdAt"
                        ].map((item: string) => {
                            return (
                                <MenuItem value={item} key={item}>
                                    {t(`order-products.${item}`)}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
        );
    }
}

export default withNamespaces("global")(OrderProductsView);