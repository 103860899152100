import BaseStore        from "./Base/BaseStore";
import { Gallery }      from "../Models/Gallery/Gallery";
import { galleryPages } from "../config/GalleryPages";
import AjaxService      from "../Service/AjaxService";
import Container        from "typedi";
import { deserialize }  from "serializr";
import { Promotion }    from "../Models/Promotion/Promotion";
import { AboutUs }      from "../Models/AboutUs/AboutUs";
import { SeoData }      from "../Models/Page/SeoData";
import { Page }         from "../Models/Page/Page";
import { pages }        from "../config/Pages";
import { observable }   from "mobx";
import { Product }      from "../Models/Product/Product";

export interface IHomePageData {
    gallery: Gallery;
    galleryBrands: Gallery;
    promotions: Promotion[];
    products: Product[];
    page: Page;
}

export interface IAboutUsPageData {
    aboutUs: AboutUs;
    gallery: Gallery;
    galleryCarousel: Gallery;
}

export class PageStore extends BaseStore {
    public static readonly NAME_STORE: string = "PageStore";

    @observable
    private pageDefault: Page;

    @observable
    private pages: Map<string, Page> = new Map<string, Page>();

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    init() {
        this.needPersistData = false;
    }

    public async getPage(name: pages): Promise<Page | undefined> {
        if (this.pages.has(name)) return this.pages.get(name);
        const page = await this.getByName(name);


        if (page) {
            this.pages.set(name, page);

            return this.pages.get(name);
        }

        return undefined;
    }

    public async getPageDefault(): Promise<Page | undefined> {
        if (this.pageDefault) return this.pageDefault;

        this.pageDefault = await this.getByName(pages.default) as Page;

        return this.pageDefault;
    }

    public setPageDefault(value: Page): this {
        this.pageDefault = value;

        return this;
    }

    public async getHomePage(): Promise<IHomePageData> {
        const {data: {data}} = await this.getAjaxService().getHomePage(),
              gallery        = deserialize(Gallery, data.gallery),
              galleryBrands  = deserialize(Gallery, data.galleryBrands),
              page           = (data.seo ? deserialize(Page, data.seo) : undefined) as Page,
              promotions     = data.promotions.map((promotion: any) => deserialize(Promotion, promotion)),
              products       = data.products.map((promotion: any) => deserialize(Product, promotion));

        return {
            gallery,
            galleryBrands,
            page,
            products,
            promotions,
        };
    }

    public async getCurrentAboutUs(): Promise<IAboutUsPageData> {
        const {data: {data}}  = await this.getAjaxService().getCurrentAboutUs(),
              gallery         = deserialize(Gallery, data.gallery),
              galleryCarousel = deserialize(Gallery, data.galleryCarousel),
              aboutUs         = deserialize(AboutUs, data.aboutUs);

        return {
            aboutUs,
            gallery,
            galleryCarousel
        };
    }

    public async getByName(name: string): Promise<Page | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPageName(name);

        if (!dataResponse) return;

        return deserialize(Page, dataResponse);
    }
}
