import { observer }                 from "mobx-react";
import * as React                   from "react";
import { Container }                from "reactstrap";
import { withNamespaces }           from "react-i18next";
import { withRouter }               from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import InjectPrefix                 from "../../../Decorators/InjectPrefix";
import { Order }                    from "../../../Models/Order/Order";
import TableProducts                from "../../Components/Table/TableProducts";
import { ProductItemCar }           from "../../../Models/ProductItemCar/ProductItemCar";
import IBaseProps                   from "../../../ViewModel/Props/IBaseProps";
import DetailsOrder                 from "../../Components/Order/DetailsOrder";
import { CalculateShipping }        from "../../../Models/CalculateShipping/CalculateShipping";
import TableProductsFooter          from "../../Components/Table/TableProductsFooter";
import CreditCardViewModel          from "../../../ViewModel/CreditCard/CreditCardViewModel";
import { MethodsPay }               from "../../../config/MethodsPay";

interface IReviewOrderViewProps extends IBaseProps {
    order: Order;
    productItemCars: ProductItemCar[];
    generateOrder: () => void;
    send: boolean;
    sending: boolean;
    error: string;
    calculateShipping: CalculateShipping;
    prefix?: string;
}

@InjectPrefix
@observer
export class ReviewOrderView extends React.Component<IReviewOrderViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderCtas = (): React.ReactNode => {
        const {
                  t,
                  history,
                  generateOrder,
                  send,
                  sending,
              } = this.props;

        let text: string | React.ReactNode = t("car:generate-order");

        if (sending) text = (<CircularProgress color="primary"/>);
        if (send) text = t("car:generated-order");

        const canSend = !(send || sending),
              button  = (
                  <Button
                      className="btn-primary"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                          if (canSend)
                              generateOrder();
                      }}
                      disabled={!canSend}
                  >
                      {text}
                  </Button>
              );

        return (
            <div className="cta-container">
                <Button
                    className="btn-secondary"
                    onClick={() => {
                        history.goBack();
                    }}
                    variant="contained"
                    color="secondary"
                    size="large"
                >
                    Volver
                </Button>
                {button}
            </div>
        );
    };

    private renderTotal = (): React.ReactNode => {
        const {
                  order,
                  calculateShipping
              }        = this.props,
              shipping = order.getMethodTransport() && order.getMethodTransport().getPickUp() ? 0 : calculateShipping.getPrice();

        return (
            <TableProductsFooter
                hideTransport={order.getMethodTransport().getPickUp()}
                shipping={shipping}
                subTotal={order.returnSubTotal()}
                total={order.returnSubTotal() + shipping}
            />
        );
    };

    public render(): React.ReactNode {
        const {
                  order,
                  t,
                  productItemCars,
                  error
              } = this.props;

        return (
            <div className={`${this.prefix}-p-review-order`}>
                <Container>
                    <h2 className="title">
                        {t("order.review-order")}
                    </h2>
                    <DetailsOrder order={order}/>
                    <TableProducts
                        productItemCars={productItemCars}
                        hideTotal={true}
                        notEdit
                        children={this.renderTotal()}
                    />
                    {order.getMethodPay() === MethodsPay.CARD
                     ? (
                         <section className="mt-3 mb-3">
                             <CreditCardViewModel creditCard={order.getCreditCard()} error={error}/>
                         </section>
                     ) : <React.Fragment/>}
                    {this.renderCtas()}
                </Container>
            </div>
        );
    }
}

export default withRouter(withNamespaces("page")(ReviewOrderView));
