import * as React               from "react";
import { inject, observer }     from "mobx-react";
import { UserStore }            from "../../Store/UserStore";
import { observable }           from "mobx";
import InjectPrefix             from "../../Decorators/InjectPrefix";
import { CarStore }             from "../../Store/CarStore";
import { BillElectronic }       from "../../Models/User/BillElectronic";
import CardChooseBillElectronic from "../../Views/Components/Card/CardChooseBillElectronic";

interface ICardDirectionChooseViewModelProps {
    billElectronic: BillElectronic;
    UserStore?: UserStore;
    CarStore?: CarStore;
    prefix?: string;
}

@inject(UserStore.NAME_STORE, CarStore.NAME_STORE)
@InjectPrefix
@observer
class CardBillElectronicChooseViewModel extends React.Component<ICardDirectionChooseViewModelProps, {}> {
    @observable
    private sending: boolean = false;

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected setBillElectronicToOrder = (billElectronic: BillElectronic) => {
        this.carStore.getOrder().setBillElectronic(billElectronic);
        this.userStore.setNewBillElectronic(false);
    };

    public render(): React.ReactNode {
        const {billElectronic}  = this.props,
              order        = this.carStore.getOrder(),
              billElectronicUID = order.getBillElectronic() ? order.getBillElectronic().getUID() : "";

        return (
            <CardChooseBillElectronic
                billElectronic={billElectronic}
                billElectronicUID={billElectronicUID}
                setBillElectronicToOrder={this.setBillElectronicToOrder}
            />
        );
    }
}

export default CardBillElectronicChooseViewModel;
