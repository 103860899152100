import * as React     from "react";
import GoogleMapReact from "google-map-react";
import { social }     from "../../../config/social";

interface LocationMapViewProps {
    keyGoogleMaps: string;
}

// tslint:disable-next-line:variable-name
const Pointer = ({text}: { text: string, lat: number, lng: number }) => <div>{text}</div>;

class LocationMapView extends React.Component<LocationMapViewProps, {}> {
    public render(): React.ReactNode {
        const {keyGoogleMaps} = this.props,
              center          = {lat: social.lat, lng: social.lng},
              zoom            = 18;

        return (
            <GoogleMapReact
                bootstrapURLKeys={{key: keyGoogleMaps}}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <Pointer
                    lat={59.955413}
                    lng={30.337844}
                    text="Maderas Camacho S.A"
                />
            </GoogleMapReact>
        );
    }
}

export default LocationMapView;
