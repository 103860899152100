import { observer }                           from "mobx-react";
import * as React                             from "react";
import { Container, Card, TextField, Button } from "@material-ui/core";
import InjectPrefix                           from "../../../Decorators/InjectPrefix";
import { withNamespaces, WithNamespaces }     from "react-i18next";
import { Register }                           from "../../../Models/Register/Register";
import SimpleReactValidator                   from "simple-react-validator";
import CircularProgress                       from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon                       from "@material-ui/icons/ErrorOutline";
import { ERROR_CLASS }                        from "../../../Store/FormStore";
import SocialLoginViewModel                   from "../../../ViewModel/SocialLogin/SocialLoginViewModel";

interface IRegisterViewProps extends WithNamespaces {
    prefix?: string;
    register: Register;
    validator: SimpleReactValidator;
    send: boolean;
    existUser: boolean;
    sending: boolean;
    submit: (form: React.Component) => void;
}

@InjectPrefix
@observer
class RegisterView extends React.Component<IRegisterViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  sending,
              }       = this.props,
              text    = sending ? (<CircularProgress color="primary"/>) : t("global:login"),
              canSend = !(sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={`mt-2 btn-register`}
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  register,
                  validator,
                  existUser
              } = this.props;

        validator.message("password-confirm-repeat", register.getConfirmPassword(), `in:${register.getPassword()}`);

        return (
            <Container className={`${this.prefix}-p-register`}>
                <Card className={"register-card"}>
                    <h1>
                        {t("global:register")}
                    </h1>
                    <form>
                        <TextField
                            id="form-name"
                            label={t("forms:name")}
                            fullWidth
                            required
                            value={register.getFirstName()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                register.setFirstName(evt.target.value);
                            }}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("firstName", register.getFirstName(), "required")}}
                        />
                        <TextField
                            id="form-lastName"
                            label={t("forms:lastName")}
                            fullWidth
                            required
                            value={register.getLastName()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                register.setLastName(evt.target.value);
                            }}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("lastName", register.getLastName(), "required")}}
                        />
                        <TextField
                            id="form-email"
                            label={t("forms:email")}
                            fullWidth
                            required
                            value={register.getEmail()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                register.setEmail(evt.target.value);
                            }}
                            type="email"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("email", register.getEmail(), "required|email")}}
                        />
                        <TextField
                            id="form-password"
                            label={t("forms:password")}
                            fullWidth
                            required
                            value={register.getPassword()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                register.setPassword(evt.target.value);
                            }}
                            type="password"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("password", register.getPassword(), "required|min:8|field-password-no-regex")}}
                        />
                        {validator.message("password", register.getPassword(), "required|min:8|field-password-no-regex")}
                        <TextField
                            id="form-password-confirm"
                            label={t("forms:password-confirm")}
                            fullWidth
                            required
                            value={register.getConfirmPassword()}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                register.setConfirmPassword(evt.target.value);
                            }}
                            type="password"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{error: !!validator.message("password-confirm", register.getConfirmPassword(), "required")}}
                        />
                        {
                            !validator.fieldValid("password-confirm-repeat") &&
                            (
                                <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                    <ErrorOutlineIcon/> {t("validator:field-password-confirm-repeat")}
                                </div>
                            )
                        }
                        {
                            existUser &&
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:user-exist")}
                            </div>
                        }
                    </form>
                    <div>
                        {this.renderButton()}
                    </div>
                    <hr/>
                    <SocialLoginViewModel/>
                </Card>
            </Container>
        );
    }
}

export default withNamespaces("page")(RegisterView);