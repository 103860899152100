export const configuration = {
    errorsContent: [
        {
            backgroundImage: "/images/pages/error/500.jpg",
            code           : 500,
            link           : "/",
            message        : "Error 500. We are experiencing an internal server problem, please try again later.",
            title          : "Internal Server Error"
        },
        {
            backgroundImage: "/images/pages/error/403.jpg",
            code           : 403,
            link           : "/",
            message        : "Error 403. You don’t have permission to acces this page.",
            title          : "Forbidden"
        },
        {
            backgroundImage: "/images/pages/error/404.jpg",
            code           : 404,
            link           : "/",
            message        : "Error 404. The requested page does not exist. Please Go Home or contact <a href=\"mailto=mail@email.com\">mail@email.com</a> for more information.",
            title          : "PAGE NOT FOUND"
        },
    ],
    pageImages   : [
        {
            backgroundImage: "/images/pages/login/background-login.jpg",
            page           : "login",
        }
    ],
    prefix       : "maderas-camacho"
};
