import { observer }            from "mobx-react";
import * as React              from "react";
import { Check }               from "@material-ui/icons";
import { Color }               from "../../../Models/Color/Color";
import { getContrastingColor } from "../../../Utils/color";

interface ISwatchesColorProps {
    color: Color;
    active: boolean;
    onClick: (color: Color) => void;
}

@observer
class SwatchesColor extends React.Component<ISwatchesColorProps, {}> {
    public render(): React.ReactNode {
        const {
                  color,
                  active,
                  onClick,
              } = this.props;

        return (
            <label
                className="swatch"
                style={{background: color.getRgb(), color: getContrastingColor(color.getRgb())}}
                onClick={() => onClick(color)}
                title={color.getName()}
                htmlFor={color.getCode()}
            >
                {color.getCode()}
                {active && <Check className="icon"/>}
                <input type="radio" value={color.getCode()} name="swatch" id={color.getCode()}/>
            </label>
        );
    }
}

export default (SwatchesColor);