import * as React             from "react";
import { inject, observer }   from "mobx-react";
import { observable }         from "mobx";
import Header                 from "../../Views/Components/Header/Header";
import { ConfigurationStore } from "../../Store/ConfigurationStore";
import { UserStore }          from "../../Store/UserStore";
import { Container }          from "typedi";
import AuthService            from "../../Service/AuthService";
import { CarStore }           from "../../Store/CarStore";

@inject(
    ConfigurationStore.NAME_STORE,
    UserStore.NAME_STORE,
    CarStore.NAME_STORE
)
@observer
class HeaderViewModel extends React.Component<{
    ConfigurationStore?: ConfigurationStore,
    UserStore?: UserStore,
    CarStore?: CarStore,
}, {}> {
    @observable
    private openMenuLeft: boolean;

    @observable
    private node: Element;

    @observable
    private nodeMenuUser: Element;

    protected getNodeMenuUser = (): Element => {
        return this.nodeMenuUser;
    };

    protected setNodeMenuUser = (value: Element | undefined) => {
        this.nodeMenuUser = value as Element;
    };

    public getOpenMenuLeft = (): boolean => {
        return this.openMenuLeft;
    };

    public setOpenMenuLeft = (value: boolean) => {
        this.openMenuLeft = value;
    };

    public setNode = (value: Element) => {
        this.node = value;
    };

    protected get show(): boolean {
        return this.configurationStore.getConfiguration().getShowHeader();
    }

    protected get authService(): AuthService {
        return Container.get(AuthService);
    }

    protected get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            this.show ? <Header
                openMenuLeft={this.getOpenMenuLeft()}
                setOpenMenuLeft={this.setOpenMenuLeft}
                setNode={this.setNode}
                node={this.node}
                currentPage={this.configurationStore.getCurrentPage()}
                user={this.userStore.getUser()}
                logout={this.authService.logout}
                quantityProductsInCar={this.carStore.getProductsInCar()}
                nodeMenuUser={this.getNodeMenuUser()}
                setNodeMenuUser={this.setNodeMenuUser}
            /> : <></>
        );
    }
}

export default HeaderViewModel;
