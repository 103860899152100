const locations = {
    "Alajuela"  : {
        cantones: {
            1 : "Central",
            2 : "San Ramón",
            3 : "Grecia",
            4 : "San Mateo",
            5 : "Atenas",
            6 : "Naranjo",
            7 : "Palmares",
            8 : "Poás",
            9 : "Orotina",
            10: "San Carlos",
            11: "Zarcero",
            12: "Sarchí",
            13: "Upala",
            14: "Los Chiles",
            15: "Guatuso",
            16: "Río Cuarto"
        }
    },
    "Cartago"   : {
        cantones: {
            1: "Central",
            2: "Paraíso",
            3: "La Unión",
            4: "Jiménez",
            5: "Turrialba",
            6: "Alvarado",
            7: "Oreamuno",
            8: "El Guarco"
        }
    },
    "Guanacaste": {
        cantones: {
            1 : "Liberia",
            2 : "Nicoya",
            3 : "Santa Cruz",
            4 : "Bagaces",
            5 : "Carrillo",
            6 : "Cañas",
            7 : "Abangares",
            8 : "Tilarán",
            9 : "Nandayure",
            10: "La Cruz",
            11: "Hojancha"
        }
    },
    "Heredia"   : {
        cantones: {
            1 : "Central",
            2 : "Barva",
            3 : "Santo Domingo",
            4 : "Santa Barbara",
            5 : "San Rafael",
            6 : "San Isidro",
            7 : "Belén",
            8 : "Flores",
            9 : "San Pablo",
            10: "Sarapiquí"
        }
    },
    "Limón"     : {
        cantones: {
            1: "Central",
            2: "Pococí",
            3: "Siquirres",
            4: "Talamanca",
            5: "Matina",
            6: "Guácimo"
        }
    },
    "Puntarenas": {
        cantones: {
            1 : "Central",
            2 : "Esparza",
            3 : "Buenos Aires",
            4 : "Montes De Oro",
            5 : "Osa",
            6 : "Quepos",
            7 : "Golfito",
            8 : "Coto Brus",
            9 : "Parrita",
            10: "Corredores",
            11: "Garabito"
        }
    },
    "San José"  : {
        cantones: {
            1 : "Central",
            2 : "Escazú",
            3 : "Desamparados",
            4 : "Puriscal",
            5 : "Tarrazú",
            6 : "Aserrí",
            7 : "Mora",
            8 : "Goicoechea",
            9 : "Santa Ana",
            10: "Alajuelita",
            11: "Vázquez De Coronado",
            12: "Acosta",
            13: "Tibás",
            14: "Moravia",
            15: "Montes De Oca",
            16: "Turrubares",
            17: "Dota",
            18: "Curridabat",
            19: "Pérez Zeledón",
            20: "León Cortés Castro"
        }
    },
};

export { locations };