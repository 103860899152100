import * as React                         from "react";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import User                               from "../../../Models/User/User";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Col, Container, Row }            from "reactstrap";
import { Card }                           from "@material-ui/core";
import { Add as AddIcon }                 from "@material-ui/icons";
import FormBillElectronicViewModel        from "../../../ViewModel/FormBillElectronic/FormBillElectronicViewModel";
import CardDirectionViewModel             from "../../../ViewModel/Card/CardDirectionViewModel";
import { BillElectronic }                 from "../../../Models/User/BillElectronic";
import CardBillElectronicViewModel        from "../../../ViewModel/Card/CardBillElectronicViewModel";

interface IBillElectronicViewProps extends WithNamespaces {
    user: User;
    prefix?: string;
}

@InjectPrefix
@observer
class BillElectronicView extends React.Component<IBillElectronicViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBillElectronics = () => {
        const {user} = this.props;
        return user.getBillElectronics().map((billElectronic: BillElectronic) => {
            return (
                <Col xs={12} sm={6} md={4} key={billElectronic.getUID()}>
                    <CardBillElectronicViewModel billElectronic={billElectronic}/>
                </Col>
            );
        });
    };

    protected addBillElectronic = () => {
        const item     = `${this.prefix}-form-contact-us`,
              itemNode = document.getElementsByClassName(item)[0] as HTMLElement;

        if (itemNode) {
            scrollTo({
                         behavior: "smooth",
                         left    : 0,
                         top     : itemNode.offsetTop - 130,
                     });
        }
    };

    public render(): React.ReactNode {
        return (
            <div className={`${this.prefix}-p-my-bill-electronic`}>
                <Container>
                    <h1 className="text-center w-100 mt-5 mb-5 d-block">Datos para la factura electronica</h1>

                    <Row className="container-cards">
                        <Col xs={12} sm={6} md={4}>
                            <Card variant="outlined" className="card add-new">
                                <a href="javascript:void(0);" onClick={this.addBillElectronic}>
                                    <AddIcon className="icon"/>
                                    <span>Agregar un nuevo registro</span>
                                </a>
                            </Card>
                        </Col>
                        {this.renderBillElectronics()}
                    </Row>
                    <div className="form">
                        <FormBillElectronicViewModel/>
                    </div>
                </Container>
            </div>
        );
    }
}


export default withNamespaces("directions")(BillElectronicView);