import {
    inject,
    observer
}                            from "mobx-react";
import * as React            from "react";
import { CarStore }          from "../../Store/CarStore";
import { UserStore }         from "../../Store/UserStore";
import User                  from "../../Models/User/User";
import ChooseMethodToPayView from "../../Views/Pages/Car/ChooseMethodToPayView";
import { MethodsPay }        from "../../config/MethodsPay";

interface IChooseMethodToPayViewModelProps {
    purgeValidator?: () => void;
    CarStore?: CarStore;
    UserStore?: UserStore;
    prefix?: string;
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class ChooseMethodToPayViewModel extends React.Component<IChooseMethodToPayViewModelProps, {}> {
    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected onChangeMethodToPay = (val: MethodsPay) => {
        this.carStore.getOrder().setMethodPay(val);
    };

    public render(): React.ReactNode {
        const {purgeValidator} = this.props;

        return (
            <ChooseMethodToPayView
                methodsToPay={Object.values(MethodsPay)}
                order={this.carStore.getOrder()}
                user={this.userStore.getUser() as User}
                onChangeMethodToPay={this.onChangeMethodToPay}
                purgeValidator={purgeValidator}
            />
        );
    }
}

export default ChooseMethodToPayViewModel;