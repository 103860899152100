export const body = document.getElementsByTagName("body")[0];

export const scrollToElement = (element: HTMLElement, duration: number, distance: number = 50) => {
    if (!window) return;

    const startingY = window.pageYOffset,
          elementY  = (element) ? startingY + element.getBoundingClientRect().top : 0,
          targetY   = document.body.scrollHeight - elementY < window.innerHeight ? body.scrollHeight - window.innerHeight : elementY;

    const diffY  = targetY - startingY - distance,
          easing = (t: any) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    let start: number;

    if (!diffY) return;

    const step = (timestamp: number) => {
        if (!start) start = timestamp;

        const time = timestamp - start;

        let percent = Math.min(time / duration, 1);

        percent = easing(percent);

        window.scrollTo(0, startingY + diffY * percent);

        if (time < duration) window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);
};

export const disableShopCar = (): boolean => {
    return process.env.REACT_APP_DISABLE_SHOP_CAR === "true";
};
