import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import * as React                         from "react";
import { Col, Row }                       from "reactstrap";
import CardPromotion                      from "../Card/CardPromotion";
import moment                             from "moment";
import formatDate                         from "../../../config/formatDate";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Promotion }                      from "../../../Models/Promotion/Promotion";


interface ILastPromotionsProps extends WithNamespaces {
    prefix?: string;
    promotions: Promotion[] | undefined;
}

@InjectPrefix

class LastPromotions extends React.Component<ILastPromotionsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, promotions} = this.props;

        return (
            <>
                <div className="w-100 text-center mb-4">
                    <h2 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("last-promotions")}
                    </h2>
                </div>
                <Row className={`${this.prefix}-container-cards`}>
                    {promotions ? promotions.map((promotion: Promotion, key: number) => {
                                    return (
                                        <Col xs={12} sm={4} lg={3} key={key}>
                                            <CardPromotion
                                                promotion={promotion}
                                            />
                                        </Col>
                                    );
                                })
                                : <React.Fragment/>}
                </Row>
            </>
        );
    }
}

export default withNamespaces("page")(LastPromotions);
