import { serializable } from "serializr";
import { observable }   from "mobx";
import User             from "./User";

export default class ResetPassword {
    @serializable
    @observable
    private userId: string;

    @serializable
    @observable
    private firstName: string;

    @serializable
    @observable
    private lastName: string;

    @serializable
    @observable
    private password: string;

    @serializable
    @observable
    private confirmPassword: string;

    public getUserId(): string {
        return this.userId;
    }

    public setUserId(value: string) {
        this.userId = value;
    }

    public getFirstName(): string {
        return this.firstName;
    }

    public setFirstName(value: string) {
        this.firstName = value;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getPassword(): string {
        return this.password || "";
    }

    public setPassword(value: string) {
        this.password = value;
    }

    public getConfirmPassword(): string {
        return this.confirmPassword || "";
    }

    public setConfirmPassword(value: string) {
        this.confirmPassword = value;
    }

    public fill(user: User): this {
        this.setFirstName(user.getFirstName());
        this.setLastName(user.getLastName());
        this.setUserId(user.getId());

        return this;
    }
}