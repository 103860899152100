import { inject, observer }   from "mobx-react";
import * as React             from "react";
import MapSelectDirectionView from "../../Views/Components/MapSelectDirection/MapSelectDirectionView";
import { observable }         from "mobx";
import { social }             from "../../config/social";
import { Direction }          from "../../Models/User/Direction";
import { FormStore }          from "../../Store/FormStore";

/// <reference types="@types/googlemaps" />

interface IMapSelectDirectionViewModelProps {
    direction: Direction;
    FormStore?: FormStore;
}

const options = {
    center: {lat: social.lat, lng: social.lng},
    zoom  : 14
};

@inject(FormStore.NAME_STORE)
@observer
class MapSelectDirectionViewModel extends React.Component<IMapSelectDirectionViewModelProps, {}> {
    @observable
    private place: string = "";

    private readonly refInput: React.RefObject<HTMLInputElement> = React.createRef();

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    public getPlace = (): string => {
        return this.place;
    };

    public setPlace = (value: string) => {
        this.place = value;
    };

    protected onGoogleApiLoaded = (maps: { map: any, maps: any }) => {
        this.formStore.map = maps.map;
        const marker       = new google.maps.Marker({
                                                        draggable: true,
                                                        map      : this.formStore.map,
                                                        position : options.center,
                                                    });

        const input     = this.refInput.current as HTMLInputElement,
              searchBox = new google.maps.places.SearchBox(input);

        google.maps.event.addListener(marker, "dragend", () => {
            this.onDraggedPosition(marker);
        });

        this.formStore.markers = [marker];
        this.formStore.map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

        this.formStore.map.addListener("bounds_changed", () => {
            searchBox.setBounds(this.formStore.map.getBounds() as google.maps.LatLngBounds);

            this.onDraggedPosition(this.formStore.markers[0]);
        });

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            this.formStore.markers.forEach((marker) => {
                // tslint:disable-next-line:no-null-keyword
                marker.setMap(null);
            });
            this.formStore.markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach((place: google.maps.places.PlaceResult) => {
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                const marker = new google.maps.Marker({
                                                          draggable: true,
                                                          // icon,
                                                          map      : this.formStore.map,
                                                          position : place.geometry.location,
                                                          title    : place.name
                                                      });

                // Create a marker for each place.
                this.formStore.markers.push(
                    marker
                );

                google.maps.event.addListener(marker, "dragend", () => {
                    this.onDraggedPosition(marker);
                });

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            this.formStore.map.fitBounds(bounds);
        });

        const destinationA = new google.maps.LatLng(9.9607411, -84.084738);
        const destinationB = new google.maps.LatLng(9.9157168, -84.1415523);

        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                destinations: [destinationB],
                origins     : [destinationA],
                travelMode  : google.maps.TravelMode.DRIVING,
                // transitOptions: TransitOptions,
                // drivingOptions: DrivingOptions,
                unitSystem  : google.maps.UnitSystem.METRIC,
                // avoidHighways: Boolean,
                // avoidTolls: Boolean,
            }, callback);

        function callback(response: any, status: any) {
            // console.log("response", response);
            // console.log("status", status);
            // See Parsing the Results for
            // the basics of a callback function.
        }
    };

    protected onDraggedPosition = (marker: google.maps.Marker) => {
        const geocoder    = new google.maps.Geocoder(),
              {direction} = this.props;

        geocoder.geocode({
                             location: marker.getPosition() as google.maps.LatLng
                         },
                         (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                             if (status == google.maps.GeocoderStatus.OK) {
                                 const location = results[0].geometry.location;
                                 direction.setLat(location.lat().toString());
                                 direction.setLng(location.lng().toString());
                             }
                         }
        );
    };

    public render(): React.ReactNode {
        return (
            <MapSelectDirectionView
                onGoogleApiLoaded={this.onGoogleApiLoaded}
                place={this.place}
                setPlace={this.setPlace}
                refInput={this.refInput}
                options={options}
            />
        );
    }
}

export default MapSelectDirectionViewModel;