import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";

interface ErrorProps extends WithNamespaces {
    backgroundImage: string;
    title: string;
    content: string;
    buttonTitle: string;
    buttonLink: string;
    prefix?: string;
}

@InjectPrefix
class Error extends React.Component<ErrorProps, any> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  backgroundImage,
                  title,
                  content,
                  buttonTitle,
                  buttonLink
              } = this.props;

        return (
            <div className={`${this.prefix}-p-error`}>
                Error 404
            </div>
        );
    }
}

export default withNamespaces("pages")(Error);
