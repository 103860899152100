import * as React                        from "react";
import { inject, observer }              from "mobx-react";
import PromotionView                     from "../Views/Pages/PromotionView";
import { IGetPromotion, PromotionStore } from "../Store/PromotionStore";
import { RouteComponentProps }           from "react-router";
import { observable }                    from "mobx";
import { GalleryStore }                  from "../Store/GalleryStore";
import { GalleryItem }                   from "../Models/Gallery/GalleryItem";
import CircularProgress                  from "@material-ui/core/CircularProgress";
import Box                               from "@material-ui/core/Box";

interface ProfileViewModelProps extends RouteComponentProps<any> {
    PromotionStore?: PromotionStore;
    GalleryStore?: GalleryStore;
}

@inject(PromotionStore.NAME_STORE, GalleryStore.NAME_STORE)
@observer
class PromotionViewModel extends React.Component<ProfileViewModelProps, {}> {
    @observable
    private data: IGetPromotion;

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    get promotionStore(): PromotionStore {
        return this.props.PromotionStore as PromotionStore;
    }

    constructor(props: ProfileViewModelProps) {
        super(props);
        this.promotionStore.setSpinner(true);
    }

    public componentDidMount = async (): Promise<void> => {
        const {slug} = this.props.match.params;

        this.data = await this.promotionStore.getBySlug(slug);
        this.galleryStore.setCurrentImage(this.data.promotion.getGallery().getFirstItem() as GalleryItem);
    };

    public render(): React.ReactNode {
        if (!this.data) return (
            <Box justifyContent="center" className="d-flex m-4">
                <CircularProgress color="secondary"/>
            </Box>
        );

        return (
            <PromotionView
                promotion={this.data.promotion}
                nextPromotions={this.data.next || []}
                previousPromotions={this.data.previous || []}
            />
        );
    }
}

export default PromotionViewModel;
