import {
    inject,
    observer
}                                from "mobx-react";
import { PageStore }             from "../../Store/PageStore";
import * as React                from "react";
import {
    RouteComponentProps,
    withRouter
}                                from "react-router-dom";
import ProductsView              from "../../Views/Pages/Products/ProductsView";
import { pages }                 from "../../config/Pages";
import { observable }            from "mobx";
import { Page }                  from "../../Models/Page/Page";
import { PrincipalSearch }       from "../../Models/Product/PrincipalSearch";
import { ProductStore }          from "../../Store/ProductStore";
import { Category }              from "../../Models/Product/Category";
import { Product }               from "../../Models/Product/Product";
import { Container }             from "typedi";
import ModalService              from "../../Service/ModalService";
import { ModalProductViewModel } from "../Modal/ModalProductViewModel";

interface IProductsViewModelProps extends RouteComponentProps<any> {
    PageStore?: PageStore;
    ProductStore?: ProductStore;
}

@inject(PageStore.NAME_STORE, ProductStore.NAME_STORE)
@observer
class ProductsViewModel extends React.Component<IProductsViewModelProps, {}> {
    @observable
    private page: Page | undefined;

    @observable
    private loading: {
        products: boolean;
        categories: boolean;
    } = {products: true, categories: true};

    @observable
    private slug: string;

    @observable
    private productToShow: Product | undefined;

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    constructor(props: IProductsViewModelProps) {
        super(props);

        this.productStore.setPrincipalSearch(new PrincipalSearch());
        this.productStore.setProducts([]);
    }

    public componentDidMount = async (): Promise<void> => {
        this.productStore.setCurrentPage(1);
        this.page = await this.pageStore.getPage(pages.products);
        await this.productStore.loadCategories();

        const {match: {params: {slug}}} = this.props;

        if (slug) await this.loadProduct(slug);
        await this.getPageSearch(1);

        this.loading.categories = false;
        this.loading.products   = false;
    };

    protected loadProduct = async (slug: string) => {
        this.productToShow = await this.productStore.getProductSlug(slug);

        if (!this.productToShow) return;

        this.productStore.getProducts().push(this.productToShow);
        Container.get(ModalService).openModal(ModalProductViewModel, {
            onClose: () => {
                this.productToShow = undefined;
            },
            product: this.productToShow
        });
    }

    protected getPageSearch = async (page: number) => {
        this.loading.products = true;

        this.productStore.setCurrentPage(page);
        const {data, pages} = await this.productStore.getSearchPaginate();

        this.productStore.setPages(pages);
        this.productStore.getProducts().push(...data);

        this.loading.products = false;
    };

    protected searchAction = () => {
        window.scrollTo({
            behavior: "smooth",
            left    : 0,
            top     : 0,
        });
        this.productStore.setProducts([]);
        this.getPageSearch(1);
    };

    protected getNextPage = () => {
        this.getPageSearch(this.productStore.getCurrentPage() + 1);
    };

    protected removeCategory = (category: Category) => {
        this.productStore.removeCategoryPrincipalSearch(category);
        this.searchAction();
    };

    public render(): React.ReactNode {
        return (
            <ProductsView
                search={this.searchAction}
                loading={this.loading}
                showMore={this.getNextPage}
                currentPage={this.productStore.getCurrentPage()}
                pages={this.productStore.getPages()}
                principalSearch={this.productStore.getPrincipalSearch()}
                removeCategory={this.removeCategory}
                page={this.page}
                openSlugProduct={this.productToShow ? this.productToShow.getSlug() : ""}
            />
        );
    }

}

export default withRouter(ProductsViewModel);
