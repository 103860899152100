import * as React                                           from "react";
import InjectPrefix                                         from "../../../Decorators/InjectPrefix";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { PromotionInformation }                             from "../../../Models/Promotion/PromotionInformation";

interface TableInformationProps {
    prefix?: string;
    information: PromotionInformation[];
}

@InjectPrefix
export class TableInformation extends React.Component<TableInformationProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  information
              } = this.props;

        return (
            <Table className={`${this.prefix}-table-information`}>
                <TableBody>
                    {information.map((row: PromotionInformation, key: number) => (
                        <TableRow key={key} className="body">
                            <TableCell component={"th" as unknown as React.ComponentClass} scope="row" className="type">
                                {row.getTitle()}
                            </TableCell>
                            <TableCell className="item">{row.getDescription()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}
