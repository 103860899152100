import * as React                         from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import { TextField }                      from "@material-ui/core";
import Row                                from "reactstrap/lib/Row";
import Col                                from "reactstrap/lib/Col";
import Cards, { Focused }                 from "react-credit-cards";
import InputMask                          from "react-input-mask";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { ErrorsCards }                    from "../../../config/errorsCards";


interface ICreditCardProps extends WithNamespaces {
    cvc: string;
    expiry: string;
    name: string;
    cardNumber: string;
    error: string;
    focused: Focused;
    setCvc: (val: string) => void;
    setExpiry: (val: string) => void;
    setName: (val: string) => void;
    setCardNumber: (val: string) => void;
    setFocused: (val: Focused) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class CreditCard extends React.Component<ICreditCardProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderCardNumberInput = () => {
        const {
                  t,
                  cardNumber,
                  setCardNumber,
                  setFocused,
              } = this.props;

        return (
            <InputMask
                mask="9999 9999 9999 9999"
                value={cardNumber}
                disabled={false}
                // @ts-ignore
                maskChar={""}
                onFocus={() => setFocused("number")}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setCardNumber(evt.target.value);
                }}
            >
                {(inputProps: any) => (
                    <TextField
                        id="form-cardNumber"
                        label={t("credit-card.cardNumber")}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        {...inputProps}
                    />
                )}
            </InputMask>

        );
    };

    protected renderCardNameInput = () => {
        const {
                  t,
                  name,
                  setName,
                  setFocused,
              } = this.props;

        return (
            <TextField
                id="form-name"
                label={t("credit-card.name")}
                fullWidth
                required
                value={name}
                onFocus={() => setFocused("name")}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setName(evt.target.value);
                }}
                margin="normal"
                variant="outlined"
            />
        );
    };

    protected renderCVCInput = () => {
        const {
                  t,
                  cvc,
                  setCvc,
                  setFocused,
              } = this.props;

        return (
            <InputMask
                mask="9999"
                value={cvc}
                disabled={false}
                // @ts-ignore
                maskChar={""}
                onFocus={() => setFocused("cvc")}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setCvc(evt.target.value);
                }}
            >
                {(inputProps: any) => (
                    <TextField
                        id="form-cvc"
                        label={t("credit-card.cvc")}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        {...inputProps}
                    />
                )}
            </InputMask>
        );
    };

    protected renderExpiryInput = () => {
        const {
                  t,
                  expiry,
                  setExpiry,
                  setFocused,
              } = this.props;

        return (
            <InputMask
                mask="99/99"
                value={expiry}
                disabled={false}
                // @ts-ignore
                maskChar={""}
                onFocus={() => setFocused("expiry")}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setExpiry(evt.target.value);
                }}
            >
                {(inputProps: any) => (
                    <TextField
                        id="form-expiry"
                        label={t("credit-card.expiry")}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        {...inputProps}
                    />
                )}
            </InputMask>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  cvc,
                  expiry,
                  name,
                  cardNumber,
                  focused,
                  error,
              } = this.props;

        return (
            <section className={`${this.prefix}-credit-card`}>
                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <Cards
                            cvc={cvc}
                            expiry={expiry}
                            name={name}
                            focused={focused}
                            number={cardNumber}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        {this.renderCardNumberInput()}
                        {this.renderCardNameInput()}
                        <Row>
                            <Col xs={12} md={6}>
                                {this.renderExpiryInput()}
                            </Col>
                            <Col xs={12} md={6}>
                                {this.renderCVCInput()}
                            </Col>
                        </Row>
                        {
                            error
                            ?
                            <p className={`${this.prefix}-error-color`}>{error}</p>
                            : <React.Fragment/>
                        }
                    </Col>
                </Row>
            </section>
        );
    }
}

export default withNamespaces("global")(CreditCard);