import { observable } from "mobx";
import * as React     from "react";

export default class SnackBar {
    @observable
    private open: boolean;

    @observable
    private currentSnackBar: React.ComponentType | undefined;

    public setOpen(openModal: boolean): this {
        this.open = openModal;

        return this;
    }

    public getOpen(): boolean {
        return this.open;
    }

    public setCurrentSnackBar(currentModal: any): this {
        this.currentSnackBar = currentModal;

        return this;
    }

    public getCurrentSnackBar(): React.ComponentType | undefined {
        return this.currentSnackBar;
    }
}
