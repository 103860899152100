import * as React           from "react";
import { inject, observer } from "mobx-react";
import { PageStore }        from "../../Store/PageStore";
import { UserStore }        from "../../Store/UserStore";
import ProfileView          from "../../Views/Pages/User/ProfileView";
import User                 from "../../Models/User/User";
import { observable }       from "mobx";
import { Order }            from "../../Models/Order/Order";
import MyOrdersView         from "../../Views/Pages/User/MyOrdersView";
import Box                  from "@material-ui/core/Box";
import CircularProgress     from "@material-ui/core/CircularProgress";

interface IProfileViewModelProps {
    PageStore?: PageStore;
    UserStore?: UserStore;
}

@inject(UserStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class ProfileViewModel extends React.Component<IProfileViewModelProps, {}> {
    @observable
    private orders: Order[] = [];

    @observable
    private loading: boolean = true;

    public getOrders = (): Order[] => {
        return this.orders;
    };

    public setOrders = (value: Order[]) => {
        this.orders = value;
    };

    public componentDidMount = async () => {
        const orders = await this.userStore.getMyOrders();
        this.setOrders(orders);
        this.loading = false;
    };

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public render(): React.ReactNode {
        if (this.loading) return (
            <Box justifyContent="center" className="d-flex m-4">
                <CircularProgress color="secondary"/>
            </Box>
        );

        return (
            <ProfileView
                user={this.userStore.getUser() as User}
                orders={this.getOrders()}
            />
        );
    }
}

export default ProfileViewModel;