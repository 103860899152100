import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import { Container }                      from "@material-ui/core";

interface IPolicyPrivacyViewProps extends WithNamespaces {
    prefix?: string;
}

@InjectPrefix
class PolicyPrivacyView extends React.Component<IPolicyPrivacyViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        console.log("log");
        return (
            <section className={`${this.prefix}-p-policy-privacy`}>
                <Container>
                    <h3>Políticas, términos y condiciones</h3>
                    <p>En la ferretería Maderas Camacho.com creemos que, como nuestro Cliente on-line, usted debe
                        conocer nuestras prácticas en lo que se refiere a la información que obtenemos de sus visitas a
                        nuestra página. Las directrices aquí incluidas sólo son aplicables a las visitas y compras en
                        nuestra página.</p>

                    <h3>Privacidad y seguridad de datos</h3>
                    <p>Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible,
                        especialmente para mantener un registro de usuarios, de pedidos en caso de que aplique, y
                        mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos
                        periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra
                        información que consideremos que pueda brindarle algún beneficio, estos correos electrónicos
                        serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier
                        momento.</p>
                    <p>Maderas Camacho está altamente comprometido para cumplir con el compromiso de mantener su
                        información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para
                        asegurarnos que no exista ningún acceso no autorizado.</p>
                    <p>La información que usted nos proporcione como cliente será protegida de conformidad con los
                        principios de privacidad, maderascamacho.com se compromete a tratar y recabar los datos
                        personales de manera licita, trasparente y legal.</p>

                    <h3>Recomendaciones de uso</h3>
                    <p>Cuando usted ingrese al sistema de compras en línea, deberá asegurarse de que ha ingresado a la
                        página oficial de maderas camacho, la cual es www.maderascamacho.com, y cuando haya terminado su
                        compra o la vaya a abandonar, deberá asegurarse de cerrar su sesión en su cuenta con el fin de
                        evitar que terceras personas utilicen la información que ha ingresado al sistema sin su
                        consentimiento. Asimismo, usted deberá verificar que la información que ha ingresado al sistema
                        es la correcta y que el artículo que ha escogido sea el correcto, por cuanto Maderas Camacho no
                        se hará responsable por cualquier error que usted tenga en su orden de compra. La
                        confidencialidad y custodia de la clave de acceso a su cuenta en el sitio web será de su
                        exclusiva responsabilidad.</p>

                    <h3>Métodos de pago</h3>
                    <p>En maderascamacho.com se permite formas de pago con tarjetas de crédito o debito, se aceptarán
                        únicamente tarjetas VISA y Masterd Card vigentes. De igual manera se puede realizar el pago por
                        transferencia bancaria o Sinpemovil.</p>
                    <p>Maderas Camacho.com utiliza la plataforma 4geeks payment para realizar los pagos, dicha
                        plataforma no guarda ningún dato de las tarjetas utilizadas.</p>
                    <p>En caso de haber realizado la transacción y no se cuente con inventario, un colaborador se estará
                        comunicando con el cliente para coordinar el envió de un producto sustituto o si procede con el
                        reembolso del dinero en la misma forma de pago como se realizó la transacción.</p>

                    <h3>Cambio o devolución</h3>
                    <p>Para proceder con la devolución o cambio del producto, siempre el producto debe ser regresado con
                        su empaque original, sellado, en excelente estado, con todas las etiquetas y no usado. Además,
                        la devolución no se aceptará con más de 30 días a partir de efectuada la factura. No se aceptan
                        devoluciones por aquellos productos que hayan sido cortados para su venta tales como cables,
                        cadenas, mecates, cedazos, tubo metálico, tubo de PVC, láminas de cualquier tipo y maderas si se
                        solicitó el servicio de corte. etc.</p>
                    <p>En el caso de productos perecederos tales como cemento, morteros, pega cerámica fraguas e
                        impermeabilizantes no se pueden cambiar.</p>
                    <p>La mercadería solamente puede ser cambiada cuando el cliente presente la factura de compra en
                        buen estado y legible sin excepción.</p>
                    <p>Se procederá con la devolución de dinero si la empresa no cuenta con el producto que el cliente
                        solicita. La devolución del dinero se realizará por el mismo medio de pago. Si fue con
                        transferencia, se le estará realizando una transacción igual a su cuenta; y si la compra fue
                        realizada con tarjeta, se reversa la transacción por medio del datafono y el tiempo que
                        trascurra desde el momento del reembolso corre por cuenta del Banco emisor.</p>
                    <p>El cambio de todo producto será única y exclusivamente por daños y defectos de fábrica, sin
                        embargo, esta garantía no se reconocerá en caso de que maderascamacho.com compruebe:</p>
                    <p>1. El mal uso del producto por parte del cliente</p>
                    <p>2. Que los productos evidencien un daño físico o alteraciones como desgaste, raspones, rupturas,
                        golpes o modificaciones entre otros.</p>
                    <p>Si el producto adquirido tiene las condiciones anteriores maderascamacho.com no aplicara ningún
                        tipo de reembolso ni cambio de mercadería.</p>

                    <h3>Políticas de entrega</h3>
                    <p>La empresa Maderas Camacho se compromete a realizar las entregas en el plazo de 48 horas,
                        exclusivamente dentro del territorio de la Gran Área Metropolitana (GAM), siempre y cuando no
                        exista ningún caso fortuito como cierres, huelgas o paros en carreteras. Además, no se podrá
                        cambiar el lugar de entrega si ya la mercadería salió de la ferretería.</p>
                    <p>Ventas fuera del GAM son sujetas a coordinación directa con el cliente.</p>
                    <p>El producto se entregará en la puerta de la ubicación establecida, esto con el fin de asegurar la
                        entrega directamente con la persona que realizo la compra o el autorizado por el cliente.</p>

                    <h3>Derechos de Propiedad y uso Inadecuado del Sitio</h3>
                    <p>La ferretería Maderas Camacho S.A, es la dueña de los derechos de este sitio. El contenido de
                        esta página (texto, fotos, audio, video, etc.) no pueden ser utilizadas sin la autorización
                        escrita de Maderas Camacho S.A.</p>
                    <p>Al visitar nuestra página maderascamacho.com usted acepta que no utilizará la información para
                        otros fines que no sean personales, esta prohibido cambiar cualquier información, si se
                        comprueba que su cuenta o visita esta siendo utilizada en contra de nuestras políticas, la
                        cuenta podrá ser deshabilitada.</p>
                </Container>
            </section>
        );
    }
}

export default withNamespaces("policyPrivacy")(PolicyPrivacyView);
