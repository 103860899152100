import {
    inject,
    observer
}                       from "mobx-react";
import * as React       from "react";
import { ColorStore }   from "../../Store/ColorStore";
import { Color }        from "../../Models/Color/Color";
import { ColorPalette } from "../../Models/Color/ColorPalette";
import ColorPickerBar   from "../../Views/Components/ColorPicker/ColorPickerBar";
import { Product }      from "../../Models/Product/Product";
import { observable }   from "mobx";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IColorPickerModelProps {
    product: Product;
    color: Color;
    setColor: (val: Color) => void;
    className?: string;
    ColorStore?: ColorStore;
}

@inject(ColorStore.NAME_STORE)
@observer
class ColorPickerViewModel extends React.Component<IColorPickerModelProps, {}> {
    @observable
    private colorPalette: ColorPalette;

    @observable
    private loading: boolean = true;

    protected showPicker = (): boolean => {
        const {product} = this.props;

        return product.hasToPickColor();
    };

    public componentDidMount = async () => {
        if (!this.showPicker()) return;

        const {product} = this.props;

        this.colorPalette = await this.colorStore.getColorPalette(product.getColorPaletteId());
        this.loading      = false;
    }

    protected get colorStore(): ColorStore {
        return this.props.ColorStore as ColorStore;
    }

    public render(): React.ReactNode {
        if (!this.showPicker()) return (<React.Fragment/>);
        if (this.loading) return (<CircularProgress color="secondary"/>);

        const {
                  color,
                  className,
                  setColor
              } = this.props;

        return (
            <ColorPickerBar
                colorPalette={this.colorPalette}
                pickedColor={color}
                className={className}
                onChange={setColor}
            />
        );
    }
}

export default ColorPickerViewModel;
