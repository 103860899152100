import * as React from "react";

import { observer }   from "mobx-react";
import { CreditCard } from "../../Models/Order/CreditCard";
import CreditCardView from "../../Views/Components/CreditCard/CreditCard";
import { observable } from "mobx";
import { Focused }    from "react-credit-cards";

interface ICreditCardProps {
    creditCard: CreditCard;
    error: string;
}

@observer
class CreditCardViewModel extends React.Component<ICreditCardProps, {}> {
    @observable
    private focused: Focused = "name";

    public setFocused = (value: Focused) => {
        this.focused = value;
    };

    public render(): React.ReactNode {
        const {
                  creditCard,
                  error,
              } = this.props;

        return (
            <CreditCardView
                cvc={creditCard.getCvc()}
                expiry={creditCard.getExpiry()}
                name={creditCard.getName()}
                cardNumber={creditCard.getCardNumber()}
                focused={this.focused}
                setCvc={creditCard.setCvc}
                setExpiry={creditCard.setExpiry}
                setName={creditCard.setName}
                setCardNumber={creditCard.setCardNumber}
                setFocused={this.setFocused}
                error={error}
            />
        );
    }

}

export default CreditCardViewModel;