import * as React        from "react";
import PolicyPrivacyView from "../Views/Pages/PolicyPrivacyView";

interface IPolicyPrivacyViewModelProps {

}

class PolicyPrivacyViewModel extends React.Component<IPolicyPrivacyViewModelProps, {}> {
    /**
     * render
     */
    public render(): React.ReactNode {
        return (<PolicyPrivacyView/>);
    }
}

export default PolicyPrivacyViewModel;