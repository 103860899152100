import { inject, observer } from "mobx-react";
import * as React           from "react";
import { CarStore }         from "../../Store/CarStore";
import CarView              from "../../Views/Pages/Car/CarView";
import Box                  from "@material-ui/core/Box";
import CircularProgress     from "@material-ui/core/CircularProgress";
import { observable }       from "mobx";

interface ICarViewModelProps {
    CarStore?: CarStore;
}

@inject(CarStore.NAME_STORE)
@observer
class CarViewModel extends React.Component<ICarViewModelProps, {}> {
    @observable
    private loading: boolean = true;

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    public componentDidMount = async () => {
        await this.carStore.refreshProductStore();
        this.loading = false;
    };

    public render(): React.ReactNode {
        if (this.loading) {
            return (
                <Box justifyContent="center" className="d-flex m-4">
                    <CircularProgress color="secondary"/>
                </Box>
            );
        }

        return (
            <CarView
                productItemCars={this.carStore.getProductStore()}
                removeProduct={this.carStore.removeProduct}
            />
        );
    }
}

export default CarViewModel;