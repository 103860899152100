import * as React                         from "react";
import { Button, Card }                   from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Direction }                      from "../../../Models/User/Direction";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";

interface ICardChooseDirectionProps extends WithNamespaces {
    direction: Direction;
    directionUID: string;
    setDirectionToOrder: (val: Direction) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class CardChooseDirection extends React.Component<ICardChooseDirectionProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  direction,
                  setDirectionToOrder,
                  directionUID,
              }          = this.props,
              isSelected = direction.getUID() === directionUID;

        return (
            <Card variant="outlined" className={`card ${this.prefix}-card-direction ${isSelected ? "active" : ""}`}>
                <div className="content">
                    <p className="font-weight-bold">{direction.getFullName()}</p>
                    <ul className="list-unstyled">
                        <li>{direction.getDni()}</li>
                        <li>{direction.getProvincie()}</li>
                        <li>{direction.getCanton()}</li>
                        <li>{direction.getDirection()}</li>
                    </ul>
                    <div className="options">
                        <Button
                            className="btn-primary"
                            color="primary"
                            variant="contained"
                            onClick={() => setDirectionToOrder(direction)}
                        >
                            {t(isSelected ? "selected-direction" : "select-direction")}
                        </Button>
                    </div>
                </div>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardChooseDirection);
