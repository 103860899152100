import { serializable } from "serializr";
import { observable }   from "mobx";

export enum RegisterType {
    FORM     = "form",
    GOOGLE   = "google",
    FACEBOOK = "facebook",
}

export class Register {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private firstName: string;

    @serializable
    @observable
    private lastName: string;

    @serializable
    @observable
    private email: string;

    @serializable
    @observable
    private username: string;

    @serializable
    @observable
    private password: string;

    @serializable
    @observable
    private confirmPassword: string;

    @serializable
    @observable
    private googleJWT: string;

    @observable
    private isFacebook: boolean = false;

    @serializable
    @observable
    private type: RegisterType = RegisterType.FORM;

    public getId(): string {
        return this._id;
    }

    public getFirstName(): string {
        return this.firstName || "";
    }

    public setFirstName(value: string) {
        this.firstName = value;
    }

    public getLastName(): string {
        return this.lastName || "";
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getEmail(): string {
        return this.email || "";
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getUsername(): string {
        return this.username || "";
    }

    public setUsername(value: string) {
        this.username = value;
    }

    public getType(): RegisterType {
        return this.type;
    }

    public setType(value: RegisterType) {
        this.type = value;
    }

    public getPassword(): string {
        return this.password || "";
    }

    public setPassword(value: string) {
        this.password = value;
    }

    public getConfirmPassword(): string {
        return this.confirmPassword || "";
    }

    public setConfirmPassword(value: string) {
        this.confirmPassword = value;
    }

    public getGoogleJWT(): string {
        return this.googleJWT || "";
    }

    public setGoogleJWT(value: string) {
        this.googleJWT = value;
    }

    public getIsFacebook(): boolean {
        return this.isFacebook;
    }

    public setIsFacebook(value: boolean) {
        this.isFacebook = value;
    }
}