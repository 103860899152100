import * as React       from "react";
import InjectPrefix     from "../../../Decorators/InjectPrefix";
import {
    Carousel,
    CarouselItem,
    CarouselCaption,
    CarouselIndicators,
    CarouselControl
}                       from "reactstrap";
import { Hidden }       from "@material-ui/core";
import { Gallery }      from "../../../Models/Gallery/Gallery";
import { observer }     from "mobx-react";
import { observable }   from "mobx";
import { GalleryItem }  from "../../../Models/Gallery/GalleryItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box              from "@material-ui/core/Box";

interface CarouselThemeProps {
    gallery: Gallery | undefined;
    prefix?: string;
    items?: any;
}

@InjectPrefix
@observer
class CarouselTheme extends React.Component<CarouselThemeProps, {}> {
    private animating: boolean;

    @observable
    private activeIndex: number = 0;

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected getItems = (): GalleryItem[] => {
        const {gallery} = this.props;
        return gallery ? gallery.getItems() : [];
    };

    private next = () => {
        if (this.animating) return;

        this.activeIndex = this.activeIndex === this.getItems().length - 1 ? 0 : this.activeIndex + 1;
    };

    private previous = () => {
        if (this.animating) return;

        this.activeIndex = this.activeIndex === 0 ? this.getItems().length - 1 : this.activeIndex - 1;
    };

    private goToIndex = (newIndex: number) => {
        if (this.animating) return;
        this.activeIndex = newIndex;
    };

    private onExiting = () => {
        this.animating = true;
    };

    private onExited = () => {
        this.animating = false;
    };

    protected renderSlides = (): React.ReactNode => {
        const {gallery} = this.props;

        if (!gallery) return ([<React.Fragment key={0}/>]);

        return gallery.getItems().map((item: GalleryItem, k: number) => {
            const image = (<img src={item.getImageFullPath()} alt={item.getTitle()} className={"w-100"}/>);
            // if (item.srcMobile) {
            //     image = (
            //         <>
            //             <Hidden only="xs">
            //                 <img src={item.src} alt={item.altText} className={"w-100"}/>
            //             </Hidden>
            //             <Hidden smUp>
            //                 <img src={item.srcMobile} alt={item.altText} className={"w-100"}/>
            //             </Hidden>
            //         </>
            //     );
            // } else {
            //     image = (<img src={item.src} alt={item.altText} className={"w-100"}/>);
            // }

            return (
                <CarouselItem
                    key={`${k}-${item.getKey()}`}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    {image}
                    <CarouselCaption captionText={item.getSubtitle()} captionHeader={item.getTitle()}/>
                </CarouselItem>
            );
        });
    };

    public render(): React.ReactNode {
        const {gallery} = this.props;

        if (!gallery)
            return (
                <Box justifyContent="center" className="d-flex m-4">
                    <CircularProgress color="secondary"/>
                </Box>
            );

        return (
            <Carousel
                className={`${this.prefix}-carousel`}
                activeIndex={this.activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators
                    items={gallery ? gallery.getItems() : []}
                    activeIndex={this.activeIndex}
                    onClickHandler={this.goToIndex}
                />
                {this.renderSlides()}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
            </Carousel>
        );
    }
}

export default CarouselTheme;
