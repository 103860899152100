import { inject, observer }       from "mobx-react";
import * as React                 from "react";
import { UserStore }              from "../../Store/UserStore";
import { observable }             from "mobx";
import SimpleReactValidator       from "simple-react-validator";
import { ERROR_CLASS, FormStore } from "../../Store/FormStore";
import { withNamespaces }         from "react-i18next";
import { scrollToElement }        from "../../Utils/common";
import { RegisterStore }          from "../../Store/RegisterStore";
import { Login }                  from "../../Models/Form/Login";
import AuthService                from "../../Service/AuthService";
import { Container }              from "typedi";
import IBaseProps                 from "../Props/IBaseProps";
import { withRouter }             from "react-router-dom";
import { PageNames, renderRoute } from "../../Routes/routes";
import LoginView                  from "../../Views/Pages/LoginRegister/LoginView";

interface IRegisterViewModelProps extends IBaseProps {
    UserStore?: UserStore;
    FormStore?: FormStore;
    RegisterStore?: RegisterStore;
}

@inject(
    UserStore.NAME_STORE,
    FormStore.NAME_STORE,
    RegisterStore.NAME_STORE
)
@observer
class LoginViewModel extends React.Component<IRegisterViewModelProps, {}> {
    private login: Login = new Login();

    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private send: boolean = false;

    @observable
    private existUser: boolean = true;

    @observable
    private passwordIncorrect: boolean = false;

    @observable
    private sending: boolean = false;

    private errorDOMElement: HTMLElement | null;

    get authService(): AuthService {
        return Container.get(AuthService);
    }

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get registerStore(): RegisterStore {
        return this.props.RegisterStore as RegisterStore;
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    constructor(props: IRegisterViewModelProps) {
        super(props);

        const {t} = this.props;

        this.validator = this.formStore.getNewValidator(t);
    }

    public componentDidMount = (): void => {
        if (this.userStore.getUser()) this.props.history.push(renderRoute(PageNames.homePage));
    };

    public getLogin(): Login {
        return this.login;
    }

    public setLogin(value: Login) {
        this.login = value;
    }

    protected setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    protected scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) scrollToElement(this.errorDOMElement, 1000, 180);
    };

    private submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.scrollOnError();
            });

            return;
        }

        this.sending = true;

        const existUser = await this.registerStore.existEmail(this.getLogin().getUsername());

        if (!existUser) {
            this.existUser = false;
            this.send      = false;
            this.sending   = false;
            return;
        }

        const confirm = await this.authService.getAccessToken(this.login);


        if (!confirm) {
            this.passwordIncorrect = true;
            this.send              = false;
            this.sending           = false;
            return;
        }

        this.send    = true;
        this.sending = false;

        this.props.history.push(renderRoute(PageNames.homePage));
    };

    public render(): React.ReactNode {
        return (
            <LoginView
                login={this.getLogin()}
                validator={this.validator}
                submit={this.submit}
                send={this.send}
                sending={this.sending}
                existUser={this.existUser}
                passwordIncorrect={this.passwordIncorrect}
            />
        );
    }
}

export default withRouter(withNamespaces("forms")(LoginViewModel));