import { serializable } from "serializr";
import { observable }   from "mobx";
import uuid             from "uuid";

export class PromotionInformation {
    @serializable
    @observable
    private title: string;

    @serializable
    @observable
    private description: string;

    @serializable
    @observable
    private key: string;

    @serializable
    @observable
    private order: number;

    constructor() {
        this.setKey(uuid());
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(value: string) {
        this.title = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getKey(): string {
        return this.key;
    }

    public setKey(value: string) {
        this.key = value;
    }

    public getOrder(): number {
        return this.order;
    }

    public setOrder(value: number) {
        this.order = value;
    }
}
