import {
    list,
    primitive,
    serializable
} from "serializr";

export class ExtraDataHome {
    @serializable(list(primitive()))
    private productIds: string[] = [];

    public getProductIds(): string[] {
        return this.productIds;
    }

    public setProductIds(value: string[]) {
        this.productIds = value;
    }
}