import * as React                         from "react";
import { observer }                       from "mobx-react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { ContactUs, WishContact }         from "../../../Models/Form/ContactUs";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import {
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
}                                         from "@material-ui/core";
import SimpleReactValidator               from "simple-react-validator";


interface FormContactUsViewProps extends WithNamespaces {
    contactUs: ContactUs;
    prefix?: string;
    send: boolean;
    sending: boolean;
    validator: SimpleReactValidator;
    submit: (form: React.Component) => void;
}

@InjectPrefix
@observer
class FormContactUsView extends React.Component<FormContactUsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  send,
                  sending,
              } = this.props;

        let text = t("submit");

        if (sending) text = (<CircularProgress color="primary"/>);
        if (send) text = t("sent");

        const canSend = !(send || sending);

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className="mt-2"
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  contactUs,
                  t,
                  validator,
              } = this.props;

        return (
            <form className={`${this.prefix}-form-contact-us mb-4`} noValidate autoComplete="off">
                <TextField
                    id="form-name"
                    label={t("name")}
                    fullWidth
                    required
                    value={contactUs.getName()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        contactUs.setName(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message("nombre", contactUs.getName(), "required")}}
                />
                {validator.message("nombre", contactUs.getName(), "required")}
                <TextField
                    id="form-lastName"
                    label={t("lastName")}
                    fullWidth
                    required
                    value={contactUs.getLastName()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        contactUs.setLastName(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message("apellido", contactUs.getLastName(), "required")}}
                />
                {validator.message("apellido", contactUs.getLastName(), "required")}
                <TextField
                    id="form-email"
                    label={t("email")}
                    fullWidth
                    required
                    type="email"
                    value={contactUs.getEmail()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        contactUs.setEmail(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message("correo electrónico", contactUs.getEmail(), "required|email")}}
                />
                {validator.message("correo electrónico", contactUs.getEmail(), "required|email")}
                <TextField
                    id="form-phone"
                    label={t("phone")}
                    fullWidth
                    required
                    value={contactUs.getPhone()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        contactUs.setPhone(evt.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message("teléfono", contactUs.getPhone(), "required")}}
                />
                {validator.message("teléfono", contactUs.getPhone(), "required")}
                <FormControl fullWidth variant="outlined" className="wish-contact">
                    <InputLabel htmlFor="wish-contact" variant="outlined">
                        {t("wish-contact")}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        required
                        fullWidth
                        value={contactUs.getWishContact()}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                            contactUs.setWishContact(event.target.value as WishContact);
                        }}
                        inputProps={{
                            id  : "wish-contact",
                            name: "wishContact",
                        }}
                    >
                        {
                            Object.keys(WishContact).map((item: string) => {
                                return (
                                    <MenuItem value={WishContact[item]} key={item}>
                                        {t(WishContact[item])}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <TextField
                    id="form-message"
                    label={t("message")}
                    fullWidth
                    required
                    value={contactUs.getMessage()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        contactUs.setMessage(evt.target.value);
                    }}
                    multiline
                    rows="4"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{error: !!validator.message("mensaje", contactUs.getMessage(), "required")}}
                />
                {validator.message("mensaje", contactUs.getMessage(), "required")}
                {this.renderButton()}
            </form>
        );
    }
}

export default withNamespaces("forms")(FormContactUsView);
