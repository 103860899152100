import {
    inject,
    observer
}                                             from "mobx-react";
import * as React                             from "react";
import { observable }                         from "mobx";
import { Product }                            from "../../Models/Product/Product";
import { CarStore }                           from "../../Store/CarStore";
import AddCar                                 from "../../Views/Components/AddCar/AddCar";
import { disableShopCar }                     from "../../Utils/common";
import { Container }                          from "typedi";
import SnackBarService                        from "../../Service/SnackBarService";
import SnackBarAddedProductViewModel          from "../SnackBar/SnackBarAddedProductViewModel";
import ModalService                           from "../../Service/ModalService";
import { Color }                              from "../../Models/Color/Color";
import { SnackBarProductValidColorViewModel } from "../SnackBar/SnackBarProductValidColorViewModel";
import { ModalProductViewModel }              from "../Modal/ModalProductViewModel";

interface IAddCarViewModelProps {
    openModal?: boolean;
    product: Product;
    color?: Color;
    CarStore?: CarStore;
}

@inject(CarStore.NAME_STORE)
@observer
class AddCarViewModel extends React.Component<IAddCarViewModelProps, {}> {
    @observable
    protected value: number = 1;

    protected setValue = (value: number) => {
        this.value = value;
    };

    protected actionAddCar = () => {
        const {
                  product,
                  color,
                  openModal,
              } = this.props;

        if (product.hasToPickColor() && !color) {
            Container.get(SnackBarService).open(SnackBarProductValidColorViewModel, {
                product
            });

            if (openModal)
                Container.get(ModalService).openModal(ModalProductViewModel, {
                    product
                });
            return;
        }

        this.carStore.addProduct(product, this.value, color);

        Container.get(SnackBarService).open(SnackBarAddedProductViewModel, {
            product
        });
        Container.get(ModalService).closeModal();
    };

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    public render(): React.ReactNode {
        const {product} = this.props;
        if (disableShopCar()) return (<React.Fragment/>);

        return (
            <AddCar
                inStock={product.getInStock()}
                value={this.value}
                setValue={this.setValue}
                action={this.actionAddCar}
            />
        );
    }
}

export default AddCarViewModel;