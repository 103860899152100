import * as React                         from "react";
import Card                               from "@material-ui/core/Card";
import CardHeader                         from "@material-ui/core/CardHeader";
import CardContent                        from "@material-ui/core/CardContent";
import CardActions                        from "@material-ui/core/CardActions";
import Typography                         from "@material-ui/core/Typography";
import { Link }                           from "react-router-dom";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { PageNames, renderRoute }         from "../../../Routes/routes";
import { Tutorial }                       from "../../../Models/Tutorial/Tutorial";


interface CardTutorialProps extends WithNamespaces {
    prefix?: string;
    tutorial: Tutorial;
}

@InjectPrefix
class CardTutorial extends React.Component<CardTutorialProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  tutorial,
                  t
              }     = this.props,
              item  = tutorial.getGallery().getFirstItem(),
              image = item ? item.getImageFullPath() : ""
        ;


        return (
            <Card className={`${this.prefix}-card-promotion`}>
                <CardHeader
                    title={tutorial.getName()}
                    titleTypographyProps={{component: "span" as unknown as React.ComponentClass, variant: "h6"}}
                />
                <img src={image} className="img-fluid image-card" alt=""/>
                <CardContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component={"p" as unknown as React.ComponentClass}
                    >
                        {tutorial.getShortDescription()}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Link to={renderRoute(PageNames.tutorialDetailPage, {
                        slug: tutorial.getSlug()
                    })} className={`show-more`}>
                        {t("show-more")}
                    </Link>
                </CardActions>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardTutorial);
