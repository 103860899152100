import {
    object,
    serializable,
    custom,
}                     from "serializr";
import { observable } from "mobx";
import { Gallery }    from "../Gallery/Gallery";
import { date }       from "../../Serializer/date";
import { SeoData }    from "../Page/SeoData";

export class Tutorial {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    private name: string;

    @serializable
    @observable
    private youtubeId: string;

    @serializable
    @observable
    private description: string;

    @serializable
    @observable
    private shortDescription: string;

    @serializable
    @observable
    private slug: string;

    @serializable(object(Gallery))
    @observable
    private gallery: Gallery;

    @serializable(object(SeoData))
    @observable
    private seoData: SeoData;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getDescription(): string {
        return this.description || "";
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getGallery(): Gallery {
        return this.gallery;
    }

    public setGallery(value: Gallery) {
        this.gallery = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getYoutubeId(): string {
        return this.youtubeId;
    }

    public setYoutubeId(value: string) {
        this.youtubeId = value;
    }

    public getShortDescription(): string {
        return this.shortDescription;
    }

    public setShortDescription(value: string) {
        this.shortDescription = value;
    }

    public getSeoData(): SeoData {
        return this.seoData;
    }

    public setSeoData(value: SeoData) {
        this.seoData = value;
    }
}
