import { serializable } from "serializr";


export class CalculateShipping {
    @serializable
    private kms: number;

    @serializable
    private price: number;

    @serializable
    private priceByKm: number;

    @serializable
    private weight: number;

    public getKms(): number {
        return this.kms;
    }

    public setKms(value: number) {
        this.kms = value;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(value: number) {
        this.price = value;
    }

    public getPriceByKm(): number {
        return this.priceByKm;
    }

    public setPriceByKm(value: number) {
        this.priceByKm = value;
    }

    public getWeight(): number {
        return this.weight;
    }

    public setWeight(value: number) {
        this.weight = value;
    }
}