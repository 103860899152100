import * as React           from "react";
import CardDirection        from "../../Views/Components/Card/CardDirection";
import { Direction }        from "../../Models/User/Direction";
import { inject, observer } from "mobx-react";
import { UserStore }        from "../../Store/UserStore";
import { FormStore }        from "../../Store/FormStore";
import { observable }       from "mobx";
import InjectPrefix         from "../../Decorators/InjectPrefix";
import CardChooseDirection  from "../../Views/Components/Card/CardChooseDirection";
import { CarStore }         from "../../Store/CarStore";

interface ICardDirectionChooseViewModelProps {
    direction: Direction;
    UserStore?: UserStore;
    CarStore?: CarStore;
    prefix?: string;
}

@inject(UserStore.NAME_STORE, CarStore.NAME_STORE)
@InjectPrefix
@observer
class CardDirectionChooseViewModel extends React.Component<ICardDirectionChooseViewModelProps, {}> {
    @observable
    private sending: boolean = false;

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected setDirectionToOrder = (direction: Direction) => {
        this.carStore.getOrder().setDirection(direction);
        this.userStore.setNewDirection(false);
    };

    public render(): React.ReactNode {
        const {direction}  = this.props,
              order        = this.carStore.getOrder(),
              directionUID = order.getDirection() ? order.getDirection().getUID() : "";

        return (
            <CardChooseDirection
                direction={direction}
                directionUID={directionUID}
                setDirectionToOrder={this.setDirectionToOrder}
            />
        );
    }
}

export default CardDirectionChooseViewModel;
