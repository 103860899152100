import * as React                         from "react";
import Card                               from "@material-ui/core/Card";
import CardHeader                         from "@material-ui/core/CardHeader";
import CardContent                        from "@material-ui/core/CardContent";
import Typography                         from "@material-ui/core/Typography";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { RecommendationPerson }           from "../../../Models/RecomendationPerson/RecommendationPerson";
import { Occupation }                     from "../../../Models/Occupation/Occupation";
import { observer }                       from "mobx-react";
import { social }                         from "../../../config/social";
import { faPhone }                        from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                from "@fortawesome/react-fontawesome";
import { faWhatsapp }                     from "@fortawesome/free-brands-svg-icons";

interface CardRecommendationPersonProps extends WithNamespaces {
    prefix?: string;
    recommendationPerson: RecommendationPerson;
    occupationsMap: Map<string, Occupation>;
}

@InjectPrefix
@observer
class CardRecommendationPerson extends React.Component<CardRecommendationPersonProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  recommendationPerson,
                  occupationsMap,
                  t
              }                         = this.props,
              imageSrc                  = recommendationPerson.getImageFullPath(),
              occupations: Occupation[] = recommendationPerson.getOccupations().filter((item: string) => {
                  return occupationsMap.has(item);
              }).map((item: string) => {
                  return occupationsMap.get(item) as Occupation;
              });

        return (
            <Card className={`${this.prefix}-card-recommendation-person`}>
                <CardHeader
                    title={recommendationPerson.getName()}
                    titleTypographyProps={{component: "span" as unknown as React.ComponentClass, variant: "h6"}}
                    subheader={recommendationPerson.getDirection()}
                />
                <img src={imageSrc} className="img-fluid image-card" alt=""/>
                <CardContent>
                    <Typography variant="body2" color="textSecondary"
                                component={"p" as unknown as React.ComponentClass}>
                        <span className={"font-weight-bold"}>{t("direction")}: </span>
                        {recommendationPerson.getName()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary"
                                component={"p" as unknown as React.ComponentClass}>
                        <span className={"font-weight-bold"}>{t("occupations")}:</span>
                    </Typography>
                    <ul>
                        {occupations.map((occupation: Occupation) => {
                            return (
                                <Typography
                                    key={occupation.getId()}
                                    variant="body2"
                                    color="textSecondary"
                                    component={"li" as unknown as React.ComponentClass}>
                                    {occupation.getName()}
                                </Typography>
                            );
                        })}
                    </ul>
                    <div className="icons">
                        <a
                            target="_blank"
                            href={`https://wa.me/${recommendationPerson.getPhone()}?text=${""}`}
                            className={`${this.prefix}-senary-color mr-2`}
                        >
                            <FontAwesomeIcon icon={faWhatsapp} size={"2x"}/>
                        </a>
                        <a
                            target="_blank"
                            href={`tel:${recommendationPerson.getPhone()}`}
                            className={`${this.prefix}-senary-color`}
                        >
                            <FontAwesomeIcon icon={faPhone} size={"2x"}/>
                        </a>
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardRecommendationPerson);
