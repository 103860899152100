import { Service }    from "typedi";
import { create }     from "mobx-persist";
import { onError }    from "mobx-react";
import BaseStore      from "./Base/BaseStore";
import * as LoadStore from "./LoadStore/LoadStore";

export interface ObjectType<T> {
    new(applicationStore: ApplicationStore): T;

    NAME_STORE: string;
}

@Service()
export class ApplicationStore {
    private stores: { [key: string]: BaseStore } = {};

    public subscribe(store: ObjectType<BaseStore>) {
        this.stores[store.NAME_STORE] = new store(this);
    }

    public getStores = () => this.stores;

    public getStore<T>(store: ObjectType<T>): T {
        const storeName = store.NAME_STORE;
        const value     = this.getStores()[storeName];

        if (!value)
            throw `Error: the Store ${storeName} don't exist or not subscribed`;

        return value as unknown as T;
    }

    constructor() {
        onError(error => {
            console.log(error);
        });
        Object.values(LoadStore).map(store => this.subscribe(store));
    }

    public async initStorage() {
        const hydrate = await create();

        for (const key in this.getStores()) {
            if (this.getStores()[key].getNeedPersistData()) {
                await hydrate(key, this.getStores()[key], {});
                this.getStores()[key].validateDateStoreToClear();
            }
        }
    }

    public clearStoreData(force: boolean = false) {
        for (const key in this.getStores()) {
            if (this.getStores()[key].getNeedPersistData() || force) {
                this.getStores()[key].resetStore();
            }
        }
    }
}
