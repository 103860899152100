import * as React                    from "react";
import { Container }                 from "typedi";
import { observer }                  from "mobx-react";
import { Product }                   from "../../Models/Product/Product";
import SnackBarService               from "../../Service/SnackBarService";
import { observable }                from "mobx";
import SnackBarProductValidColorView from "../../Views/Components/SnackBar/SnackBarProductValidColorView";

interface ISnackBarProductValidColorViewModelProps {
    product: Product;
}

@observer
export class SnackBarProductValidColorViewModel extends React.Component<ISnackBarProductValidColorViewModelProps, {}> {
    @observable
    protected open = true;

    protected snackBarService: SnackBarService;

    constructor(props: ISnackBarProductValidColorViewModelProps) {
        super(props);
        this.snackBarService = Container.get(SnackBarService);
    }

    protected close = () => {
        this.snackBarService.closeSnackBar();
    };

    public render(): React.ReactNode {
        const {product} = this.props;

        return (
            <SnackBarProductValidColorView
                close={this.close}
                open={this.snackBarService.getSnackBar().getOpen()}
                product={product}
            />
        );
    }
}

export default SnackBarProductValidColorViewModel;