import {
    inject,
    observer
}                             from "mobx-react";
import * as React             from "react";
import { Product }            from "../../Models/Product/Product";
import ModalProduct           from "../../Views/Components/Modal/ModalProduct";
import { observable }         from "mobx";
import { ConfigurationStore } from "../../Store/ConfigurationStore";
import { ProductStore }       from "../../Store/ProductStore";
import { Color }              from "../../Models/Color/Color";

interface IModalProductViewModelProps {
    ConfigurationStore?: ConfigurationStore;
    ProductStore?: ProductStore;
    product: Product;
    onClose?: () => void;
}

@inject(ConfigurationStore.NAME_STORE, ProductStore.NAME_STORE)
@observer
export class ModalProductViewModel extends React.Component<IModalProductViewModelProps, {}> {
    @observable
    private fullScreen: boolean = false;

    @observable
    private productsRelationship: Product[] = [];

    @observable
    private loading: boolean = true;

    @observable
    private color: Color;

    public getColor = (): Color => {
        return this.color;
    }

    public setColor = (value: Color) => {
        this.color = value;
    }

    public get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    public get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    public getFullScreen = (): boolean => {
        return this.fullScreen;
    };

    public setFullScreen = (value: boolean) => {
        this.fullScreen = value;
    };

    constructor(props: IModalProductViewModelProps) {
        super(props);

        this.handleResizeModal();
    }

    protected handleResizeModal = () => {
        this.setFullScreen(
            (this.configurationStore.isXsScreen() || this.configurationStore.isSmScreen())
            && !this.configurationStore.isMdScreen()
        );
    };

    public componentDidMount = async () => {
        const {
                  product,
              } = this.props;
        window.addEventListener("resize", this.handleResizeModal, false);

        this.productsRelationship = await this.productStore.getProductsRelationship(product);
        this.loading              = false;
    };

    public componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResizeModal, false);
        this.props.onClose && this.props.onClose();
    };

    public render(): React.ReactNode {
        const {
                  product,
              } = this.props;

        return (
            <ModalProduct
                product={product}
                fullScreen={this.getFullScreen()}
                productsRelationship={this.productsRelationship}
                loading={this.loading}
                color={this.getColor()}
                setColor={this.setColor}
            />
        );
    }
}