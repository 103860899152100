import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import * as React                         from "react";
import { Col, Row }                       from "reactstrap";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Product }                        from "../../../Models/Product/Product";
import { CardProductViewModel }           from "../../../ViewModel/Card/CardProductViewModel";

interface ILastPromotionsProps extends WithNamespaces {
    prefix?: string;
    className?: string;
    products: Product[] | undefined;
}

@InjectPrefix
class LastProducts extends React.Component<ILastPromotionsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, products, className} = this.props;

        return (
            <>
                <div className={`w-100 text-center ${className || ""}`}>
                    <h2 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("home.last-products")}
                    </h2>
                </div>
                <Row className={`${this.prefix}-container-cards products`}>
                    {products ? products.map((product: Product, key: number) => {
                                  return (
                                      <Col xs={12} sm={4} lg={3} key={key} className="item">
                                          <CardProductViewModel
                                              product={product}
                                          />
                                      </Col>
                                  );
                              })
                              : <React.Fragment/>}
                </Row>
            </>
        );
    }
}

export default withNamespaces("page")(LastProducts);
