import React              from "react";
import {
    Drawer,
    Button,
    List,
    ListItem,
    ListItemText,
    Icon,
    Divider,
}                         from "@material-ui/core";
import { Link }           from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { withRouter }     from "react-router";
import {
    PageNames,
    renderRoute
}                         from "../../../Routes/routes";
import InjectPrefix       from "../../../Decorators/InjectPrefix";
import IBaseProps         from "../../../ViewModel/Props/IBaseProps";
import User               from "../../../Models/User/User";
import { disableShopCar } from "../../../Utils/common";

const classNames = require("classnames");

interface MenuProps extends IBaseProps {
    openMenuLeft: boolean;
    setOpenMenuLeft: (evt: boolean) => void;
    prefix?: string;
    currentPage: string;
    logout: () => void;
    user: User | undefined;
}

@InjectPrefix
class Menu extends React.Component<MenuProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) return;

        this.props.setOpenMenuLeft(open);
    };

    private registerComponent = () => {
        const {t, currentPage} = this.props;

        return (
            <>
                <ListItem
                    button key={PageNames.loginPage}
                    onClick={() => this.goTo(renderRoute(PageNames.loginPage))}
                >
                    <ListItemText primary={
                        <Link
                            to={renderRoute(PageNames.loginPage)}
                            className={classNames({active: PageNames.loginPage === currentPage})}
                        >
                            {t("global:login")}
                        </Link>
                    }/>
                </ListItem>

                <ListItem
                    button key={PageNames.registerPage}
                    onClick={() => this.goTo(renderRoute(PageNames.registerPage))}
                >
                    <ListItemText primary={
                        <Link
                            to={renderRoute(PageNames.registerPage)}
                        >
                            {t("global:register")}
                        </Link>
                    }/>
                </ListItem>
            </>
        );
    };

    private userComponent = () => {
        const {user, logout} = this.props;

        return (
            <>
                <ListItem button onClick={() => this.goTo(renderRoute(PageNames.profilePage))}>
                    <ListItemText primary={
                        <Link
                           className="active"
                           to={renderRoute(PageNames.profilePage)}
                        >
                            {user?.getFirstName()}
                        </Link>
                    }/>
                </ListItem>

                <ListItem
                    button
                    onClick={() => logout()}
                >
                    <ListItemText primary={
                        <a href="javascript:void(0);"
                           onClick={() => logout()}
                        >
                            Logout
                        </a>
                    }/>
                </ListItem>
            </>
        );
    };

    private getSideList = (): React.ReactNode => {
        const {
                  t,
                  currentPage,
                  user
              } = this.props;

        return (
            <div
                role="presentation"
                onClick={() => this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)}
                className={`${this.prefix}-header-mobile-nav`}
            >
                <Link className="image-logo" to={renderRoute(PageNames.homePage)}>
                    <img src="/assets/img/components/logo/logo.png" alt="" className="img-fluid"/>
                </Link>
                <List className="w-100">
                    <Divider/>
                    {disableShopCar() ? <React.Fragment/> :
                     (
                         <>
                             {user ? this.userComponent() : this.registerComponent()}
                             <Divider/>
                         </>
                     )
                    }
                    {[
                        PageNames.homePage,
                        PageNames.productsPage,
                        PageNames.promotionsPage,
                        PageNames.tutorialPage,
                        PageNames.whoWePage,
                        PageNames.recommendationPersonPage,
                        PageNames.contactPage,
                    ].map(item => <ListItem button key={item} onClick={() => this.goTo(renderRoute(item))}>
                        <ListItemText primary={
                            <Link
                                to={renderRoute(item)}
                                className={classNames({active: item === currentPage})}
                            >
                                {t(`menu:${item}`)}
                            </Link>
                        }/>
                    </ListItem>)}
                </List>
            </div>
        );
    };

    private goTo(route: string) {
        this.props.history.push(route);
        this.props.setOpenMenuLeft(false);
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <>
                <Drawer open={this.props.openMenuLeft} onClose={this.toggleDrawer(false)}>
                    {this.getSideList()}
                </Drawer>
                <Button onClick={this.toggleDrawer(true)} className={""}>
                    <Icon>menu</Icon>
                </Button>
            </>
        );
    }
}

export default withRouter(withNamespaces("header")(Menu));
