import * as React                   from "react";
import { Button, Container, }       from "@material-ui/core";
import { withRouter }               from "react-router-dom";
import { withNamespaces }           from "react-i18next";
import { ModalLayout }              from "../Layout/ModalLayout";
import InjectPrefix                 from "../../../Decorators/InjectPrefix";
import { observer }                 from "mobx-react";
import ModalService                 from "../../../Service/ModalService";
import { Container as ContainerDI } from "typedi";
import { Order }                    from "../../../Models/Order/Order";
import IBaseProps                   from "../../../ViewModel/Props/IBaseProps";
import { PageNames, renderRoute }   from "../../../Routes/routes";

interface IModalGenerateOrderSuccessProps extends IBaseProps {
    order: Order;
    closeModal?: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class ModalGenerateOrderSuccess extends React.Component<IModalGenerateOrderSuccessProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected closeModal = () => {
        this.props.history.push(renderRoute(PageNames.myOrdersPage));

        if (this.props.closeModal)
            this.props.closeModal();
    };

    public render(): React.ReactNode {
        const {
                  t,
              } = this.props;

        return (
            <ModalLayout
                className={`${this.prefix}-modal-added-product`}
                closeButton={true}
                maxWidth="xl"
                closeModal={this.closeModal}
                buttons={[
                    <Button
                        key={1}
                        color="default"
                        onClick={() => {
                            ContainerDI.get(ModalService).closeModal();
                            this.closeModal();
                        }}
                    >
                        Cerrar
                    </Button>
                ]}
            >
                <Container>
                    <h5 className={"title"}>{t("order.new-order")}</h5>
                    <p>{t("order.new-order-content")}</p>
                </Container>
            </ModalLayout>
        );
    }
}

export default withRouter(withNamespaces("page")(ModalGenerateOrderSuccess));