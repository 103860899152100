import { observable }   from "mobx";
import { serializable } from "serializr";
import uuid             from "uuid";

export interface IImageSelect {
    base64: string;
    file: File;
}

export class GalleryItem {
    @serializable
    @observable
    private title: string;

    @serializable
    @observable
    private key: string;

    @serializable
    @observable
    private subtitle: string;

    @serializable
    @observable
    private image: string;

    @observable
    private imageSelect: IImageSelect;

    @serializable
    private order: number;

    constructor() {
        this.setKey(uuid());
    }

    public getTitle(): string {
        return this.title || "";
    }

    public setTitle(value: string) {
        this.title = value;
    }

    public getKey(): string {
        return this.key;
    }

    public setKey(value: string) {
        this.key = value;
    }

    public getSubtitle(): string {
        return this.subtitle || "";
    }

    public setSubtitle(value: string) {
        this.subtitle = value || "";
    }

    public getImage(): string {
        return this.image;
    }

    public getImageFullPath(): string {
        return this.image ? `${process.env.REACT_APP_ENDPOINT_BACKEND}uploads/gallery/${this.image}` : "";
    }

    public setImage(value: string) {
        this.image = value;
    }

    public getOrder(): number {
        return this.order;
    }

    public setOrder(value: number) {
        this.order = value;
    }

    public getImageSelect(): IImageSelect {
        return this.imageSelect;
    }

    public setImageSelect(value: IImageSelect) {
        this.imageSelect = value;
    }
}
