import * as React              from "react";
import InjectPrefix            from "../../../Decorators/InjectPrefix";
import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import {
    faWaze,
    faWhatsapp,
    faFacebook
}                              from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { social }              from "../../../config/social";

interface SocialProps {
    prefix?: string;
    colorIcons?: string;
    text?: string;
    noWaze?: boolean;
}

@InjectPrefix
export class Social extends React.Component<SocialProps, {}> {

    private readonly icons: React.ReactNode[];

    constructor(props: SocialProps) {
        super(props);
        const text   = encodeURI(props.text || ""),
              noWaze = props.noWaze;
        this.icons   = [
            {
                href: `https://wa.me/${social.whatsApp}?text=${text}`,
                icon: <FontAwesomeIcon icon={faWhatsapp}/>,
            },
            {
                href: `mailto:${social.email}?body=${text}`,
                icon: <FontAwesomeIcon icon={faEnvelope}/>,
            },
            {
                href: `tel:${social.phone}`,
                icon: <FontAwesomeIcon icon={faPhone}/>,
            },
            {
                href: `https://www.facebook.com/laferreteriadelosescazucenos`,
                icon: <FontAwesomeIcon icon={faFacebook}/>,
            },
        ];

        if (!noWaze) {
            this.icons.splice(2, 0, {
                href: `https://waze.com/ul?ll=${social.lat},${social.lng}&z=10`,
                icon: <FontAwesomeIcon icon={faWaze}/>,
            });
        }

        this.icons = this.icons.map((item: any, key: number) => (
            <a
                key={key}
                target="_blank"
                href={item.href}
                className={props.colorIcons || `${this.prefix}-quaternary-color`}
            >
                {item.icon}
            </a>
        ));
    }

    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <div className={`${this.prefix}-social`}>
                {this.icons}
            </div>
        );
    }
}
