import { action, observable } from "mobx";
import { persist }            from "mobx-persist";
import { deserialize }        from "serializr";
import { configuration }      from "../mockdata/configuration";
import Configuration          from "../Models/Configuration/Configuration";
import BaseStore              from "./Base/BaseStore";
import scssConfiguration      from "../resources/generator/scssConfiguration";

export class ConfigurationStore extends BaseStore {
    public static readonly NAME_STORE: string = "ConfigurationStore";

    @observable
    private currentRolePage: string;

    @observable
    private currentPage: string;

    @persist("object", Configuration)
    @observable
    private configuration: Configuration;

    @observable
    private sizeWidth: number = 0;

    @persist("object")
    @observable
    protected createdStoreAt: Date;

    protected init() {
        this.needPersistData = true;
        const config         = deserialize(Configuration, configuration).setShowHeader(true).setShowFooter(true);

        this.setConfiguration(config);
    }

    public resetStore() {
    }

    @action
    public getConfiguration(): Configuration {
        return this.configuration;
    }

    @action
    public setConfiguration(value: Configuration) {
        this.configuration = value;

        return this;
    }

    public getCurrentRolePage(): string {
        return this.currentRolePage;
    }

    public setCurrentRolePage(val: string): this {
        this.currentRolePage = val;

        return this;
    }

    public getCurrentPage(): string {
        return this.currentPage;
    }

    public setCurrentPage(val: string): this {
        this.currentPage = val;

        return this;
    }

    public getSizeWidth(): number {
        return this.sizeWidth;
    }

    public setSizeWidth(val: number): this {
        this.sizeWidth = val;

        return this;
    }

    public isXsScreen = (): boolean => {
        return this.getSizeWidth() < scssConfiguration.screenSmDevices;
    };

    public isLgScreen = (): boolean => {
        return this.getSizeWidth() > scssConfiguration.screenLgDevices;
    };

    public isMdScreen = (): boolean => {
        return this.getSizeWidth() > scssConfiguration.screenMdDevices;
    };

    public isSmScreen = (): boolean => {
        return this.getSizeWidth() > scssConfiguration.screenSmDevices;
    };

    public isXlScreen = (): boolean => {
        return this.getSizeWidth() > scssConfiguration.screenXlDevices;
    };
}
