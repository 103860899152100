import * as React           from "react";
import { inject, observer } from "mobx-react";
import { PageStore }        from "../../Store/PageStore";
import { UserStore }        from "../../Store/UserStore";
import User                 from "../../Models/User/User";
import MyDirectionsView     from "../../Views/Pages/User/MyDirectionsView";

interface IMyDirectionsViewModelProps {
    PageStore?: PageStore;
    UserStore?: UserStore;
}

@inject(UserStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class MyDirectionsViewModel extends React.Component<IMyDirectionsViewModelProps, {}> {
    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public render(): React.ReactNode {
        return (
            <MyDirectionsView
                user={this.userStore.getUser() as User}
            />
        );
    }
}

export default MyDirectionsViewModel;