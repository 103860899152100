import * as React                                                       from "react";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { googleCredentials }                                            from "../../../config/GoogleCredentials";
import FacebookLogin, { ReactFacebookLoginInfo }                        from "react-facebook-login";
import { withNamespaces, WithNamespaces }                               from "react-i18next";
import InjectPrefix                                                     from "../../../Decorators/InjectPrefix";
import { observer }                                                     from "mobx-react";
import { facebookCredentials }                                          from "../../../config/FacebookCredentials";

interface ISocialLoginProps extends WithNamespaces {
    prefix?: string;
    onSuccessGoogle: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
    onSuccessFacebook: (userInfo: ReactFacebookLoginInfo) => void;
}

@InjectPrefix
@observer
class SocialLogin extends React.Component<ISocialLoginProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  onSuccessGoogle,
                  onSuccessFacebook
              } = this.props;

        return (
            <div className={`${this.prefix}-social-login`}>
                <GoogleLogin
                    clientId={googleCredentials.clientId}
                    buttonText={t("global:login-google")}
                    onSuccess={onSuccessGoogle}
                    cookiePolicy={"single_host_origin"}
                    onFailure={(error: any) => {
                        console.log("error", error);
                    }}
                />
                <FacebookLogin
                    appId={facebookCredentials.appId}
                    autoLoad={false}
                    fields="name,email,picture"
                    icon="fa-facebook"
                    cookie={true}
                    textButton={t("global:login-google")}
                    callback={onSuccessFacebook}
                    // @ts-ignore
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {}}
                />
            </div>
        );
    }
}

export default withNamespaces("global")(SocialLogin);