import {
    custom,
    list,
    object,
    serializable,
    raw
}               from "serializr";
import { date } from "../../Serializer/date";

export class Category {
    @serializable
    private _id: string;

    @serializable
    private name: string;

    @serializable
    private slug: string;

    @serializable
    private categoryId: string;

    @serializable(list(object(Category)))
    private categories: Category[] = [];

    @serializable
    private order: number;

    @serializable(raw())
    private style: any;

    @serializable(custom(date.serializer, date.deserializer))
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    private updatedAt: Date;


    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getCategoryId(): string {
        return this.categoryId;
    }

    public setCategoryId(value: string) {
        this.categoryId = value;
    }

    public getCategories(): Category[] {
        return this.categories;
    }

    public setCategories(value: Category[]) {
        this.categories = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getOrder(): number {
        return this.order;
    }

    public setOrder(value: number) {
        this.order = value;
    }

    public getStyle(): any {
        return this.style || {};
    }

    public setStyle(value: any) {
        this.style = value;
    }
}
