import BaseStore        from "./Base/BaseStore";
import { Gallery }      from "../Models/Gallery/Gallery";
import { galleryPages } from "../config/GalleryPages";
import AjaxService      from "../Service/AjaxService";
import Container        from "typedi";
import { deserialize }  from "serializr";
import { observable }   from "mobx";
import { GalleryItem }  from "../Models/Gallery/GalleryItem";

export class GalleryStore extends BaseStore {
    public static readonly NAME_STORE: string = "GalleryStore";

    private galleries: Map<string, Gallery> = new Map<string, Gallery>();

    @observable
    private currentImage: GalleryItem;

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public setCurrentImage = (image: GalleryItem) => {
        this.currentImage = image;
    };

    public getCurrentImage = () => {
        return this.currentImage;
    };

    init() {
        this.needPersistData = false;
    }

    public async getGalleryPage(galleryPage: galleryPages): Promise<Gallery> {
        if (!this.galleries.has(galleryPage)) {
            const response = await this.getAjaxService().getGalleryPage(galleryPage);

            if (response.data.data) {
                const gallery = deserialize(Gallery, response.data.data);

                this.galleries.set(galleryPage, gallery);
            }
        }

        return this.galleries.get(galleryPage) as Gallery;
    }
}
