import { inject, observer }      from "mobx-react";
import * as React                from "react";
import { CarStore }              from "../../Store/CarStore";
import { UserStore }             from "../../Store/UserStore";
import User                      from "../../Models/User/User";
import ChooseMethodTransportView from "../../Views/Pages/Car/ChooseMethodTransportView";
import { MethodTransport }       from "../../Models/MethodTransport/MethodTransport";
import { reaction }              from "mobx";
import { CalculateShipping }     from "../../Models/CalculateShipping/CalculateShipping";

interface IChooseMethodTransportViewModelProps {
    CarStore?: CarStore;
    UserStore?: UserStore;
    purgeValidator?: () => void;
    prefix?: string;
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class ChooseMethodTransportViewModel extends React.Component<IChooseMethodTransportViewModelProps, {}> {
    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    public componentDidMount = async () => {
        await this.carStore.initMethodTransports();
        this.setMethodTransportMinimum();

        reaction(
            () => this.carStore.getProductStore().map(item => item.getQuantity()),
            this.setMethodTransportMinimum
        );

        reaction(
            () => this.carStore.getOrder().getCalculateShipping(),
            this.setMethodTransportMinimum
        );

        reaction(
            () => this.carStore.getOrder().getDirection(),
            this.setMethodTransportMinimum
        );
    };

    protected setMethodTransportMinimum = () => {
        const minWight                = this.carStore.getTotalWeight(this.carStore.getProductStore()),
              methodTransportSelected = this.carStore.getOrder().getMethodTransport(),
              distance                = this.carStore.getOrder().getDistance(),
              direction               = this.carStore.getOrder().getDirection();

        if (methodTransportSelected && (methodTransportSelected.getPickUp() || methodTransportSelected.isOptionValid(minWight, distance))) {
            return;
        }

        for (const item of this.carStore.getMethodTransports()) {
            const valid = direction && item.getMinWeight() >= minWight && item.isValidDistance(distance);

            if (valid) {
                this.carStore.getOrder().setMethodTransport(item);
                break;
            }

            if (item.getPickUp()) {
                this.carStore.getOrder().setMethodTransport(item);
                break;
            }
        }

        this.forceUpdate();
    };

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected onChangeMethodTransport = (val: MethodTransport) => {
        const {purgeValidator} = this.props;
        this.carStore.getOrder().setMethodTransport(val);

        if (val.getPickUp()) {
            // @ts-ignore
            this.carStore.getOrder().setDirection(undefined);
            this.carStore.getOrder().setCalculateShipping(undefined as unknown as CalculateShipping);
        }

        if (purgeValidator)
            purgeValidator();
    };

    public render(): React.ReactNode {
        const {} = this.props;

        return (
            <ChooseMethodTransportView
                methodTransports={this.carStore.getMethodTransports()}
                order={this.carStore.getOrder()}
                user={this.userStore.getUser() as User}
                onChangeMethodTransport={this.onChangeMethodTransport}
                minWight={this.carStore.getTotalWeight(this.carStore.getProductStore())}
            />
        );
    }
}

export default ChooseMethodTransportViewModel;