import * as React                         from "react";
import { Button, Card }                   from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Direction }                      from "../../../Models/User/Direction";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import { BillElectronic }                 from "../../../Models/User/BillElectronic";

interface ICardChooseDirectionProps extends WithNamespaces {
    billElectronic: BillElectronic;
    billElectronicUID: string;
    setBillElectronicToOrder: (billElectronic: BillElectronic) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class CardChooseBillElectronic extends React.Component<ICardChooseDirectionProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  billElectronic,
                  setBillElectronicToOrder,
                  billElectronicUID,
              }          = this.props,
              isSelected = billElectronic.getUID() === billElectronicUID;

        return (
            <Card variant="outlined" className={`card ${this.prefix}-card-direction ${isSelected ? "active" : ""}`}>
                <div className="content">
                    <p className="font-weight-bold">{billElectronic.getFullName()}</p>
                    <ul className="list-unstyled">
                        <li>{t(`dni:${billElectronic.getTypeDni()}`)}</li>
                        <li>{billElectronic.getDni()}</li>
                        <li>{billElectronic.getEmail()}</li>
                        <li>{billElectronic.getPhone()}</li>
                        <li>{billElectronic.getDirection()}</li>
                    </ul>
                    <div className="options">
                        <Button
                            className="btn-primary"
                            color="primary"
                            variant="contained"
                            onClick={() => setBillElectronicToOrder(billElectronic)}
                        >
                            {t(isSelected ? "selected" : "select")}
                        </Button>
                    </div>
                </div>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardChooseBillElectronic);
