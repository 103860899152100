import * as React                         from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Tutorial }                       from "../../../Models/Tutorial/Tutorial";

interface TutorialDetailPresentationProps extends WithNamespaces {
    prefix?: string;
    tutorial: Tutorial;
}


@InjectPrefix
class TutorialDetailPresentation extends React.Component<TutorialDetailPresentationProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {tutorial} = this.props;

        return (
            <div className="mb-5">
                <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                    {tutorial.getName()}
                </h1>
            </div>
        );
    }

}

export default withNamespaces("page")(TutorialDetailPresentation);
