import InjectPrefix                 from "../../Decorators/InjectPrefix";
import { observer }                 from "mobx-react";
import * as React                   from "react";
import { Container as ContainerDI } from "typedi";
import ModalService                 from "../../Service/ModalService";
import { IconButton }               from "@material-ui/core";
import { Close as CloseIcon }       from "@material-ui/icons";

interface IModalHeaderProps {
    prefix?: string;
}

@InjectPrefix
@observer
class ModalHeader extends React.Component<IModalHeaderProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public closeModal = () => {
        ContainerDI.get(ModalService).closeModal();
    };

    public render(): React.ReactNode {
        return (
            <div
                className={`${this.prefix}-modal-header`}
            >
                <div className="logo">
                    <img src="/assets/img/components/logo/logo.png" alt="" className="img-fluid"/>
                </div>
                <div className="close-button">
                    <IconButton aria-label="close" onClick={this.closeModal} className="icon-close">
                        <CloseIcon/>
                    </IconButton>
                </div>
            </div>
        );
    }

}

export default ModalHeader;
