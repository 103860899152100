import InjectPrefix       from "../../../Decorators/InjectPrefix";
import { observer }       from "mobx-react";
import * as React         from "react";
import { Order }          from "../../../Models/Order/Order";
import {
    withNamespaces,
    WithNamespaces
}                         from "react-i18next";
import {
    Col,
    Row
}                         from "reactstrap";
import { MethodsPay }     from "../../../config/MethodsPay";
import NoteMethodTransfer from "../NoteMethodTransfer/NoteMethodTransfer";
import { Direction }      from "../../../Models/User/Direction";

interface IDetailsOrderProps extends WithNamespaces {
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
export class DetailsOrder extends React.Component<IDetailsOrderProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderDirection = (direction: Direction): React.ReactNode => {
        const {t} = this.props;
        return (
            <Col xs={12} sm={4}>
                <h5>Direccion de envio</h5>
                <ul className="list-details">
                    <li>
                        <span className="title">{t("forms:fullName")}:</span> {direction.getFullName()}
                    </li>
                    <li>
                        <span className="title">{t("forms:province")}:</span> {direction.getProvincie()}
                    </li>
                    <li>
                        <span className="title">{t("forms:canton")}:</span> {direction.getCanton()}
                    </li>
                    <li>
                        <span className="title">{t("forms:phone")}:</span> {direction.getPhone()}
                    </li>
                    <li>
                        <span className="title">{t("forms:exact-direction")}:</span> {direction.getDirection()}
                    </li>
                </ul>
            </Col>
        );
    };

    protected renderBillElectronic = (): React.ReactNode => {
        const {t, order}     = this.props,
              billElectronic = order.getBillElectronic();

        if (!billElectronic) return <React.Fragment/>;

        return (
            <Col xs={12}>
                <h5>Datos Facturacion Electronica</h5>
                <ul className="list-details">
                    <li>
                        <span className="title">{t("forms:type-dni")}:</span> {t(`dni:${billElectronic.getTypeDni()}`)}
                    </li>
                    <li>
                        <span className="title">{t("forms:dni")}:</span> {billElectronic.getDni()}
                    </li>
                    <li>
                        <span className="title">{t("forms:fullName")}:</span> {billElectronic.getFullName()}
                    </li>
                    <li>
                        <span className="title">{t("forms:phone")}:</span> {billElectronic.getPhone()}
                    </li>
                    <li>
                        <span className="title">{t("forms:exact-direction")}:</span> {billElectronic.getDirection()}
                    </li>
                </ul>
            </Col>
        );
    };

    public render(): React.ReactNode {
        const {
                  order,
                  t,
              }         = this.props,
              direction = order.getMethodTransport().getPickUp() ? undefined : order.getDirection();

        return (
            <Row className={`${this.prefix}-c-details-order`}>
                {direction ? this.renderDirection(direction) : ""}
                <Col xs={12} sm={direction ? 4 : 6}>
                    <h5>Metodo de Pago</h5>
                    <p>{t(`methods-pay:${order.getMethodPay()}`)}</p>
                    {order.getMethodPay() === MethodsPay.TRANSFER ? <NoteMethodTransfer/> : <React.Fragment/>}
                </Col>
                <Col xs={12} sm={direction ? 4 : 6}>
                    <h5>Metodo de Transporte</h5>
                    <p>{order.getMethodTransport().getName()}</p>
                </Col>
                {order.getIsBillElectronic() ? this.renderBillElectronic() : <React.Fragment/>}
            </Row>
        );
    }
}

export default withNamespaces("page")(DetailsOrder);
