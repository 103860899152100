import { inject, observer }     from "mobx-react";
import * as React               from "react";
import { CarStore }             from "../../Store/CarStore";
import { UserStore }            from "../../Store/UserStore";
import User                     from "../../Models/User/User";
import ChooseBillElectronicView from "../../Views/Pages/Car/ChooseBillElectronicView";
import { BillElectronic }       from "../../Models/User/BillElectronic";

interface IChooseDirectionViewModelProps {
    CarStore?: CarStore;
    UserStore?: UserStore;
    prefix?: string;
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class ChooseBillElectronicViewModel extends React.Component<IChooseDirectionViewModelProps, {}> {
    constructor(props: IChooseDirectionViewModelProps) {
        super(props);
        this.userStore.setNewBillElectronic(false);
        const user = this.userStore.getUser() as User;

        if (!this.carStore.getOrder().getBillElectronic())
            user.getBillElectronics().forEach((item: BillElectronic) => {
                if (item.getPrincipal()) {
                    this.carStore.getOrder().setBillElectronic(item);
                }
            });
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    public render(): React.ReactNode {
        const {} = this.props;

        return (
            <ChooseBillElectronicView
                user={this.userStore.getUser() as User}
                newBillElectronic={this.userStore.getNewBillElectronic()}
                setNewBillElectronic={this.userStore.setNewBillElectronic}
                order={this.carStore.getOrder()}
            />
        );
    }
}

export default ChooseBillElectronicViewModel;