import moment from "moment";

moment.locale("es");

export default {
    calendarDate : "DD-MM-YYYY",
    monthDay     : "D MMM",
    shortDate    : "D MMM, YY",
    shortDateYear: "D [de] MMMM [del] YYYY"
};
