import InjectPrefix                         from "../../../Decorators/InjectPrefix";
import { observer }                         from "mobx-react";
import * as React                           from "react";
import { TextField, Button }                from "@material-ui/core";
import { ShoppingCart as ShoppingCartIcon } from "@material-ui/icons";
import { disableShopCar }                   from "../../../Utils/common";
import { withNamespaces, WithNamespaces }   from "react-i18next";

interface IAddCarProps extends WithNamespaces {
    inStock: boolean;
    value: number;
    setValue: (val: number) => void;
    action: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class AddCar extends React.Component<IAddCarProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected addSubtract = (add: boolean) => {
        const {value, setValue} = this.props;

        if (add) {
            setValue(value + 1);
            return;
        }

        setValue((value - 1) < 0 ? 0 : (value - 1));
    };

    public render(): React.ReactNode {
        const {
                  value,
                  setValue,
                  action,
                  inStock,
                  t,
              } = this.props;

        return (
            <div className={`${this.prefix}-add-car`}>
                <div className="counter">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={`quantity-change ${inStock ? "" : "disabled"}`}
                        onClick={() => inStock ? this.addSubtract(false) : 1}
                        disabled={!inStock}
                    >
                        -
                    </Button>
                    <TextField
                        label={false}
                        variant="filled"
                        value={value}
                        type="text"
                        className={`input-value ${inStock ? "" : "disabled"}`}
                        disabled={!inStock}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            if (!inStock) return;

                            setValue(
                                parseInt(
                                    evt.target.value.replace(/\D/, ""),
                                    10
                                ) || 0
                            );
                        }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        className={`quantity-change ${inStock ? "" : "disabled"}`}
                        onClick={() => inStock ? this.addSubtract(true) : 1}
                        disabled={!inStock}
                    >
                        +
                    </Button>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className={`button-add ${inStock ? "" : "disabled"}`}
                    onClick={() => inStock ? action() : 1} disabled={!inStock}

                >
                    <ShoppingCartIcon/> {t("add")}
                </Button>
            </div>
        );
    }
}

export default withNamespaces("car")(AddCar);