import * as React           from "react";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Box,
}                           from "@material-ui/core";
import {
    withNamespaces,
    WithNamespaces
}                           from "react-i18next";
import InjectPrefix         from "../../../Decorators/InjectPrefix";
import { Product }          from "../../../Models/Product/Product";
import AddCarViewModel      from "../../../ViewModel/AddCar/AddCarViewModel";
import NumberFormat         from "../NumberFormat/NumberFormat";

interface ICardProductProps extends WithNamespaces {
    showProduct: (product: Product) => void;
    product: Product;
    isOpenProduct?: boolean;
    prefix?: string;
}

@InjectPrefix
class CardProduct extends React.Component<ICardProductProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderNoStock = () => {
        const {t} = this.props;

        return (<span className="out-stock">{t("car:out-stock")}</span>);
    };

    public render(): React.ReactNode {
        const {
                  showProduct,
                  t,
                  product,
                  isOpenProduct,
              }        = this.props,
              imageSrc = product.getImageFullPath();

        return (
            <Card className={`${this.prefix}-card-product ${isOpenProduct ? "is-open" : ""}`}>
                {!product.getInStock() ? this.renderNoStock() : <React.Fragment/>}
                <div className="image-content">
                    <img src={imageSrc} className="img-fluid image-card" alt=""/>
                </div>
                <CardHeader
                    title={product.getName()}
                    titleTypographyProps={{component: "span" as unknown as React.ComponentClass, variant: "h6"}}
                    subheader={`${product.getBrand()} - ${product.getCode()}`}
                    className="header-content text-break"
                />
                <CardContent
                    className="card-content"
                >
                    <NumberFormat
                        value={product.getPrice()}
                        renderText={(formattedValue: string) =>
                            <Box component={"p"} className="price" fontWeight="fontWeightBold">
                                {formattedValue}
                            </Box>
                        }
                    />
                    <AddCarViewModel
                        product={product}
                        openModal
                    />
                </CardContent>
                <CardActions>
                    <a
                        href="javascript:void(0)"
                        onClick={() => {
                            showProduct(product);
                        }}
                        className={`show-more`}
                    >
                        {t("show-more")}
                    </a>
                </CardActions>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardProduct);
