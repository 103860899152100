import * as React                   from "react";
import { Route as RouteRouter }     from "react-router";
import { Container }                from "typedi";
import { MiddlewareHandlerService } from "../../../Service/MiddlewareHandlerService";
import { AuthMiddleware }           from "../../../Middleware/AuthMiddleware";
import { inject }                   from "mobx-react";
import { ConfigurationStore }       from "../../../Store/ConfigurationStore";
import BaseMiddleware               from "../../../Middleware/Base/BaseMiddleware";
import ReactGA                      from "react-ga";

@inject(ConfigurationStore.NAME_STORE)
export default class Route extends React.Component<any> {
    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    public resetFooterHeader() {
        this.configurationStore
            .getConfiguration()
            .setShowFooter(true)
            .setShowHeader(true)
            .setShowMenuLogo(true);
    }

    public constructor(props: any) {
        super(props);

        this.resetFooterHeader();
    }

    public componentDidMount(): void {
        const options = this.props.options || {},
              {name}  = this.props;

        this.configurationStore.setCurrentRolePage(options.currentRole || "")
            .setCurrentPage(name);
        window.scrollTo(0, 0);
    }

    render() {
        const {auth, component: COMPONENT, middleware, name, ...rest} = this.props,
              middlewareService                                       = Container.get(MiddlewareHandlerService),
              authMiddleware                                          = middlewareService.getMiddleware(AuthMiddleware.getAlias());
        let {options}                                                 = this.props;
        ReactGA.pageview(location.pathname);

        return (
            <RouteRouter {...rest} key={name} render={(props) => {
                if (auth) {
                    // validate if logged
                    if (!authMiddleware.validate())
                        return authMiddleware.reject(props);
                }

                if (middleware) {
                    options = options || {};
                    for (const item of middleware) {
                        if ((item as BaseMiddleware).setMatch)
                            (item as BaseMiddleware).setMatch(props.match);

                        const valid = (item as BaseMiddleware).validate(options);

                        if (!valid) {
                            return (item as BaseMiddleware).reject(props);
                        }
                    }
                }

                return <COMPONENT {...props} key={name}/>;
            }}/>
        );
    }
}
