import * as React              from "react";
import { GalleryList }         from "../../Views/Components/Gallery/GalleryList";
import { Container }           from "typedi";
import ModalService            from "../../Service/ModalService";
import { ModalImageViewModel } from "../Modal/ModalImageViewModel";
import { Gallery }             from "../../Models/Gallery/Gallery";
import { GalleryItem }         from "../../Models/Gallery/GalleryItem";

interface GalleryListProps {
    gallery: Gallery | undefined;
    className?: string;
}

export class GalleryListViewModel extends React.Component<GalleryListProps, {}> {
    private onClickImage = (image: GalleryItem, position: number) => {
        const {gallery} = this.props;
        Container.get(ModalService).openModal(ModalImageViewModel, {
            image,
            images: gallery ? gallery.getItems() : [],
            position
        });
    };

    public render(): React.ReactNode {
        const {
                  gallery,
                  className
              } = this.props;

        return (
            <GalleryList
                className={className}
                gallery={gallery}
                onClickImage={this.onClickImage}
            />
        );
    }
}
