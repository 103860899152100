import * as React                   from "react";
import {
    Box,
    Container,
    Breadcrumbs,
    Typography,
}                                   from "@material-ui/core";
import { ModalLayout }              from "../Layout/ModalLayout";
import InjectPrefix                 from "../../../Decorators/InjectPrefix";
import { Product }                  from "../../../Models/Product/Product";
import { observer }                 from "mobx-react";
import ModalHeader                  from "../../../ViewModel/Modal/ModalHeader";
import {
    withNamespaces,
    WithNamespaces
}                                   from "react-i18next";
import AddCarViewModel              from "../../../ViewModel/AddCar/AddCarViewModel";
import ProductRelationshipViewModel from "../../../ViewModel/Products/ProductRelationshipViewModel";
import NumberFormat                 from "../NumberFormat/NumberFormat";
import ColorPickerViewModel         from "../../../ViewModel/ColorPicker/ColorPickerViewModel";
import { Color }                    from "../../../Models/Color/Color";

interface IModalProductProps extends WithNamespaces {
    product: Product;
    productsRelationship: Product[];
    loading: boolean;
    color: Color;
    setColor: (val: Color) => void;
    fullScreen: boolean;
    prefix?: string;
}

@InjectPrefix
@observer
class ModalProduct extends React.Component<IModalProductProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  product,
                  t,
                  fullScreen,
                  productsRelationship,
                  loading,
                  color,
                  setColor
              }        = this.props,
              imageSrc = product.getImageFullPath();

        return (
            <ModalLayout
                className={`${this.prefix}-modal-product`}
                fullScreen={fullScreen}
                closeButton={!fullScreen}
                maxWidth="xl"
            >
                {
                    fullScreen
                        ? <ModalHeader/>
                        : <React.Fragment/>
                }
                <Container>
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-modal">
                        <Typography color="inherit">
                            {product.getSubCategory().getName()}
                        </Typography>
                        <Typography color="textPrimary">
                            {product.getCategory().getName()}
                        </Typography>
                    </Breadcrumbs>
                    <h6 className="brand">
                        {product.getBrand()}
                    </h6>
                    <h3 className="name">
                        {product.getName()}
                    </h3>
                    <div className="image-container">
                        <img src={imageSrc} className="img-fluid image" alt=""/>
                    </div>
                    <NumberFormat
                        value={product.getPrice()}
                        renderText={(formattedValue: string) =>
                            <Box component={"p"} className="price" fontWeight="fontWeightBold">
                                {t("price")}: {formattedValue}
                            </Box>
                        }
                    />
                    <ColorPickerViewModel
                        color={color}
                        setColor={setColor}
                        product={product}
                        className="mb-3"
                    />
                    <AddCarViewModel
                        product={product}
                        color={color}
                    />
                    <hr/>
                    {
                        loading
                            ? <React.Fragment/>
                            : <ProductRelationshipViewModel
                                productsRelationship={productsRelationship}
                                product={product}
                            />
                    }
                    <p>
                        {product.getDescription()}
                    </p>
                </Container>
            </ModalLayout>
        );
    }
}

export default withNamespaces("global")(ModalProduct);