import * as React           from "react";
import { inject, observer } from "mobx-react";
import { UserStore }        from "../../Store/UserStore";
import { FormStore }        from "../../Store/FormStore";
import { observable }       from "mobx";
import InjectPrefix         from "../../Decorators/InjectPrefix";
import { BillElectronic }   from "../../Models/User/BillElectronic";
import CardBillElectronic   from "../../Views/Components/Card/CardBillElectronic";

interface ICardDirectionViewModelProps {
    billElectronic: BillElectronic;
    UserStore?: UserStore;
    FormStore?: FormStore;
    prefix?: string;
}

@inject(UserStore.NAME_STORE, FormStore.NAME_STORE)
@InjectPrefix
@observer
class CardBillElectronicViewModel extends React.Component<ICardDirectionViewModelProps, {}> {
    @observable
    private sending: boolean = false;

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected setPrincipal = async () => {
        if (this.sending) return;

        const {billElectronic} = this.props;
        billElectronic.setPrincipal(true);

        this.sending = true;
        await this.userStore.updateBillElectronic(billElectronic);
        this.sending = false;
    };

    protected edit = () => {
        if (this.sending) return;

        const {billElectronic} = this.props;

        this.sending = true;
        this.formStore.setBillElectronic(billElectronic);
        this.sending = false;

        const item     = `${this.prefix}-form-contact-us`,
              itemNode = document.getElementsByClassName(item)[0] as HTMLElement;

        if (itemNode) {
            scrollTo({
                         behavior: "smooth",
                         left    : 0,
                         top     : itemNode.offsetTop - 130,
                     });
        }
    };

    protected deleteItem = async () => {
        if (this.sending) return;

        const {billElectronic} = this.props;

        this.sending = true;
        await this.userStore.deleteBillElectronic(billElectronic);
        this.sending = false;
    };

    public render(): React.ReactNode {
        const {billElectronic} = this.props;

        return (
            <CardBillElectronic
                billElectronic={billElectronic}
                setPrincipal={this.setPrincipal}
                deleteItem={this.deleteItem}
                edit={this.edit}
            />
        );
    }
}

export default CardBillElectronicViewModel;
