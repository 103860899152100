import * as React                         from "react";
import { inject, observer }               from "mobx-react";
import { observable }                     from "mobx";
import { UserStore }                      from "../../Store/UserStore";
import FormDirectionView                  from "../../Views/Components/Form/FormDirectionView";
import { Direction }                      from "../../Models/User/Direction";
import { ERROR_CLASS, FormStore }         from "../../Store/FormStore";
import SimpleReactValidator               from "simple-react-validator";
import { scrollToElement }                from "../../Utils/common";
import { withNamespaces, WithNamespaces } from "react-i18next";

interface IFormDirectionViewModelProps extends WithNamespaces {
    direction?: Direction;
    UserStore?: UserStore;
    FormStore?: FormStore;
}

@inject(UserStore.NAME_STORE, FormStore.NAME_STORE)
@observer
class FormDirectionViewModel extends React.Component<IFormDirectionViewModelProps, {}> {
    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private send: boolean = false;

    @observable
    private sending: boolean = false;

    private errorDOMElement: HTMLElement | null;

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    private scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) scrollToElement(this.errorDOMElement, 1000, 180);
    };

    constructor(props: IFormDirectionViewModelProps) {
        super(props);
        const {t} = this.props;
        this.formStore.initDirection(props.direction);
        this.validator = this.formStore.getNewValidator(t);
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    private setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    private submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.scrollOnError();
            });

            return;
        }

        this.sending = true;

        if (this.formStore.getDirection().getUID())
            await this.userStore.updateDirection(this.formStore.getDirection());
        else
            await this.userStore.postDirection(this.formStore.getDirection());

        this.send    = true;
        this.sending = false;
        setTimeout(() => {
            scrollTo({
                         behavior: "smooth",
                         left    : 0,
                         top     : 0
                     });
        }, 800);

        setTimeout(() => {
            this.validator.hideMessages();
            this.formStore.initDirection();
            this.send = false;
            this.forceUpdate();
        }, 3000);
    };

    public render(): React.ReactNode {
        return (
            <FormDirectionView
                direction={this.formStore.getDirection()}
                validator={this.validator}
                submit={this.submit}
                send={this.send}
                sending={this.sending}
            />
        );
    }
}

export default withNamespaces("forms")(FormDirectionViewModel);
