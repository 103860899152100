import * as React      from "react";
import GridListTile    from "@material-ui/core/GridListTile";
import GridList        from "@material-ui/core/GridList";
import InjectPrefix    from "../../../Decorators/InjectPrefix";
import { Gallery }     from "../../../Models/Gallery/Gallery";
import { GalleryItem } from "../../../Models/Gallery/GalleryItem";

interface GalleryListProps {
    prefix?: string;
    className?: string;
    gallery: Gallery | undefined;
    onClickImage: (image: GalleryItem, position: number) => void;
    cols?: number;
    cellHeight?: number;
}

const images = [
    {cols: 2},
    {cols: 1},
    {cols: 3},
    {cols: 2},
    {cols: 1},
    {cols: 3},
];

@InjectPrefix
export class GalleryList extends React.Component<GalleryListProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  gallery,
                  onClickImage,
                  className  = "",
                  cols       = 3,
                  cellHeight = 160
              } = this.props;

        return (
            <div className={`${this.prefix}-gallery-list ${className}`}>
                <GridList cellHeight={cellHeight} cols={cols}>
                    {
                        gallery
                        ? gallery.getItems().map((item: GalleryItem, key: number) => (
                            <GridListTile
                                cols={images[key] ? images[key].cols : Math.floor(Math.random() * 3) + 1}
                                key={key}
                                component={"a"}
                                onClick={() => onClickImage(item, key)}
                                className={`item-list`}
                            >
                                <img src={item.getImageFullPath()} alt={""}/>
                            </GridListTile>
                        ))
                        : <React.Fragment/>
                    }
                </GridList>
            </div>
        );
    }
}
