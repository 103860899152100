import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Button, Card, TextField }        from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import SimpleReactValidator               from "simple-react-validator";
import CircularProgress                   from "@material-ui/core/CircularProgress";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import ResetPassword                      from "../../../Models/User/ResetPassword";
import { ERROR_CLASS }                    from "../../../Store/FormStore";
import ErrorOutlineIcon                   from "@material-ui/icons/ErrorOutline";

interface IEditProfileFormViewProps extends WithNamespaces {
    resetPassword: ResetPassword;
    validator: SimpleReactValidator;
    send: boolean;
    sending: boolean;
    submit: (form: React.Component) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class EditProfileFormView extends React.Component<IEditProfileFormViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderButton = (): React.ReactNode => {
        const {
                  t,
                  submit,
                  sending,
                  send,
              }       = this.props,
              text    = sending ? (<CircularProgress color="primary"/>) : t("global:save"),
              canSend = !(sending);

        if (send) {
           return (
               <Button
                   variant="contained"
                   color="secondary"
                   size="large"
                   className={`mt-2 btn-reset`}
                   disabled
               >
                   Guardado
               </Button>
           );
        }

        return (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={`mt-2 btn-reset`}
                onClick={() => {
                    if (canSend)
                        submit(this);
                }}
                disabled={!canSend}
            >
                {text}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  resetPassword,
                  t,
                  validator,
              }              = this.props,
              isNeedPassword = resetPassword.getPassword() !== "",
              confirm        = isNeedPassword && validator.message("password-confirm-repeat", resetPassword.getConfirmPassword(), `required|in:${resetPassword.getPassword()}`);

        return (
            <Card className={"edit-card"}>
                <form>
                    <TextField
                        id="form-name"
                        label={t("forms:name")}
                        fullWidth
                        required
                        value={resetPassword.getFirstName()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            resetPassword.setFirstName(evt.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{error: !!validator.message("firstName", resetPassword.getFirstName(), "required")}}
                    />
                    <TextField
                        id="form-lastName"
                        label={t("forms:lastName")}
                        fullWidth
                        required
                        value={resetPassword.getLastName()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            resetPassword.setLastName(evt.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{error: !!validator.message("lastName", resetPassword.getLastName(), "required")}}
                    />
                    <TextField
                        id="form-password"
                        label={t("forms:password")}
                        fullWidth
                        required={isNeedPassword}
                        value={resetPassword.getPassword()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            resetPassword.setPassword(evt.target.value);
                            if (resetPassword.getPassword() === "") {
                                delete validator.fields["password-confirm-repeat"];
                                delete validator.errorMessages["password-confirm-repeat"];
                            }
                        }}
                        type="password"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{error: isNeedPassword && !!validator.message("password", resetPassword.getPassword(), "required|min:6")}}
                    />
                    <TextField
                        id="form-password-confirm"
                        label={t("forms:password-confirm")}
                        fullWidth
                        required={isNeedPassword}
                        value={resetPassword.getConfirmPassword()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            resetPassword.setConfirmPassword(evt.target.value);
                        }}
                        type="password"
                        margin="normal"
                        variant="outlined"
                    />
                    {
                        (isNeedPassword && !validator.fieldValid("password-confirm-repeat")) &&
                        (
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:field-password-confirm-repeat")}
                            </div>
                        )
                    }
                </form>
                <div>
                    {this.renderButton()}
                </div>
                <hr/>
            </Card>
        );
    }
}

export default withNamespaces("page")(EditProfileFormView);