import { observable }   from "mobx";
import { serializable } from "serializr";

export class Login {
    @serializable
    @observable
    private username: string = "";

    @serializable
    @observable
    private password: string = "";

    @serializable
    @observable
    private jwtToken: string = "";

    @serializable
    @observable
    private facebookEmail: string = "";

    public getUsername(): string {
        return this.username;
    }

    public setUsername(value: string): this {
        this.username = value;

        return this;
    }

    public getPassword(): string {
        return this.password;
    }

    public setPassword(value: string): this {
        this.password = value;

        return this;
    }

    public getJwtToken(): string {
        return this.jwtToken;
    }

    public setJwtToken(value: string): this {
        this.jwtToken = value;

        return this;
    }

    public getFacebookEmail(): string {
        return this.facebookEmail;
    }

    public setFacebookEmail(value: string): this {
        this.facebookEmail = value;

        return this;
    }
}
