import * as React               from "react";
import {
    WithNamespaces,
    withNamespaces
}                               from "react-i18next";
import {
    Col,
    Row
}                               from "reactstrap";
import InjectPrefix             from "../../../Decorators/InjectPrefix";
import { observer }             from "mobx-react";
import { Product }              from "../../../Models/Product/Product";
import { CardProductViewModel } from "../../../ViewModel/Card/CardProductViewModel";
import { Divider }              from "@material-ui/core";

interface RecommendationPersonsViewProps extends WithNamespaces {
    prefix?: string;
    products: Product[];
}

@InjectPrefix
@observer
class ProductView extends React.Component<RecommendationPersonsViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  products,

              } = this.props;
        if (products.length === 0) return <React.Fragment/>;

        return (
            <>
                <div className="w-100 text-center mt-4 mb-4">
                    <h2 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("home.products")}
                    </h2>
                </div>
                <Row className={`${this.prefix}-container-cards products`}>
                    {products ? products.map((product: Product, key: number) => {
                            return (
                                <Col xs={12} sm={4} lg={3} key={key} className="item">
                                    <CardProductViewModel
                                        product={product}
                                    />
                                </Col>
                            );
                        })
                        : <React.Fragment/>}
                </Row>
            </>
        );
    }
}

export default withNamespaces("page")(ProductView);
