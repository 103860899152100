import * as React      from "react";
import LocationMapView from "../../Views/Components/Maps/LocationMapView";

interface LocationMapViewModelProps {}

class LocationMapViewModel extends React.Component<LocationMapViewModelProps, {}> {
    public render(): React.ReactNode {
        return (
            <LocationMapView
                keyGoogleMaps={process.env.REACT_APP_GOOGLE_MAP_KEY as string}
            />
        );
    }
}

export default LocationMapViewModel;
