import * as React                                          from "react";
import { inject, observer }                                from "mobx-react";
import SocialLogin                from "../../Views/Components/SocialLogin/SocialLogin";
import { GoogleLoginResponse }    from "react-google-login";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { Register, RegisterType } from "../../Models/Register/Register";
import uuid                       from "uuid/v4";
import { UserStore }              from "../../Store/UserStore";
import AuthService                from "../../Service/AuthService";
import { Container }              from "typedi";
import { Login }                  from "../../Models/Form/Login";
import { RegisterStore }          from "../../Store/RegisterStore";
import { withRouter }             from "react-router-dom";
import { RouteComponentProps }    from "react-router";
import { PageNames, renderRoute } from "../../Routes/routes";

interface ISocialLoginViewModelProps extends RouteComponentProps<any> {
    UserStore?: UserStore;
    RegisterStore?: RegisterStore;
}

@inject(UserStore.NAME_STORE, RegisterStore.NAME_STORE)
@observer
class SocialLoginViewModel extends React.Component<ISocialLoginViewModelProps, {}> {
    private password: string = "";

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    get registerStore(): RegisterStore {
        return this.props.RegisterStore as RegisterStore;
    }

    get authService(): AuthService {
        return Container.get(AuthService);
    }

    private onSuccessGoogle = (response: GoogleLoginResponse) => {
        const register = new Register(),
              profile  = response.getBasicProfile();

        this.password = uuid();

        Object.assign(register, {
            confirmPassword: this.password,
            email          : profile.getEmail(),
            firstName      : profile.getGivenName(),
            googleJWT      : response.tokenId,
            lastName       : profile.getFamilyName(),
            password       : this.password,
            type           : RegisterType.GOOGLE,
            username       : profile.getEmail()
        });

        this.registerAndLogin(register);
    };

    private onSuccessFacebook = (userInfo: ReactFacebookLoginInfo) => {
        const register = new Register();
        this.password  = uuid();

        Object.assign(register, {
            confirmPassword: this.password,
            email          : userInfo.email,
            firstName      : userInfo.name,
            isFacebook     : true,
            lastName       : "",
            password       : this.password,
            type           : RegisterType.FACEBOOK,
            username       : userInfo.email,
        });

        this.registerAndLogin(register);
    };

    private registerAndLogin = async (register: Register) => {
        const user  = await this.registerStore.registerUser(register),
              login = new Login();

        if (register.getGoogleJWT()) {
            login.setUsername(user.getUsername())
                 .setJwtToken(register.getGoogleJWT())
            ;
        }

        if (register.getIsFacebook()) {
            login.setUsername(user.getUsername())
                 .setFacebookEmail(user.getUsername())
            ;
        }

        await this.authService.getAccessToken(login);

        this.props.history.push(renderRoute(PageNames.homePage));
    };

    public render(): React.ReactNode {
        return (
            <SocialLogin
                onSuccessGoogle={this.onSuccessGoogle}
                onSuccessFacebook={this.onSuccessFacebook}
            />
        );
    }
}

export default withRouter(SocialLoginViewModel);