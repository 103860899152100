import InjectPrefix       from "../../Decorators/InjectPrefix";
import { observer }       from "mobx-react";
import * as React         from "react";
import { withNamespaces } from "react-i18next";
import User               from "../../Models/User/User";
import { Order }          from "../../Models/Order/Order";
import {
    Button,
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
}                         from "@material-ui/core";
import moment             from "moment";
import formatDate         from "../../config/formatDate";
import {
    withRouter
}                         from "react-router-dom";
import {
    PageNames,
    renderRoute
}                         from "../../Routes/routes";
import IBaseProps         from "../Props/IBaseProps";
import NumberFormat       from "../../Views/Components/NumberFormat/NumberFormat";

interface ITableOrderViewModelProps extends IBaseProps {
    user: User;
    orders: Order[];
    prefix?: string;
}

@InjectPrefix
@observer
class TableOrderViewModel extends React.Component<ITableOrderViewModelProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private renderOrders = (): React.ReactNode => {
        const {t, orders} = this.props;

        return orders.map((order: Order) => {
            return (
                <TableRow key={order.getId()}>
                    <TableCell>
                        {moment(order.getCreatedAt()).format(formatDate.calendarDate)}
                    </TableCell>
                    <TableCell>
                        {order.getIdentifier()}
                    </TableCell>
                    <TableCell>
                        {t(`order-status:${order.getStatus()}`)}
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            value={order.getTotal()}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <Button
                            onClick={() => {
                                this.props.history.push(renderRoute(PageNames.orderDetailPage, {
                                    identifier: order.getIdentifier(),
                                }));
                            }}
                            className="btn-primary"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {t("detail")}
                        </Button>
                    </TableCell>
                </TableRow>
            );
        });
    };

    private renderDesktop = (): React.ReactNode => {
        const {t} = this.props;

        return (
            <Table className={`${this.prefix}-table-orders`}>
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell>
                            {t("date")}
                        </TableCell>
                        <TableCell>
                            {t("order")}
                        </TableCell>
                        <TableCell>
                            {t("status")}
                        </TableCell>
                        <TableCell>
                            {t("total")}
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderOrders()}
                </TableBody>
            </Table>
        );
    };

    private renderMobile = (): React.ReactNode => {
        const {t, orders} = this.props;

        return (
            orders.map((order: Order) => {
                return (
                    <Table className={`${this.prefix}-table-orders mobile`} key={order.getId()}>
                        <TableBody>
                            <TableRow>
                                <TableCell align={"right"}>
                                    {t("date")}
                                </TableCell>
                                <TableCell>
                                    {moment(order.getCreatedAt()).format(formatDate.calendarDate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"right"}>
                                    {t("order")}
                                </TableCell>
                                <TableCell>
                                    {order.getIdentifier()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"right"}>
                                    {t("status")}
                                </TableCell>
                                <TableCell>
                                    {t(`order-status:${order.getStatus()}`)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"right"}>
                                    {t("total")}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        value={order.getTotal()}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Button
                                        onClick={() => {
                                            this.props.history.push(renderRoute(PageNames.orderDetailPage, {
                                                identifier: order.getIdentifier(),
                                            }));
                                        }}
                                        className="btn-primary"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        {t("detail")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                );
            })
        );
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <>
                <Hidden smUp>
                    {this.renderMobile()}
                </Hidden>
                <Hidden xsDown>
                    {this.renderDesktop()}
                </Hidden>
            </>
        );
    }
}

export default withRouter(withNamespaces("global")(TableOrderViewModel));