import { serializable } from "serializr";
import { observable }   from "mobx";
import { TYPE_DNI }     from "../../config/typeDNI";

export class BillElectronic {
    @serializable
    @observable
    private dni: string = "";

    @serializable
    @observable
    private typeDni: TYPE_DNI = TYPE_DNI.CEDULA;

    @serializable
    @observable
    private fullName: string = "";

    @serializable
    @observable
    private uid: string;

    @serializable
    @observable
    private email: string = "";

    @serializable
    @observable
    private phone: string = "";

    @serializable
    @observable
    private direction: string = "";

    @serializable
    @observable
    private principal: boolean = false;

    public getDni(): string {
        return this.dni;
    }

    public setDni(value: string) {
        this.dni = value;
    }

    public getTypeDni(): TYPE_DNI {
        return this.typeDni;
    }

    public setTypeDni(value: TYPE_DNI) {
        this.typeDni = value;
    }

    public getFullName(): string {
        return this.fullName;
    }

    public setFullName(value: string) {
        this.fullName = value;
    }

    public getUID(): string {
        return this.uid;
    }

    public setUid(value: string) {
        this.uid = value;
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getPrincipal(): boolean {
        return this.principal;
    }

    public setPrincipal(value: boolean) {
        this.principal = value;
    }

    public getDirection(): string {
        return this.direction;
    }

    public setDirection(value: string) {
        this.direction = value;
    }

    public getPhone(): string {
        return this.phone;
    }

    public setPhone(value: string) {
        this.phone = value;
    }
}