import { observable }             from "mobx";
import BaseStore                  from "./Base/BaseStore";
import { ContactUs }              from "../Models/Form/ContactUs";
import AjaxService                from "../Service/AjaxService";
import Container                  from "typedi";
import { deserialize, serialize } from "serializr";
import SimpleReactValidator       from "simple-react-validator";
import ErrorOutlineIcon           from "@material-ui/icons/ErrorOutline";
import * as React                 from "react";
import { ConfigurationStore }     from "./ConfigurationStore";
import { TranslationFunction }    from "i18next";
import { Direction }              from "../Models/User/Direction";
import { BillElectronic }         from "../Models/User/BillElectronic";

export const ERROR_CLASS = "element-error";

export class FormStore extends BaseStore {
    public static readonly NAME_STORE: string = "FormStore";

    @observable
    private contactUs: ContactUs;

    @observable
    private direction: Direction;

    @observable
    private billElectronic: BillElectronic;

    public markers: google.maps.Marker[] = [];

    public map: google.maps.Map;

    @observable
    private validator: SimpleReactValidator;

    public getValidator(): SimpleReactValidator {
        return this.validator;
    }

    public setValidator(value: SimpleReactValidator) {
        this.validator = value;
    }

    public getContactUs(): ContactUs {
        return this.contactUs;
    }

    public setContactUs(value: ContactUs) {
        this.contactUs = value;
    }

    public getDirection(): Direction {
        return this.direction;
    }

    public setDirection(value: Direction) {
        this.direction = value;
    }

    public getBillElectronic(): BillElectronic {
        return this.billElectronic;
    }

    public setBillElectronic(value: BillElectronic) {
        this.billElectronic = value;
    }

    public initDirection(direction?: Direction) {
        this.direction = direction || new Direction().setProvince("San José");
    }

    public initBillElectronic(billElectronic?: BillElectronic) {
        this.billElectronic = billElectronic || new BillElectronic();
    }

    protected init() {
        this.needPersistData = false;
    }

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async saveContact(contactUs: ContactUs): Promise<ContactUs> {
        const {data: {data}} = await this.getAjaxService().postContactUs(serialize(contactUs));

        return deserialize(ContactUs, data);
    }

    public getNewValidator(t: TranslationFunction) {
        const prefix                          = this.getStore(ConfigurationStore).getConfiguration().getPrefix(),
              validator: SimpleReactValidator =
                  new SimpleReactValidator({
                                               autoForceUpdate: false,
                                               element        : (message: string) => (
                                                   <div className={`${prefix}-error-color ${ERROR_CLASS}`}>
                                                       <ErrorOutlineIcon/> {message}
                                                   </div>
                                               ),
                                               locale         : "es",
                                               messages       : {
                                                   direction         : t("validator:field-direction"),
                                                   email             : t("validator:field-email"),
                                                   min               : t("validator:field-min"),
                                                   name              : t("validator:field-name"),
                                                   password          : t("validator:field-password"),
                                                   "password-confirm": t("validator:password-field-confirm"),
                                                   required          : t("validator:field-required"),
                                               },
                                               validators     : {
                                                   "field-password-no-regex": {
                                                       message : t("validator:field-password-no-regex"),
                                                       required: true,  // optional
                                                       rule    : (val: string, params: string) => {
                                                           const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/i;

                                                           return validator.helpers.testRegex(val, regex);
                                                       }
                                                   }
                                               }
                                           });

        return validator;
    }
}
