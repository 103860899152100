import BaseStore                                  from "./Base/BaseStore";
import { deserialize }                            from "serializr";
import { Container }                              from "typedi";
import AjaxService                                from "../Service/AjaxService";
import { observable }                             from "mobx";
import { Promotion }                              from "../Models/Promotion/Promotion";
import { IPaginationData, promotionPagerOptions } from "../config/pagination";

export interface IGetPromotion {
    promotion: Promotion;
    previous: Promotion[];
    next: Promotion[];
}

export class PromotionStore extends BaseStore {
    public static readonly NAME_STORE: string = "PromotionStore";

    @observable
    private promotions: Promotion[] = [];

    @observable
    private promotion: Promotion;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getPromotion(): Promotion {
        return this.promotion;
    }

    public setPromotion(value: Promotion): this {
        this.promotion = value;

        return this;
    }

    public getPromotions(): Promotion[] {
        return this.promotions;
    }

    public setPromotions(value: Promotion[]): this {
        this.promotions = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Promotion>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginatePromotions(
            this.getCurrentPage(),
            promotionPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Promotion, item)),
            pages: dataResponse.pages
        } as IPaginationData<Promotion>;
    }

    public async getBySlug(slug: string): Promise<IGetPromotion> {
        const {data: {data}} = await this.getAjaxService().getPromotionSlug(slug),
              promotion      = deserialize(Promotion, data.promotion),
              previous       = data.previous.map((item: any) => deserialize(Promotion, item)),
              next           = data.next.map((item: any) => deserialize(Promotion, item));

        return {
            next,
            previous,
            promotion,
        };
    }
}
