import BaseStore           from "./Base/BaseStore";
import AjaxService         from "../Service/AjaxService";
import Container           from "typedi";
import {
    deserialize,
    serialize
}                          from "serializr";
import { observable }      from "mobx";
import { Category }        from "../Models/Product/Category";
import {
    globalPagerOptions,
    IPaginationData
}                          from "../config/pagination";
import { Product }         from "../Models/Product/Product";
import { PrincipalSearch } from "../Models/Product/PrincipalSearch";
import {
    contains,
    removeItem
}                          from "../Utils/array";

export class ProductStore extends BaseStore {
    public static readonly NAME_STORE: string = "ProductStore";

    @observable
    private categories: Category[] = [];

    @observable
    private principalSearch: PrincipalSearch = new PrincipalSearch();

    @observable
    private products: Product[] = [];

    @observable
    private spinner: boolean = false;

    private currentPage: number = 1;

    @observable
    private pages: number = 1;

    public init() {
        this.needPersistData = false;
    }

    public getCategories = (): Category[] => {
        return this.categories;
    };

    public setCategories = (value: Category[]) => {
        this.categories = value;
    };

    public addCategory = (value: Category) => {
        this.getCategories().push(value);
    };

    public removeCategory = (value: Category) => {
        removeItem(this.getCategories(), value, "_id" as keyof Category);
    };

    public existCategory = (value: Category): boolean => {
        return !!contains(this.getCategories(), value, "_id" as keyof Category);
    };

    public getPrincipalSearch = (): PrincipalSearch => {
        return this.principalSearch;
    };

    public setPrincipalSearch = (value: PrincipalSearch) => {
        this.principalSearch = value;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public setSpinner = (value: boolean): this => {
        this.spinner = value;

        return this;
    };

    public getPages = (): number => {
        return this.pages;
    };

    public setPages = (value: number): this => {
        this.pages = value;

        return this;
    };

    public getCurrentPage = (): number => {
        return this.currentPage;
    };

    public setCurrentPage = (value: number): this => {
        this.currentPage = value;

        return this;
    };

    public getProducts = (): Product[] => {
        return this.products;
    };

    public setProducts = (value: Product[]) => {
        this.products = value;
    };

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public addCategoryPrincipalSearch = (category: Category) => {
        const principalSearch = this.getPrincipalSearch();

        principalSearch.addSubCategory(category.getId());
        principalSearch.addSubCategoryMap(category);
    };

    public removeCategoryPrincipalSearch = (category: Category) => {
        const principalSearch = this.getPrincipalSearch();

        principalSearch.removeSubCategory(category.getId());
        principalSearch.removeSubCategoryMap(category);
    };

    public async loadCategories() {
        const {data: {data: dataResponse}} = await this.getAjaxService().getCategories();

        this.setCategories(dataResponse.map((item: any) => deserialize(Category, item)));

        return this.getCategories();
    }

    public async getSearchPaginate(): Promise<IPaginationData<Product>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getSearchProducts(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PRODUCTS_PER_PAGE,
            serialize(this.getPrincipalSearch())
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Product, item)),
            pages: dataResponse.pages
        };
    }

    public async getProductSlug(slug: string): Promise<Product | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getProductBySlug(slug);

        return dataResponse ? deserialize(Product, dataResponse) : undefined;
    }

    public async getProductsRelationship(product: Product | string): Promise<Product[]> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getProductsRelationship(
            typeof product === "string" ? product : product.getId()
        );

        return dataResponse.map((item: any) => deserialize(Product, item));
    }

    public async getProductsByIdsList(productsIds: string[]) {
        const {data: {data: {products: productsSerialize}}} = await this.getAjaxService().getProductsByIdsList(productsIds);

        return productsSerialize.map((item: any) => deserialize(Product, item));
    }
}
