import { AxiosRequestConfig } from "axios";

export const CONFIG_GET_ACCESS_TOKEN = <AxiosRequestConfig> {
   baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
   headers    : {
       "Content-Type": "application/x-www-form-urlencoded"
   }
};

export const PARAMS_GET_ACCESS_TOKEN = {
    client_id    : process.env.REACT_APP_API_BACKEND_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_BACKEND_CLIENT_SECRET,
    grant_type   : "password",
};

export const PARAMS_GET_ACCESS_TOKEN_SOCIAL = {
    client_id    : process.env.REACT_APP_API_BACKEND_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_BACKEND_CLIENT_SECRET,
    grant_type   : "social",
};

export const CONFIG_CALL_GET_API = <AxiosRequestConfig> {
    baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
    headers    : {
        "Content-Type": "application/x-www-form-urlencoded",
        "authorization" : "Bearer %s"
    }
};

export const CONFIG_CALL_POST_API = <AxiosRequestConfig> {
    baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
    headers    : {
        "authorization" : "Bearer %s",
        "content-type": "application/json"
    }
};