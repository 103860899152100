import * as React           from "react";
import { Container }        from "typedi";
import { ApplicationStore } from "../Store/ApplicationStore";
import { UserStore }        from "../Store/UserStore";
import { Redirect }         from "react-router";
import Middleware           from "../Decorators/Middleware";
import BaseMiddleware       from "./Base/BaseMiddleware";

@Middleware
export class AuthMiddleware extends BaseMiddleware {
    public static getAlias(): string {
        return "AuthMiddleware";
    }

    validate(): boolean {
        const userStore: UserStore = Container.get(ApplicationStore).getStore(UserStore);

        return !!userStore.getUser();
    }

    reject(props: any): React.ReactNode {
        return <Redirect to={{
            pathname: "/login",
            state   : {from: props.location}
        }}/>;
    }
}