import { inject, observer } from "mobx-react";
import * as React           from "react";
import Categories           from "../../Views/Components/Categories/Categories";
import { ProductStore }     from "../../Store/ProductStore";

interface ICategoriesViewModelProps {
    search: () => void;
    ProductStore?: ProductStore;
}

@inject(ProductStore.NAME_STORE)
@observer
class CategoriesViewModel extends React.Component<ICategoriesViewModelProps, {}> {
    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    public render(): React.ReactNode {
        const principalSearch = this.productStore.getPrincipalSearch(),
              {search}        = this.props;

        return (
            <Categories
                categories={this.productStore.getCategories()}
                addCategory={this.productStore.addCategoryPrincipalSearch}
                removeCategory={this.productStore.removeCategoryPrincipalSearch}
                existCategory={principalSearch.existSubCategoryMap}
                search={search}
            />
        );
    }
}

export default CategoriesViewModel;