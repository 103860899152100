import * as React                          from "react";
import { WithNamespaces, withNamespaces }  from "react-i18next";
import InjectPrefix                        from "../../../Decorators/InjectPrefix";
import { GalleryPromotionDetailViewModel } from "../../../ViewModel/Gallery/GalleryPromotionDetailViewModel";
import { Col, Row }                        from "reactstrap";
import moment                              from "moment";
import Box                                 from "@material-ui/core/Box";
import Paper                               from "@material-ui/core/Paper";
import formatDate                          from "../../../config/formatDate";
import { Social }                          from "../Social/Social";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import { Promotion }                       from "../../../Models/Promotion/Promotion";
import NumberFormat                        from "../NumberFormat/NumberFormat";

interface PromotionPresentationProps extends WithNamespaces {
    prefix?: string;
    promotion: Promotion;
}

@InjectPrefix
class PromotionPresentation extends React.Component<PromotionPresentationProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, promotion} = this.props;

        return (
            <div className={`${this.prefix}-presentation-promotion row`}>
                <Col xs={12} sm={6}>
                    <GalleryPromotionDetailViewModel promotion={promotion}/>
                </Col>
                <Col xs={12} sm={6}>
                    <Paper className="p-2">
                        <h1>
                            {promotion.getName()}
                        </h1>
                        <span className="date">
                            {moment(promotion.getDateInit()).format(formatDate.shortDateYear)}
                        </span>
                        <hr/>
                        <Row>
                            <Col xs={2} className="font-weight-bold">
                                {t("actual-price")}
                            </Col>
                            <Col xs={10}>
                                <NumberFormat
                                    value={promotion.getActualPrice()}
                                    renderText={(formattedValue: string) =>
                                        <Box component={"p"} className="price" fontWeight="fontWeightBold">
                                            {formattedValue}
                                        </Box>
                                    }
                                />
                            </Col>
                            <Col xs={2} className={`${this.prefix}-senary-color`}>
                                {t("before")}
                            </Col>
                            <Col xs={10}>
                                <NumberFormat
                                    value={promotion.getLastPrice()}
                                    renderText={(formattedValue: string) =>
                                        <Box component={"p"} className="was-price">
                                            {formattedValue}
                                        </Box>
                                    }
                                />
                            </Col>
                            <Col xs={12}>
                                <hr/>
                                <h5 className={`${this.prefix}-secondary-color`}>
                                    {t("description")}
                                </h5>
                                <p>{promotion.getShortDescription()}</p>
                            </Col>
                        </Row>
                        <hr/>
                        <Social
                            colorIcons={`${this.prefix}-secondary-color`}
                            noWaze
                            text={`Hola, deseo información de la promocion: ${promotion.getName()}. ${location.origin}${renderRoute(PageNames.promotionPage, {slug: promotion.getSlug()})}`}
                        />
                    </Paper>
                </Col>
            </div>
        );
    }
}

export default withNamespaces("global")(PromotionPresentation);
