import { serializable } from "serializr";

export default class Error {
    @serializable
    private code: number;

    @serializable
    private backgroundImage: string;

    @serializable
    private message: string;

    @serializable
    private link: string;

    @serializable
    private title: string;

    /**
     * Getter code
     * @return {number}
     */
    public getCode(): number {
        return this.code;
    }

    /**
     * Getter backgroundImage
     * @return {string}
     */
    public getBackgroundImage(): string {
        return this.backgroundImage;
    }

    /**
     * Getter message
     * @return {string}
     */
    public getMessage(): string {
        return this.message;
    }

    /**
     * Setter code
     * @param {number} value
     */
    public setCode(value: number) {
        this.code = value;
    }

    /**
     * Setter backgroundImage
     * @param {string} value
     */
    public setBackgroundImage(value: string) {
        this.backgroundImage = value;
    }

    /**
     * Setter message
     * @param {string} value
     */
    public setMessage(value: string) {
        this.message = value;
    }

    /**
     * Getter title
     * @return {string}
     */
    public getTitle(): string {
        return this.title;
    }

    /**
     * Setter title
     * @param {string} value
     */
    public setTitle(value: string) {
        this.title = value;
    }

    /**
     * Getter link
     * @return {string}
     */
    public getLink(): string {
        return this.link;
    }

    /**
     * Setter link
     * @param {string} value
     */
    public setLink(value: string) {
        this.link = value;
    }


}
