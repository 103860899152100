import * as React      from "react";
import { ModalImage }  from "../../Views/Components/Modal/ModalImage";
import { observable }  from "mobx";
import { observer }    from "mobx-react";
import { contains }    from "../../Utils/array";
import { GalleryItem } from "../../Models/Gallery/GalleryItem";

interface ModalImageViewModelProps {
    image: GalleryItem;
    images: GalleryItem[];
    position: number;
}

const LEFT  = [37, "37", "ArrowLeft"],
      RIGHT = [39, "39", "ArrowRight"];

@observer
export class ModalImageViewModel extends React.Component<ModalImageViewModelProps, {}> {
    @observable
    private position: number;
    private readonly length: number;

    public constructor(props: ModalImageViewModelProps) {
        super(props);
        this.position = props.position;
        this.length   = props.images.length;
    }

    public componentDidMount(): void {
        document.addEventListener("keydown", this.arrowFunction, false);
    }

    public componentWillUnmount = () => {
        document.removeEventListener("keydown", this.arrowFunction, false);
    };

    private arrowFunction = (event: KeyboardEvent) => {
        let code;

        if (event.key !== undefined) {
            code = event.key;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        }

        if (contains(LEFT, code) && this.showBack()) {
            this.back();
        }

        if (contains(RIGHT, code) && this.showNext()) {
            this.next();
        }
    };

    private next = () => {
        this.position++;
    };

    private back = () => {
        this.position--;
    };

    private showNext = (): boolean => {
        return this.position < this.length - 1;
    };

    private showBack = (): boolean => {
        return this.position !== 0;
    };

    public render(): React.ReactNode {
        const {images} = this.props;

        if (images[this.position])
            return (
                <ModalImage
                    image={images[this.position]}
                    next={this.next}
                    back={this.back}
                    showNext={this.showNext()}
                    showBack={this.showBack()}
                    position={this.position + 1}
                    length={this.length}
                />
            );

        return <React.Fragment/>;
    }
}
