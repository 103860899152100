import * as React                from "react";
import CardProduct               from "../../Views/Components/Card/CardProduct";
import { Product }               from "../../Models/Product/Product";
import { Container }             from "typedi";
import ModalService              from "../../Service/ModalService";
import { ModalProductViewModel } from "../Modal/ModalProductViewModel";
import { observer }              from "mobx-react";
import { observable }            from "mobx";

interface ICardProductViewModelProps {
    product: Product;
    openSlugProduct?: string;
}

@observer
export class CardProductViewModel extends React.Component<ICardProductViewModelProps, {}> {
    @observable
    private isOpen: boolean;

    constructor(props: ICardProductViewModelProps) {
        super(props);
    }

    protected showProduct = (product: Product) => {
        this.isOpen = true;
        Container.get(ModalService).openModal(ModalProductViewModel, {
            onClose: () => {
                this.isOpen = false;
            },
            product,
        });
    };

    public render(): React.ReactNode {
        const {product, openSlugProduct} = this.props;

        return (
            <CardProduct
                showProduct={this.showProduct}
                product={product}
                isOpenProduct={this.isOpen || openSlugProduct === product.getSlug()}
            />
        );
    }
}