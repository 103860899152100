import * as React      from "react";
import InjectPrefix    from "../../../Decorators/InjectPrefix";
import { Hidden }      from "@material-ui/core";
import { Promotion }   from "../../../Models/Promotion/Promotion";
import { GalleryItem } from "../../../Models/Gallery/GalleryItem";
import classNames      from "classnames";

interface GalleryPromotionDetailProps {
    prefix?: string;
    promotion: Promotion;
    currentImage: GalleryItem;
    setCurrentImage: (image: GalleryItem) => void;
}

@InjectPrefix
export class GalleryPromotionDetail extends React.Component<GalleryPromotionDetailProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private renderImages = (): React.ReactNode[] => {
        const {promotion, currentImage, setCurrentImage} = this.props;

        return promotion.getGallery().getItems().map((image: GalleryItem) => {
            return (
                <li
                    className={`item ${classNames({active: image.getImageFullPath() === currentImage.getImageFullPath()})}`}
                    key={image.getImageFullPath()}
                >
                    <a onClick={() => setCurrentImage(image)} href="javascript:void(0);">
                        <img
                            src={image.getImageFullPath()}
                            alt={image.getImageFullPath()}
                            className="thumbnail"
                        />
                    </a>
                </li>
            );
        });
    };

    public render(): React.ReactNode {
        const {currentImage} = this.props;

        return (
            <div className={`${this.prefix}-gallery-promotion-detail`}>
                <div className="list-images">
                    <ul className="list-unstyled">
                        {this.renderImages()}
                    </ul>
                </div>
                <div className="current-image">
                    <Hidden only="xs">
                        <img
                            src={currentImage.getImageFullPath()}
                            alt={currentImage.getImageFullPath()}
                            className="img-fluid"
                        />
                    </Hidden>
                    <Hidden smUp>
                        <img
                            src={currentImage.getImageFullPath()}
                            alt={currentImage.getImageFullPath()}
                            className="img-fluid"
                        />
                    </Hidden>
                </div>
            </div>
        );
    }
}
