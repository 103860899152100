import InjectPrefix                         from "../../../Decorators/InjectPrefix";
import { observer }                         from "mobx-react";
import * as React                           from "react";
import { Product }                          from "../../../Models/Product/Product";
import { withNamespaces, WithNamespaces }   from "react-i18next";
import ProductRelationship                  from "./ProductRelationship";
import { ListCheckedProductsRelationship }  from "../../../types/ListCheckedProductsRelationship";
import { ShoppingCart as ShoppingCartIcon } from "@material-ui/icons";
import { Box, Button }                      from "@material-ui/core";
import NumberFormat                         from "../NumberFormat/NumberFormat";

interface IProductRelationshipProps extends WithNamespaces {
    productsRelationship: Product[];
    product: Product;
    listChecked: ListCheckedProductsRelationship;
    handleChangeList: (id: string, status: boolean) => void;
    actionAddCar: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class ProductsRelationship extends React.Component<IProductRelationshipProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderProductsChecked = (products: { product: Product }[]): React.ReactNode => {
        return products.map((item, key: number) => {
            const product = item.product;

            return (
                <React.Fragment key={product.getId()}>
                    <li className="product">
                        <img src={product.getImageFullPath()} className="image" alt=""/>
                    </li>
                    {key + 1 === products.length ? <React.Fragment/> : <li className="plus">+</li>}
                </React.Fragment>
            );
        });
    };

    protected renderAddAll = (products: { product: Product }[]): React.ReactNode => {
        if (products.length === 0) return <React.Fragment/>;

        const {actionAddCar, t} = this.props,
              total             = products.reduce((accum, item) => accum + item.product.getPrice(), 0);

        return (
            <li className="add-all">
                <div>
                    <span>{t("car:total-price")}: <NumberFormat
                        value={total}
                        renderText={(formattedValue: string) =>
                            <Box component={"span"} className="price">
                                {formattedValue}
                            </Box>
                        }
                    /></span>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className="button-add"
                    onClick={() => actionAddCar()}
                >
                    <ShoppingCartIcon/> {t("car:add-all")}
                </Button>
            </li>
        );
    };

    public render(): React.ReactNode {
        const {
                  productsRelationship,
                  t,
                  product,
                  handleChangeList,
                  listChecked,
              }        = this.props,
              products = Object.keys(listChecked)
                               .map((key: string) => {
                                   return listChecked[key];
                               })
                               .filter((item) => {
                                   return item.status;
                               });

        return (
            <section className={`${this.prefix}-product-relationship`}>
                <h5>{t("frequently-bought-together")}</h5>
                <ul className="list-products-selected">
                    {this.renderProductsChecked(products)}
                    {this.renderAddAll(products)}
                </ul>
                <ul className="list-products">
                    <ProductRelationship
                        key={product.getId()}
                        checked={listChecked[product.getId()].status}
                        product={product}
                        handleChange={(status: boolean) => {
                            handleChangeList(product.getId(), status);
                        }}
                    />
                    {productsRelationship.map((product: Product) => {
                        return (
                            <ProductRelationship
                                key={product.getId()}
                                checked={listChecked[product.getId()].status}
                                product={product}
                                handleChange={(status: boolean) => {
                                    handleChangeList(product.getId(), status);
                                }}
                            />
                        );
                    })}
                </ul>
            </section>
        );
    }
}

export default withNamespaces("global")(ProductsRelationship);