import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import CircularProgress                   from "@material-ui/core/CircularProgress";
import Button                             from "@material-ui/core/Button";
import Box                                from "@material-ui/core/Box";
import { Container }                      from "@material-ui/core";
import { Col, Row }                       from "reactstrap";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import Carousel                           from "../Components/Carousel/Carousel";
import CardRecommendationPerson           from "../Components/Card/CardRecommendationPerson";
import { RecommendationPerson }           from "../../Models/RecomendationPerson/RecommendationPerson";
import { Gallery }                        from "../../Models/Gallery/Gallery";
import { Page }                           from "../../Models/Page/Page";
import Seo                                from "../Components/Header/Seo";
import { Occupation }                     from "../../Models/Occupation/Occupation";
import { observer }                       from "mobx-react";

interface RecommendationPersonsViewProps extends WithNamespaces {
    prefix?: string;
    recommendationPersons: RecommendationPerson[];
    showMore: () => void;
    loading: boolean;
    currentPage: number;
    pages: number;
    gallery: Gallery;
    page: Page | undefined;
    occupationsMap: Map<string, Occupation>;
}

@InjectPrefix
@observer
class RecommendationPersonsView extends React.Component<RecommendationPersonsViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private renderLoading = (): React.ReactNode => {
        const {
                  t,
                  showMore,
                  loading,
                  currentPage,
                  pages
              } = this.props;

        if (loading)
            return (<CircularProgress color="secondary"/>);

        if (currentPage > pages || (currentPage === 1 && pages === 1))
            return (<React.Fragment/>);

        return (
            <Button variant="outlined" color="secondary" onClick={showMore}>
                {t("global:show-more")}
            </Button>
        );
    };

    public render(): React.ReactNode {
        const {
                  t,
                  recommendationPersons,
                  gallery,
                  occupationsMap,
                  page
              }          = this.props,
              paragraphs = t("recommendation-persons.paragraphs", {returnObjects: true});

        return (
            <React.Fragment>
                <Seo seoData={page ? page.getSeoData() : undefined}/>
                <Carousel
                    gallery={gallery}
                />
                <Container className={`${this.prefix}-p-recommendation-persons`}>
                    <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("recommendation-persons.title")}
                    </h1>
                    <hr/>

                    <h3 className={"mt-4 mb-4 d-block"}>{t("recommendation-persons.subtitle")}</h3>
                    {
                        paragraphs.map((text: string, key: number) => (<p key={key}>{text}</p>))
                    }
                    <Row className={`${this.prefix}-container-cards`}>
                        {recommendationPersons.map((recommendationPerson: RecommendationPerson, key: number) => {
                            return (
                                <Col xs={12} sm={4} lg={3} key={key}>
                                    <CardRecommendationPerson
                                        recommendationPerson={recommendationPerson}
                                        occupationsMap={occupationsMap}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Box justifyContent="center" className="d-flex mb-4">
                        {this.renderLoading()}
                    </Box>
                </Container>
            </React.Fragment>
        );
    }
}

export default withNamespaces("page")(RecommendationPersonsView);
