import {
    list,
    object,
    serializable
}                     from "serializr";
import { observable } from "mobx";
import { Color }      from "./Color";

export class ColorPalette {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private type: string;

    @observable
    @serializable(list(object(Color)))
    private colors: Color[] = [];

    public getId(): string {
        return this._id;
    }

    public setId(value: string): this {
        this._id = value;

        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): this {
        this.name = value;

        return this;
    }

    public getType(): string {
        return this.type;
    }

    public setType(value: string): this {
        this.type = value;

        return this;
    }

    public getColors(): Color[] {
        return this.colors;
    }

    public setColors(value: Color[]): this {
        this.colors = value;

        return this;
    }
}