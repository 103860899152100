import * as React                         from "react";
import Card                               from "@material-ui/core/Card";
import CardHeader                         from "@material-ui/core/CardHeader";
import CardContent                        from "@material-ui/core/CardContent";
import CardActions                        from "@material-ui/core/CardActions";
import Typography                         from "@material-ui/core/Typography";
import Box                                from "@material-ui/core/Box";
import { Link }                           from "react-router-dom";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { PageNames, renderRoute }         from "../../../Routes/routes";
import { Promotion }                      from "../../../Models/Promotion/Promotion";
import moment                             from "moment";
import formatDate                         from "../../../config/formatDate";
import NumberFormat                       from "../NumberFormat/NumberFormat";


interface CardPromotionProps extends WithNamespaces {
    prefix?: string;
    promotion: Promotion;
}

@InjectPrefix
class CardPromotion extends React.Component<CardPromotionProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  promotion,
                  t
              }         = this.props,
              firstItem = promotion.getGallery().getFirstItem(),
              imageSrc  = firstItem ? firstItem.getImageFullPath() : "";

        return (
            <Card className={`${this.prefix}-card-promotion`}>
                <CardHeader
                    title={promotion.getName()}
                    titleTypographyProps={{component: "span" as unknown as React.ComponentClass, variant: "h6"}}
                    subheader={moment(promotion.getDateInit()).format(formatDate.shortDateYear)}
                />
                <img src={imageSrc} className="img-fluid image-card" alt=""/>
                <CardContent>
                    <Typography variant="body2" color="textSecondary"
                                component={"p" as unknown as React.ComponentClass}>
                        {promotion.getShortDescription()}
                    </Typography>
                    <NumberFormat
                        value={promotion.getActualPrice()}
                        renderText={(formattedValue: string) =>
                            <Box component={"p"} className="price" fontWeight="fontWeightBold">
                                {formattedValue}
                            </Box>
                        }
                    />
                    {
                        promotion.getLastPrice()
                        ? <NumberFormat
                            value={promotion.getLastPrice()}
                            renderText={(formattedValue: string) =>
                                <Box component={"p"} className="was-price">
                                    {t("global:before")} {formattedValue}
                                </Box>
                            }
                        />
                        : <React.Fragment/>
                    }
                </CardContent>
                <CardActions>
                    <Link to={renderRoute(PageNames.promotionPage, {
                        slug: promotion.getSlug()
                    })} className={`show-more`}>
                        {t("show-more")}
                    </Link>
                </CardActions>
            </Card>
        );
    }
}

export default withNamespaces("cards")(CardPromotion);
