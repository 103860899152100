import { list, object, serializable } from "serializr";
import { observable }                 from "mobx";
import Error                          from "./Error";
import PageImage                      from "./PageImage";

class Configuration {
    @serializable
    private prefix: string;

    @observable
    private showHeader: boolean;

    @observable
    private showFooter: boolean;

    @observable
    private showMenuLogo: boolean;

    @observable
    private showLoader: boolean;

    @serializable
    private heroContentProfile: string;

    @serializable(list(object(Error)))
    private errorsContent: Error[];

    @serializable(list(object(PageImage)))
    private pageImages: PageImage[];

    public getPrefix(): string {
        return this.prefix;
    }

    public setPrefix(value: string): this {
        this.prefix = value;

        return this;
    }

    public getShowHeader(): boolean {
        return this.showHeader;
    }

    public setShowHeader(value: boolean) {
        this.showHeader = value;

        return this;
    }

    public getShowFooter(): boolean {
        return this.showFooter;
    }

    public setShowFooter(value: boolean) {
        this.showFooter = value;

        return this;
    }

    public getShowLoader(): boolean {
        return this.showLoader;
    }

    public setShowLoader(value: boolean) {
        this.showLoader = value;
    }

    /**
     * Getter showMenuLogo
     * @return {boolean}
     */
    public getShowMenuLogo(): boolean {
        return this.showMenuLogo;
    }

    /**
     * Setter showMenuLogo
     * @param {boolean} value
     */
    public setShowMenuLogo(value: boolean) {
        this.showMenuLogo = value;

        return this;
    }

    /**
     * Getter heroContentProfile
     * @return {string}
     */
    public getHeroContentProfile(): string {
        return this.heroContentProfile;
    }

    /**
     * Setter heroContentProfile
     * @param {string} value
     */
    public setHeroContentProfile(value: string) {
        this.heroContentProfile = value;
    }

    /**
     * Getter errorsContent
     * @return {Error[]}
     */
    public getErrorsContent(): Error[] {
        return this.errorsContent;
    }

    /**
     * Setter errorsContent
     * @param {Error[]} value
     */
    public setErrorsContent(value: Error[]) {
        this.errorsContent = value;
    }

    /**
     * Setter pageImages
     * @param {PageImage[]} value
     */
    public setPageImages(value: PageImage[]) {
        this.pageImages = value;
    }


    public getErrorContent(code: number): Error | undefined {
        return this.errorsContent.find((error: Error) => {
            return error.getCode() === code;
        });
    }

    public getPageImage(code: string): PageImage | undefined {
        return this.pageImages.find((page: PageImage) => {
            return page.getPage() === code;
        });
    }
}

export default Configuration;
