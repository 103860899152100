import { observer }       from "mobx-react";
import * as React         from "react";
import GoogleMapReact     from "google-map-react";
import InjectPrefix       from "../../../Decorators/InjectPrefix";
import { withNamespaces, WithNamespaces } from "react-i18next";


interface IMapSelectDirectionViewProps extends WithNamespaces{
    onGoogleApiLoaded: (maps: { map: any, maps: any }) => void;
    place: string;
    setPlace: (val: string) => void;
    refInput: React.RefObject<HTMLInputElement>;
    options: {
        center: {
            lat: number;
            lng: number;
        },
        zoom: number;
    };
    prefix?: string;
}

@InjectPrefix
@observer
class MapSelectDirectionView extends React.Component<IMapSelectDirectionViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  onGoogleApiLoaded,
                  place,
                  setPlace,
                  refInput,
                  options,
            t,
              } = this.props;


        return (
            <div className={`${this.prefix}-map-select-direction`}>
                <input
                    className="input-direction-search"
                    id="form-direction"
                    required
                    value={place}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setPlace(evt.target.value);
                    }}
                    ref={refInput}
                    placeholder={t("direction")}
                />
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key      : process.env.REACT_APP_GOOGLE_MAP_KEY as string,
                        // @ts-ignore add library
                        libraries: "places"
                    }}
                    options={() => {
                        return {
                            animation: google.maps.Animation.DROP,
                            draggable: true,
                            mapTypeId: "roadmap"
                        };
                    }}
                    defaultCenter={options.center}
                    defaultZoom={options.zoom}
                    onGoogleApiLoaded={onGoogleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals
                    // heatmapLibrary
                />
            </div>
        );
    }
}

export default withNamespaces("forms")(MapSelectDirectionView);