import React, { ElementType } from "react";
import { Container, Service } from "typedi";
import { ApplicationStore }   from "../Store/ApplicationStore";
import { SnackBarStore }      from "../Store/SnackBarStore";
import SnackBar               from "../Models/SnackBar/SnackBar";

@Service()
class SnackBarService {
    private propsInject: any = {};

    public getPropsInject(): any {
        return this.propsInject;
    }

    public setPropsInject(props: any) {
        this.propsInject = props;
    }

    private getApplicationStore(): ApplicationStore {
        return Container.get(ApplicationStore);
    }

    private getSnackBarStore(): SnackBarStore {
        return this.getApplicationStore().getStore(SnackBarStore);
    }

    public getSnackBar(): SnackBar {
        return this.getSnackBarStore().getSnackBar();
    }

    public printSnackBar() {
        const COMPONENT = this.getSnackBar().getCurrentSnackBar();

        return COMPONENT ? <COMPONENT {...this.getPropsInject()}/> : COMPONENT;
    }

    public open<T extends ElementType>(T: React.ComponentType, props: React.ComponentProps<T>) {
        this.closeSnackBar();
        this.setPropsInject(props);
        this.getSnackBar()
            .setOpen(true)
            .setCurrentSnackBar(T);
    }

    public closeSnackBar = () => {
        if (this.getSnackBar())
            this.getSnackBar()
                .setCurrentSnackBar(undefined)
                .setOpen(false);

        this.setPropsInject({});
    };
}

export default SnackBarService;
