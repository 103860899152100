import {
    inject,
    observer
}                                from "mobx-react";
import { CarStore }              from "../../Store/CarStore";
import { UserStore }             from "../../Store/UserStore";
import { FormStore }             from "../../Store/FormStore";
import * as React                from "react";
import ReviewOrderView           from "../../Views/Pages/Car/ReviewOrderView";
import { Redirect }              from "react-router";
import {
    PageNames,
    renderRoute
}                                from "../../Routes/routes";
import { observable }            from "mobx";
import { Container }             from "typedi";
import ModalService              from "../../Service/ModalService";
import ModalGenerateOrderSuccess from "../../Views/Components/Modal/ModalGenerateOrderSuccess";

interface IReviewOrderViewModelProps {
    CarStore?: CarStore;
    FormStore?: FormStore;
    UserStore?: UserStore;
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE, FormStore.NAME_STORE)
@observer
export class ReviewOrderViewModel extends React.Component<IReviewOrderViewModelProps, {}> {
    @observable
    private send: boolean = false;

    @observable
    private sending: boolean = false;

    @observable
    private error: string = "";

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    public componentDidMount = () => {
        this.carStore.getOrder().setProductStore(this.carStore.getProductStore());
    };

    protected closeModal = () => {
        // @ts-ignore
        this.carStore.setOrder(undefined);
        // @ts-ignore
        this.carStore.setCalculateShipping(undefined);
        this.carStore.setProductStore([]);
    };

    protected generateOrder = async () => {
        this.sending = true;

        this.carStore.getOrder().setProductStore(this.carStore.getProductStore());
        const order = await this.carStore.generateOrder(this.carStore.getOrder(), this.carStore.getCalculateShipping());

        this.sending = false;

        // @ts-ignore
        if (order.status && order.status !== 500) {
            this.send = true;
            Container.get(ModalService).openModal(ModalGenerateOrderSuccess, {
                closeModal: this.closeModal,
                order     : this.carStore.getOrder()
            });

            return;
        }

        this.error = "Datos de la tarjeta incorrectos";
    };

    public render(): React.ReactNode {
        if (!this.carStore.getOrder()) {
            return (<Redirect to={{
                pathname: renderRoute(PageNames.carPage)
            }}/>);
        }

        return (
            <ReviewOrderView
                order={this.carStore.getOrder()}
                productItemCars={this.carStore.getProductStore()}
                generateOrder={this.generateOrder}
                send={this.send}
                sending={this.sending}
                error={this.error}
                calculateShipping={this.carStore.getCalculateShipping()}
            />
        );
    }
}

export default ReviewOrderViewModel;
