import {
    withNamespaces,
    WithNamespaces
} from "react-i18next";
import InjectPrefix from "../../../Decorators/InjectPrefix";
import { observer } from "mobx-react";
import * as React from "react";
import {
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@material-ui/core";
import NumberFormat from "../NumberFormat/NumberFormat";

interface ITableProductsFooterProps extends WithNamespaces {
    hideTransport: boolean;
    subTotal: number;
    total: number;
    shipping: number;
    prefix?: string;
}

@InjectPrefix
@observer
class TableProductsFooter extends React.Component<ITableProductsFooterProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public renderDesktop = (): React.ReactNode => {
        const {
                  t,
                  hideTransport,
                  total,
                  shipping,
                  subTotal,
              } = this.props;
        return (
            <React.Fragment key="total">
                {
                    hideTransport
                        ? <React.Fragment/>
                        : <TableRow className="item total" key="shipping">
                            <TableCell className="">
                                &nbsp;
                            </TableCell>
                            <TableCell className="">
                                &nbsp;
                            </TableCell>
                            <TableCell className="">
                                &nbsp;
                            </TableCell>
                            <TableCell align="left">
                                {t("car:shipping")}
                            </TableCell>
                            <TableCell align="right">
                                <NumberFormat
                                    value={shipping}
                                />
                            </TableCell>
                        </TableRow>
                }
                <TableRow className="item total" key="subtotal">
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell align="left">
                        {t("car:subtotal")}
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={subTotal}
                        />
                    </TableCell>
                </TableRow>
                <TableRow className="item total" key="total">
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell className="">
                        &nbsp;
                    </TableCell>
                    <TableCell align="left">
                        {t("car:total")}
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={total}
                        />
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    public renderMobile = (): React.ReactNode => {
        const {
                  t,
                  hideTransport,
                  total,
                  shipping,
                  subTotal,
              } = this.props;
        return (
            <Table className={`${this.prefix}-table-products`} key="total">
                <TableBody>
                    {
                        hideTransport
                            ? <React.Fragment/>
                            : <TableRow className="item total" key="shipping">
                                <TableCell align="right">
                                    {t("car:shipping")}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        value={shipping}
                                    />
                                </TableCell>
                            </TableRow>
                    }
                    <TableRow className="item total" key="subtotal">
                        <TableCell align="right">
                            {t("car:subtotal")}
                        </TableCell>
                        <TableCell>
                            <NumberFormat
                                value={subTotal}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow className="item total" key="total">
                        <TableCell align="right">
                            {t("car:total")}
                        </TableCell>
                        <TableCell>
                            <NumberFormat
                                value={total}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    public render = (): React.ReactNode => {
        return (
            <>
                <Hidden only={["md", "lg", "xl"]}>
                    {this.renderMobile()}
                </Hidden>
                <Hidden only={["xs", "sm"]}>
                    {this.renderDesktop()}
                </Hidden>
            </>
        );
    };
}

export default withNamespaces("car")(TableProductsFooter);