import { observer }                                      from "mobx-react";
import * as React                                        from "react";
import { Collapse, Container }                           from "reactstrap";
import { withNamespaces, WithNamespaces }                from "react-i18next";
import ErrorOutlineIcon                                  from "@material-ui/icons/ErrorOutline";
import { Button, TableCell, TableRow, CircularProgress } from "@material-ui/core";
import SimpleReactValidator                              from "simple-react-validator";
import InjectPrefix                                      from "../../../Decorators/InjectPrefix";
import User                                              from "../../../Models/User/User";
import { Order }                                         from "../../../Models/Order/Order";
import { CollapseStep }               from "../../../ViewModel/Car/SelectDirectionViewModel";
import ChooseDirectionViewModel       from "../../../ViewModel/Car/ChooseDirectionViewModel";
import ChooseMethodTransportViewModel from "../../../ViewModel/Car/ChooseMethodTransportViewModel";
import { ProductItemCar }             from "../../../Models/ProductItemCar/ProductItemCar";
import ChooseMethodToPayViewModel     from "../../../ViewModel/Car/ChooseMethodToPayViewModel";
import { ERROR_CLASS }                from "../../../Store/FormStore";
import { CalculateShipping }          from "../../../Models/CalculateShipping/CalculateShipping";
import ChooseBillElectronicViewModel  from "../../../ViewModel/Car/ChooseBillElectronicViewModel";
import TableProducts                  from "../../Components/Table/TableProducts";
import TableProductsFooter            from "../../Components/Table/TableProductsFooter";
import { ShowDirectionData }          from "../../Components/Internal/ShowDirectionData";
import { Product }                    from "../../../Models/Product/Product";

interface ISelectDirectionViewProps extends WithNamespaces {
    user: User;
    order: Order;
    collapseStep: CollapseStep;
    productItemCars: ProductItemCar[];
    removeProduct: (product: Product) => ProductItemCar[];
    setCollapseStep: (val: CollapseStep) => void;
    continueAction: () => void;
    validator: SimpleReactValidator;
    calculateShipping: CalculateShipping;
    loadingCalculateShipping: boolean;
    subTotalProducts: number;
    prefix?: string;
    purgeValidator: () => void;
}

@InjectPrefix
@observer
class SelectDirectionView extends React.Component<ISelectDirectionViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected onChangeCollapse = (option: CollapseStep) => {
        const {setCollapseStep, collapseStep} = this.props;

        setCollapseStep(collapseStep === option ? CollapseStep.EMPTY : option);
    };

    protected showChoseDataBillElectronic = (): React.ReactNode => {
        const {order} = this.props;

        if (!order.getIsBillElectronic()) {
            return (<React.Fragment/>);
        }

        const {
                  collapseStep,
                  validator,
                  t
              } = this.props;
        validator.message("billElectronic", order.getDirection(), "required");

        return (
            <>
                <div className="header-option">
                    <button
                        onClick={() => {
                            this.onChangeCollapse(CollapseStep.DATA_BILL_ELECTRONIC);
                        }}
                    >
                        {t(`order.${CollapseStep.DATA_BILL_ELECTRONIC}`)}
                    </button>
                </div>
                <Collapse
                    isOpen={collapseStep === CollapseStep.DATA_BILL_ELECTRONIC}
                >
                    <ChooseBillElectronicViewModel/>
                    {
                        !validator.fieldValid("billElectronic") &&
                        (
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:field-bill-electronic")}
                            </div>
                        )
                    }
                </Collapse>
            </>
        );
    };

    protected chooseDirection = () => {
        const {
                  collapseStep,
                  validator,
                  t,
                  order,
              }        = this.props,
              isPickup = order.getMethodTransport() && order.getMethodTransport().getPickUp();

        if (!isPickup)
            validator.message("direction", order.getDirection(), "required");

        return (
            <>
                <div className="header-option">
                    <button
                        onClick={() => {
                            this.onChangeCollapse(CollapseStep.CHOOSE_DIRECTION);
                        }}
                    >
                        {t(`order.${CollapseStep.CHOOSE_DIRECTION}`)}
                    </button>
                </div>
                <Collapse
                    isOpen={collapseStep === CollapseStep.CHOOSE_DIRECTION}
                >
                    <ChooseDirectionViewModel/>
                    {
                        !isPickup && !validator.fieldValid("direction") &&
                        (
                            <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                                <ErrorOutlineIcon/> {t("validator:field-direction")}
                            </div>
                        )
                    }
                </Collapse>
            </>
        );
    };

    protected renderTotal = () => {
        const {
                  calculateShipping,
                  subTotalProducts,
                  order,
              } = this.props;

        if (!calculateShipping || Array.isArray(calculateShipping)) return (<React.Fragment/>);

        const priceShipping = order.getMethodTransport() && order.getMethodTransport().getPickUp() ? 0 : calculateShipping.getPrice();

        return (
            <TableProductsFooter
                hideTransport={priceShipping === 0}
                shipping={priceShipping}
                subTotal={subTotalProducts}
                total={subTotalProducts + priceShipping}
            />
        );
    };

    public render(): React.ReactNode {
        const {
                  collapseStep,
                  productItemCars,
                  removeProduct,
                  t,
                  continueAction,
                  calculateShipping,
                  validator,
                  order,
                  loadingCalculateShipping,
                  purgeValidator,
              }               = this.props,
              methodTransport = order.getMethodTransport();

        return (
            <div className={`${this.prefix}-p-select-directions`}>
                <Container>
                    {this.chooseDirection()}
                    <div className="header-option">
                        <button
                            onClick={() => {
                                this.onChangeCollapse(CollapseStep.METHOD_TRANSPORT);
                            }}
                        >
                            {t(`order.${CollapseStep.METHOD_TRANSPORT}`)}
                        </button>
                    </div>
                    <Collapse
                        isOpen={collapseStep === CollapseStep.METHOD_TRANSPORT}
                    >
                        <div>
                            <ChooseMethodTransportViewModel purgeValidator={purgeValidator}/>
                            {
                                methodTransport
                                && !methodTransport.getPickUp()
                                && validator.message(t("car:method-transport"), order.getMethodTransport(), "required")
                            }
                        </div>
                    </Collapse>
                    <div className="header-option">
                        <button
                            onClick={() => {
                                this.onChangeCollapse(CollapseStep.METHOD_PAY);
                            }}
                        >
                            {t(`order.${CollapseStep.METHOD_PAY}`)}
                        </button>
                    </div>
                    <Collapse
                        isOpen={collapseStep === CollapseStep.METHOD_PAY}
                    >
                        <div>
                            <ChooseMethodToPayViewModel purgeValidator={purgeValidator}/>
                            {validator.message(t("car:method-pay"), order.getMethodPay(), "required")}
                        </div>
                    </Collapse>
                    {this.showChoseDataBillElectronic()}
                    <hr/>
                    <h2 className="title">
                        {t("order.my-car")}
                    </h2>
                    <TableProducts
                        productItemCars={productItemCars}
                        removeProduct={removeProduct}
                        hideTotal
                        children={!loadingCalculateShipping ? this.renderTotal() : undefined}
                    />
                    {loadingCalculateShipping ? <CircularProgress color="secondary"/> :
                     <ShowDirectionData calculateShipping={calculateShipping}/>}
                    <div className="cta-container">
                        <Button
                            className="btn-primary"
                            onClick={continueAction}
                            color="primary"
                            variant="contained"
                        >
                            {t("car:continue")}
                        </Button>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withNamespaces("page")(SelectDirectionView);