import InjectPrefix       from "../../../Decorators/InjectPrefix";
import * as React         from "react";
import { Col, Row }       from "reactstrap";
import BuildIcon          from "@material-ui/icons/Build";
import LocalShippingIcon  from "@material-ui/icons/LocalShipping";
import MailIcon           from "@material-ui/icons/Mail";

interface IOurServicesProps {
    prefix?: string;
}

const sizeIcon = 60;

@InjectPrefix
export class OurServices extends React.Component<IOurServicesProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        return (
            <Row className={"out-services"}>
                <Col xs={12} sm={4} lg={4}>
                    <div className="item row">
                        <div className={`col-4 ${this.prefix}-secondary-color icon`}>
                            <BuildIcon style={{fontSize: sizeIcon}}/>
                        </div>
                        <div className="col-8 content">
                            <h4 className={`${this.prefix}-secondary-color mb-3`}>
                                Instalamos
                            </h4>
                            <p>
                                Con nuestro respaldo, los proveedores certificados instalan 190 líneas de
                                nuestros productos.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4} lg={4}>
                    <div className="item row">
                        <div className={`col-4 ${this.prefix}-secondary-color icon`}>
                            <LocalShippingIcon style={{fontSize: sizeIcon}}/>
                        </div>
                        <div className="col-8 content">
                            <h4 className={`${this.prefix}-secondary-color mb-3`}>
                                Transportamos
                            </h4>
                            <p>
                                Brindamos el servicio de transporte. Nos encargamos de trasladar sus
                                compras.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4} lg={4}>
                    <div className="item row">
                        <div className={`col-4 ${this.prefix}-secondary-color icon`}>
                            <MailIcon style={{fontSize: sizeIcon}}/>
                        </div>
                        <div className="col-8 content">
                            <h4 className={`${this.prefix}-secondary-color mb-3`}>
                                Cotizamos
                            </h4>
                            <p>
                                Realizamos cualquier cotización formal de precio para renovar su hogar.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}
