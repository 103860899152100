import * as React                                                 from "react";
import { observer }                                               from "mobx-react";
import User                                                       from "../../../Models/User/User";
import InjectPrefix                                               from "../../../Decorators/InjectPrefix";
import { Col, Container, Row }                                    from "reactstrap";
import { withNamespaces, WithNamespaces }                         from "react-i18next";
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Add as AddIcon }                                         from "@material-ui/icons";
import FormDirectionViewModel
                                                                  from "../../../ViewModel/FormDirection/FormDirectionViewModel";
import { Direction }                                              from "../../../Models/User/Direction";
import CardDirectionViewModel                                     from "../../../ViewModel/Card/CardDirectionViewModel";
import { Order }                                                  from "../../../Models/Order/Order";
import TableOrderViewModel                                        from "../../../ViewModel/Order/TableOrderViewModel";
import CircularProgress                                           from "@material-ui/core/CircularProgress";

interface IMyDirectionsViewProps extends WithNamespaces {
    user: User;
    orders: Order[];
    loading: boolean;
    prefix?: string;
}

@InjectPrefix
@observer
class MyOrdersView extends React.Component<IMyDirectionsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  user,
                  orders,
                  loading,
              } = this.props;

        return (
            <div className={`${this.prefix}-p-my-orders`}>
                <Container>
                    <h1 className="text-center w-100 mt-5 mb-5 d-block">{t("order.my-orders")}</h1>
                    {
                        loading
                        ? <CircularProgress color="secondary"/>
                        : <TableOrderViewModel orders={orders} user={user}/>
                    }
                </Container>
            </div>
        );
    }
}

export default withNamespaces("page")(MyOrdersView);