import * as React           from "react";
import PromotionsView       from "../Views/Pages/PromotionsView";
import { inject, observer } from "mobx-react";
import { PromotionStore }   from "../Store/PromotionStore";
import { GalleryStore }     from "../Store/GalleryStore";
import { Gallery }          from "../Models/Gallery/Gallery";
import { observable }       from "mobx";
import { galleryPages }     from "../config/GalleryPages";
import { Page }             from "../Models/Page/Page";
import { PageStore }        from "../Store/PageStore";
import { pages }            from "../config/Pages";

interface ProfileViewModelProps {
    PromotionStore?: PromotionStore;
    GalleryStore?: GalleryStore;
    PageStore?: PageStore;
}

@inject(PromotionStore.NAME_STORE, GalleryStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class PromotionsViewModel extends React.Component<ProfileViewModelProps, {}> {
    @observable
    private gallery: Gallery;

    @observable
    private page: Page | undefined;

    get promotionStore(): PromotionStore {
        return this.props.PromotionStore as PromotionStore;
    }

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    constructor(props: ProfileViewModelProps) {
        super(props);
        this.promotionStore.setPromotions([]);
    }

    private showMore = () => {
        this.getPage(this.promotionStore.getCurrentPage() + 1);
    };

    protected async getGalleryPage() {
        this.gallery = await this.galleryStore.getGalleryPage(galleryPages.promotions);
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
        this.getGalleryPage();
        this.page = await this.pageStore.getPage(pages.promotions);
    };

    public getPage = async (page: number) => {
        this.promotionStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.promotionStore.getPaginate();

        this.promotionStore
            .setPages(response.pages)
            .setPromotions([...this.promotionStore.getPromotions(), ...response.data])
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        return (
            <PromotionsView
                promotions={this.promotionStore.getPromotions()}
                showMore={this.showMore}
                loading={this.promotionStore.getSpinner()}
                currentPage={this.promotionStore.getCurrentPage()}
                pages={this.promotionStore.getPages()}
                gallery={this.gallery}
                page={this.page}
            />
        );
    }
}

export default PromotionsViewModel;
