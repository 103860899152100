import BaseStore                  from "./Base/BaseStore";
import { Register }               from "../Models/Register/Register";
import User                       from "../Models/User/User";
import { deserialize, serialize } from "serializr";
import AjaxService                from "../Service/AjaxService";
import Container                  from "typedi";
import { ForgetPassword }         from "../Models/ForgetPassword/ForgetPassword";

export class RegisterStore extends BaseStore {
    public static readonly NAME_STORE: string = "RegisterStore";

    protected init() {
        this.needPersistData = false;
    }

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async registerUser(register: Register): Promise<User> {
        const {data: {data}} = await this.getAjaxService().postRegister(serialize(register));

        return deserialize(User, data);
    }

    public async existEmail(email: string): Promise<boolean> {
        const {data: {data}} = await this.getAjaxService().getExistUserByEmail(email);

        return data;
    }

    public async forgetPassword(email: string): Promise<boolean> {
        const {data: {data}} = await this.getAjaxService().postForgetPassword(email);

        return data;
    }

    public async getUserByToken(token: string): Promise<User | undefined> {
        const {data: {data, success}} = await this.getAjaxService().getUserByToken(token);

        if (!success) return undefined;

        return deserialize(User, data);
    }

    public async changePassword(forgetPassword: ForgetPassword): Promise<User> {
        const {data: {data}} = await this.getAjaxService().putChangePassword(serialize(forgetPassword));

        return deserialize(User, data);
    }
}
