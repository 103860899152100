import { inject, observer } from "mobx-react";
import * as React           from "react";
import { ProductStore }     from "../../Store/ProductStore";
import OrderProductsView    from "../../Views/Components/OrderProducts/OrderProductsView";

interface IOrderProductsViewModelProps {
    ProductStore?: ProductStore;
    search: () => void;
}

@inject(ProductStore.NAME_STORE)
@observer
class OrderProductsViewModel extends React.Component<IOrderProductsViewModelProps, {}> {
    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    public render(): React.ReactNode {
        const {search} = this.props;

        return (
            <OrderProductsView
                principalSearch={this.productStore.getPrincipalSearch()}
                search={search}
            />
        );
    }
}

export default OrderProductsViewModel;
