import { serializable } from "serializr";
import { observable }   from "mobx";

export interface IColor {
    _id: string;
    code: string;
    name: string;
    rgb: string;
}

export class Color {
    public static readonly NONE: Color = new Color();

    @serializable
    @observable
    private code: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private rgb: string = "";

    public getCode(): string {
        return this.code;
    }

    public setCode(value: string): this {
        this.code = value;

        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): this {
        this.name = value;

        return this;
    }

    public getRgb(): string {
        return this.rgb;
    }

    public setRgb(value: string): this {
        this.rgb = value;

        return this;
    }
}
