import { inject, observer } from "mobx-react";
import * as React           from "react";
import ChooseDirectionView  from "../../Views/Pages/Car/ChooseDirectionView";
import { CarStore }         from "../../Store/CarStore";
import { UserStore }        from "../../Store/UserStore";
import User                 from "../../Models/User/User";

interface IChooseDirectionViewModelProps {
    CarStore?: CarStore;
    UserStore?: UserStore;
    prefix?: string;
}

@inject(CarStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class ChooseDirectionViewModel extends React.Component<IChooseDirectionViewModelProps, {}> {
    constructor(props: IChooseDirectionViewModelProps) {
        super(props);
        this.userStore.setNewDirection(false);
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected get carStore(): CarStore {
        return this.props.CarStore as CarStore;
    }

    public render(): React.ReactNode {
        const {} = this.props;

        return (
            <ChooseDirectionView
                user={this.userStore.getUser() as User}
                newDirection={this.userStore.getNewDirection()}
                setNewDirection={this.userStore.setNewDirection}
                order={this.carStore.getOrder()}
            />
        );
    }
}

export default ChooseDirectionViewModel;