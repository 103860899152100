import { inject, observer }       from "mobx-react";
import { UserStore }              from "../../Store/UserStore";
import { ERROR_CLASS, FormStore } from "../../Store/FormStore";
import { RegisterStore }          from "../../Store/RegisterStore";
import * as React                 from "react";
import AuthService                from "../../Service/AuthService";
import { Container }              from "typedi";
import ForgetPasswordFormView     from "../../Views/Pages/ForgetPassword/ForgetPasswordFormView";
import { observable }             from "mobx";
import SimpleReactValidator       from "simple-react-validator";
import {
    withNamespaces,
    WithNamespaces
}                                 from "react-i18next";

interface IForgetPasswordFormViewModelProps extends WithNamespaces {
    UserStore?: UserStore;
    FormStore?: FormStore;
    RegisterStore?: RegisterStore;
}

@inject(
    UserStore.NAME_STORE,
    FormStore.NAME_STORE,
    RegisterStore.NAME_STORE
)
@observer
class ForgetPasswordFormViewModel extends React.Component<IForgetPasswordFormViewModelProps, {}> {
    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private email: string = "";

    @observable
    private sending: boolean = false;

    @observable
    private response: boolean = false;

    @observable
    private sent: boolean = false;

    protected getEmail = (): string => {
        return this.email;
    };

    protected setEmail = (value: string) => {
        this.email = value;
    };

    protected get authService(): AuthService {
        return Container.get(AuthService);
    }

    protected get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    protected get registerStore(): RegisterStore {
        return this.props.RegisterStore as RegisterStore;
    }

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    constructor(props: IForgetPasswordFormViewModelProps) {
        super(props);

        const {t} = this.props;

        this.validator = this.formStore.getNewValidator(t);
    }

    protected submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate();

            return;
        }

        this.sending  = true;
        this.response = await this.registerStore.forgetPassword(this.getEmail());
        this.sent     = true;
        this.sending  = false;
    };

    public render(): React.ReactNode {
        return (
            <ForgetPasswordFormView
                validator={this.validator}
                submit={this.submit}
                email={this.email}
                sending={this.sending}
                setEmail={this.setEmail}
                response={this.response}
                sent={this.sent}
            />
        );
    }
}

export default withNamespaces("forms")(ForgetPasswordFormViewModel);