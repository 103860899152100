enum ErrorsCards {
    "Card's security code is not incorrect."                                        = 1,
    "Your card has expired. Please review it with your Bank."                       = 2,
    "Your card was declined. Please review it with your Bank."                      = 3,
    "Your card number is incorrect. Please check the values."                       = 4,
    "AMEX is not supported for CRC currency. You must use Visa or MasterCard card"  = 5,
    "There was an error procesing your payment. Please check all given information" = 6,
    "Card's expiration month is not valid." = 6,
    "Exceeds your limit amount for charge" = 7,
}

export {
    ErrorsCards
};