const variables: any = require("../../styles/core/variables.scss");

const scssConfiguration = {
    prefix         : variables.prefix,
    primaryColor   : variables.primaryColor,
    quaternaryColor: variables.quaternaryColor,
    secondaryColor : variables.secondaryColor,
    tertiaryColor  : variables.tertiaryColor,

    screenLgDevices: parseInt(variables.screenLgDevices.replace("px", ""), 10),
    screenMdDevices: parseInt(variables.screenMdDevices.replace("px", ""), 10),
    screenSmDevices: parseInt(variables.screenSmDevices.replace("px", ""), 10),
    screenXlDevices: parseInt(variables.screenXlDevices.replace("px", ""), 10),
};

export default scssConfiguration;
