import * as React                  from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Hidden }                  from "@material-ui/core";
import { ModalLayout }             from "../Layout/ModalLayout";
import InjectPrefix                from "../../../Decorators/InjectPrefix";
import { GalleryItem }             from "../../../Models/Gallery/GalleryItem";

interface ModalImageProps {
    prefix?: string;
    image: GalleryItem;
    next: () => void;
    back: () => void;
    showNext: boolean;
    showBack: boolean;
    position: number;
    length: number;
}

@InjectPrefix
export class ModalImage extends React.Component<ModalImageProps, {}> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  image,
                  next,
                  back,
                  showNext,
                  showBack,
                  position,
                  length
              } = this.props;

        return (
            <ModalLayout className={`${this.prefix}-modal-image`}>
                <Hidden only="xs">
                    <img src={image.getImageFullPath()} className="img-fluid"/>
                </Hidden>
                <Hidden smUp>
                    <img src={image.getImageFullPath()} className="img-fluid"/>
                </Hidden>
                <span className="position">{`${position} / ${length}`}</span>
                <div className="controls">
                    {showBack ? <ArrowBack className="arrow back" onClick={back}>
                        arrow_back
                    </ArrowBack> : <div/>}
                    {showNext ? <ArrowForward className="arrow next" onClick={next}>
                        arrow_forward
                    </ArrowForward> : <div/>}
                </div>
            </ModalLayout>
        );
    }
}
