import * as React             from "react";
import { Switch, withRouter } from "react-router-dom";
import { routesList }          from "../../../Routes/routes";
import Route                   from "../Route/Route";
import HeaderViewModel         from "../../../ViewModel/Header/HeaderViewModel";
import FooterViewModel         from "../../../ViewModel/Footer/FooterViewModel";
import { ThemeProvider }       from "@material-ui/styles";
import { theme }               from "../../../resources/generator/theme";
import Error404ViewModel       from "../../../ViewModel/Error/Error404ViewModel";
import ManageModalViewModel    from "../../../ViewModel/ManageModal/ManageModalViewModel";
import { inject }              from "mobx-react";
import { ConfigurationStore }  from "../../../Store/ConfigurationStore";
import { body }                from "../../../Utils/common";
import ManageSnackBarViewModel from "../../../ViewModel/ManageSnackBar/ManageSnackBarViewModel";

class ScrollToTop extends React.Component<any, any> {
    public componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location)
            window.scrollTo(0, 0);
    }

    public render(): React.ReactNode {
        return this.props.children;
    }
}

// tslint:disable-next-line:variable-name
const ScrollToTopRouter = withRouter(ScrollToTop);

interface ILayoutProps {
    ConfigurationStore?: ConfigurationStore;
}

@inject(ConfigurationStore.NAME_STORE)
class Layout extends React.Component<ILayoutProps, {}> {
    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    protected handleResize = () => {
        this.configurationStore.setSizeWidth(body.clientWidth);
    };

    public componentDidMount = () => {
        window.addEventListener("resize", this.handleResize, false);
        this.handleResize();
    };

    public componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize, false);
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <main>
                    <ScrollToTopRouter>
                        <ThemeProvider theme={theme}>
                            <HeaderViewModel/>
                            <Switch>
                                {routesList.map((page, key) =>
                                                    <Route
                                                        exact={page.exact}
                                                        auth={page.auth}
                                                        path={page.path}
                                                        key={page.name}
                                                        name={page.name}
                                                        component={page.component}
                                                        middleware={page.middleware}
                                                        options={page.options}
                                                    />
                                )}
                                <Route component={Error404ViewModel}/>
                            </Switch>
                            <FooterViewModel/>
                            <ManageModalViewModel/>
                            <ManageSnackBarViewModel/>
                        </ThemeProvider>
                    </ScrollToTopRouter>
                </main>
            </React.Fragment>
        );
    }
}

export default Layout;
