import { observer }                       from "mobx-react";
import * as React                         from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Hidden,
}                                         from "@material-ui/core";
import { Search, ExpandMore }             from "@material-ui/icons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Category }                       from "../../../Models/Product/Category";

interface ICategoriesProps extends WithNamespaces {
    categories: Category[];
    addCategory: (category: Category) => void;
    removeCategory: (category: Category) => void;
    existCategory: (category: Category) => boolean;
    search: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class Categories extends React.Component<ICategoriesProps, {}> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected actionSubCategory = (subCategory: Category) => {
        const {
                  addCategory,
                  removeCategory,
                  existCategory,
                  search,
              } = this.props;

        if (existCategory(subCategory)) {
            removeCategory(subCategory);
            search();
            return;
        }

        addCategory(subCategory);
        search();
    };

    protected renderSubCategories = (subCategories: Category[]): React.ReactNode => {
        if (subCategories.length <= 0) { return (<React.Fragment/>); }

        const {
                  existCategory,
                  t,
                  i18n,
              } = this.props;

        return (
            <List>
                {
                    subCategories.map((subCategory: Category, index: number) => {
                        const key  = `categories:${subCategory.getName().toLowerCase()}`,
                              text = i18n.exists(key)
                                     ? t(key)
                                     : subCategory.getName();

                        return (
                            <ListItem
                                key={`${subCategory.getId()}-${index}`}
                                role={undefined}
                                dense
                                button
                                onClick={() => this.actionSubCategory(subCategory)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={existCategory(subCategory)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{"aria-labelledby": subCategory.getId()}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={subCategory.getId()} primary={text}/>
                            </ListItem>
                        );
                    })
                }
            </List>

        );
    };

    protected categoriesView = (): React.ReactNode => {
        const {categories, t, i18n} = this.props;

        return categories.map(category => {
            const subCategories = category.getCategories(),
                  key           = `categories:${category.getName().toLowerCase()}`,
                  text          = i18n.exists(key)
                                  ? t(key)
                                  : category.getName();

            return (
                <Accordion key={category.getName()} style={category.getStyle()}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {text}
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.renderSubCategories(subCategories)}
                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    public render(): React.ReactNode {
        const {
                  t,
                  search,
              }              = this.props,
              categoriesView = this.categoriesView();

        return (
            <div className={`${this.prefix}-categories-list`}>
                <div className="d-md-none">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {t("categories")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="category-mobile">
                                {categoriesView}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className="btn-search"
                        onClick={search}
                    >
                        {t("forms:search")} <Search className="icon"/>
                    </Button>
                </div>
                <div className="d-none d-md-block">
                    <h4 className="title">{t("categories")}</h4>
                    {categoriesView}
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className="btn-search"
                        onClick={search}
                    >
                        {t("forms:search")} <Search className="icon"/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default withNamespaces("global")(Categories);