import { alias, date, object, serializable } from "serializr";
import User                                  from "../User/User";

class AccessToken {
    @serializable
    private _id: string;

    @serializable(alias("access_token"))
    private accessToken: string;

    @serializable(date())
    private accessTokenExpiresAt: Date;

    @serializable(object(User))
    private user: User;

    @serializable(date())
    private createdAt: Date;

    @serializable(date())
    private updatedAt: Date;

    public constructor(accessToken?: AccessToken) {
        if (!accessToken)
            return;

        Object.assign(this, accessToken);
    }


    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getAccessToken(): string {
        return this.accessToken;
    }

    public setAccessToken(value: string) {
        this.accessToken = value;
    }

    public getAccessTokenExpiresAt(): Date {
        return this.accessTokenExpiresAt;
    }

    public setAccessTokenExpiresAt(value: Date) {
        this.accessTokenExpiresAt = value;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User) {
        this.user = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }
}

export default AccessToken;
