import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import Container                          from "@material-ui/core/Container";
import ReactPlayer                        from "react-player";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import TutorialDetailPresentation         from "../Components/Presentation/TutorialDetailPresentation";
import { Tutorial }                       from "../../Models/Tutorial/Tutorial";
import { GalleryListViewModel }           from "../../ViewModel/Gallery/GalleryListViewModel";
import Seo                                from "../Components/Header/Seo";

interface TutorialViewProps extends WithNamespaces {
    prefix?: string;
    tutorial: Tutorial;
}

@InjectPrefix
class TutorialDetailView extends React.Component<TutorialViewProps, any> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {tutorial} = this.props,
              idYt       = tutorial.getYoutubeId() || "yC_yYFOUh9Y";

        return (
            <Container className={`${this.prefix}-p-tutorial-detail`}>
                <Seo seoData={tutorial.getSeoData()}/>
                {tutorial && <TutorialDetailPresentation tutorial={tutorial}/>}
                <ReactPlayer
                    controls={true}
                    className="mb-2"
                    url={`//www.youtube.com/embed/${idYt}`}
                    width="100%"
                    youtubeConfig={
                        {
                            playerVars: {
                                enablejsapi: 1,
                                origin     : location.origin,
                                showinfo   : 0
                            }
                        }
                    }
                />
                <div
                    dangerouslySetInnerHTML={{__html: tutorial.getDescription() || ""}}
                    className="mt-4 mb-4"
                />
                <GalleryListViewModel
                    className="mb-2"
                    gallery={tutorial.getGallery()}
                />
            </Container>
        );
    }
}

export default withNamespaces("page")(TutorialDetailView);
