import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TutorialView                        from "../Views/Pages/TutorialView";
import { TutorialStore }                   from "../Store/TutorialStore";
import { observable }                      from "mobx";
import { Gallery }                         from "../Models/Gallery/Gallery";
import { GalleryStore }                    from "../Store/GalleryStore";
import { galleryPages }                    from "../config/GalleryPages";
import { PageStore }                       from "../Store/PageStore";
import { Page }                            from "../Models/Page/Page";
import { pages }                           from "../config/Pages";

interface TutorialsViewModelProps extends RouteComponentProps<any> {
    TutorialStore?: TutorialStore;
    GalleryStore?: GalleryStore;
    PageStore?: PageStore;
}

@inject(TutorialStore.NAME_STORE, GalleryStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class TutorialsViewModel extends React.Component<TutorialsViewModelProps, {}> {
    @observable
    private gallery: Gallery;

    @observable
    private page: Page | undefined;

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    get tutorialStore(): TutorialStore {
        return this.props.TutorialStore as TutorialStore;
    }

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    constructor(props: TutorialsViewModelProps) {
        super(props);
        this.tutorialStore.setTutorials([]);
    }

    private showMore = () => {
        this.getPage(this.tutorialStore.getCurrentPage() + 1);
    };


    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
        this.getGalleryPage();
        this.page = await this.pageStore.getPage(pages.tutorials);
    };

    protected async getGalleryPage() {
        this.gallery = await this.galleryStore.getGalleryPage(galleryPages.tutorials);
    }

    public getPage = async (page: number) => {
        this.tutorialStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.tutorialStore.getPaginate();

        this.tutorialStore
            .setPages(response.pages)
            .setTutorials([...this.tutorialStore.getTutorials(), ...response.data])
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        return (
            <TutorialView
                tutorials={this.tutorialStore.getTutorials()}
                showMore={this.showMore}
                loading={this.tutorialStore.getSpinner()}
                currentPage={this.tutorialStore.getCurrentPage()}
                pages={this.tutorialStore.getPages()}
                gallery={this.gallery}
                page={this.page}
            />
        );
    }
}

export default withRouter(TutorialsViewModel);
