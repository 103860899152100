import * as React                   from "react";
import HomeView                     from "../Views/Pages/HomeView";
import { observable }               from "mobx";
import { inject, observer }         from "mobx-react";
import { IHomePageData, PageStore } from "../Store/PageStore";

interface HomeViewModelProps {
    PageStore?: PageStore;
}

@inject(PageStore.NAME_STORE)
@observer
class HomeViewModel extends React.Component<HomeViewModelProps, {}> {
    @observable
    private data: IHomePageData;

    protected get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.data = await this.pageStore.getHomePage();
    };

    public render(): React.ReactNode {
        return (
            <HomeView
                promotions={this.data ? this.data.promotions : undefined}
                products={this.data ? this.data.products : undefined}
                gallery={this.data ? this.data.gallery : undefined}
                galleryBrands={this.data ? this.data.galleryBrands : undefined}
                seoData={this.data ? (this.data.page ? this.data.page.getSeoData() : undefined) : undefined}
                page={this.data ? this.data.page : undefined}
            />
        );
    }
}

export default HomeViewModel;
