import tinycolor from "tinycolor2";

export const toState = (data: any, oldHue?: any) => {
    const color       = tinycolor(data),
          hsl         = color.toHsl(),
          hsv         = color.toHsv(),
          rgb         = color.toRgb(),
          hex         = color.toHex(),
          transparent = hex === "000000" && rgb.a === 0;

    if (hsl.s === 0) {
        hsl.h = oldHue || 0;
        hsv.h = oldHue || 0;
    }


    return {
        hex   : transparent ? "transparent" : `#${hex}`,
        hsl,
        hsv,
        oldHue: data.h || oldHue || hsl.h,
        rgb,
        source: data.source,
    };
};

export const getContrastingColor = (data: string): string => {
    if (!data) {
        return "#fff";
    }

    const col = toState(data);

    if (col.hex === "transparent") {
        return "rgba(0, 0, 0, 0.4)";
    }

    const yiq = ((col.rgb.r * 299) + (col.rgb.g * 587) + (col.rgb.b * 114)) / 1000;

    return (yiq >= 128) ? "#000" : "#fff";
};