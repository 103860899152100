import { observable } from "mobx";
import * as React     from "react";

export default class Modal {
    @observable
    private openModal: boolean;

    @observable
    private currentModal: React.ComponentType | undefined;

    public setOpenModal(openModal: boolean): this {
        this.openModal = openModal;

        return this;
    }

    public getOpenModal(): boolean {
        return this.openModal;
    }

    public setCurrentModal(currentModal: any): this {
        this.currentModal = currentModal;

        return this;
    }

    public getCurrentModal(): React.ComponentType | undefined {
        return this.currentModal;
    }
}
