import * as React               from "react";
import { Close as CloseIcon }   from "@material-ui/icons";
import { Snackbar, IconButton } from "@material-ui/core";
import { Product }              from "../../../Models/Product/Product";
import { observer }             from "mobx-react";

interface ISnackBarAddedProductViewProps {
    close: () => void;
    product: Product;
    open: boolean;
}

@observer
export class SnackBarAddedProductView extends React.Component<ISnackBarAddedProductViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  product,
                  open,
                  close
              } = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    horizontal: "left",
                    vertical  : "bottom",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={(event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    close();
                }}
                message={`Se agrego: ${product.getName()} al carrito.`}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => close()}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />

        );
    }
}

export default SnackBarAddedProductView;