import { match, Redirect } from "react-router";
import * as React          from "react";
import { IMiddleware }     from "./IMiddleware";
import * as moment         from "moment";
import Base = moment.unitOfTime.Base;

export default abstract class BaseMiddleware {
    private match: match<any>;

    abstract validate(options?: any): boolean ;

    abstract reject(props?: any): React.ReactNode ;

    public getMatch(): match<any> {
        return this.match;
    }

    public setMatch(value: match) {
        this.match = value;
    }

    public static getName(): string {
        throw "You need implement the function static getAlias on your middleware.";
    }
}