import { Product }    from "../Product/Product";
import {
    object,
    serializable
}                     from "serializr";
import { observable } from "mobx";
import { Color }      from "../Color/Color";

export class ProductItemCar {
    @serializable(object(Product))
    @observable
    private product: Product;

    @serializable
    @observable
    private productId: string;

    @serializable
    @observable
    private quantity: number;

    @serializable(object(Color))
    @observable
    private color?: Color;

    @observable
    private itemId: string;

    public getProduct(): Product {
        return this.product;
    }

    public setProduct(value: Product): this {
        this.product   = value;
        this.productId = this.getProduct().getId();

        return this;
    }

    public getProductId(): string {
        return this.productId;
    }

    public getQuantity(): number {
        return this.quantity;
    }

    public setQuantity(value: number): this {
        this.quantity = value;

        return this;
    }

    public getColor(): Color | undefined {
        return this.color;
    }

    public setColor(value?: Color): this {
        this.color = value;

        return this;
    }

    public getItemId(): string {
        return this.itemId;
    }

    public getIdentifier(): string {
        return `${this.getProductId()}-${this.getColor() ? this.getColor()?.getCode() : ""}`;
    }
}