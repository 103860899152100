import * as React from "react";

import BaseMiddleware                 from "../Middleware/Base/BaseMiddleware";
import HomeViewModel                  from "../ViewModel/HomeViewModel";
import PromotionsViewModel            from "../ViewModel/PromotionsViewModel";
import TutorialsViewModel             from "../ViewModel/TutorialsViewModel";
import PromotionViewModel             from "../ViewModel/PromotionViewModel";
import AboutUsViewModel               from "../ViewModel/AboutUsViewModel";
import ContactUsViewModel             from "../ViewModel/ContactUsViewModel";
import TutorialDetailViewModel        from "../ViewModel/Tutorial/TutorialDetailViewModel";
import RecommendationPersonsViewModel from "../ViewModel/RecomendationPersonViewModel";
import RegisterViewModel              from "../ViewModel/LoginRegister/RegisterViewModel";
import LoginViewModel                 from "../ViewModel/LoginRegister/LoginViewModel";
import ProductsViewModel              from "../ViewModel/Products/ProductsViewModel";
import ProfileViewModel               from "../ViewModel/User/ProfileViewModel";
import CarViewModel                   from "../ViewModel/Car/CarViewModel";
import MyDirectionsViewModel          from "../ViewModel/User/MyDirectionsViewModel";
import SelectDirectionViewModel       from "../ViewModel/Car/SelectDirectionViewModel";
import ReviewOrderViewModel           from "../ViewModel/Car/ReviewOrderViewModel";
import BillElectronicViewModel        from "../ViewModel/User/BillElectronicViewModel";
import MyOrdersViewModel              from "../ViewModel/User/MyOrdersViewModel";
import OrderDetailViewModel           from "../ViewModel/Order/OrderDetailViewModel";
import EditProfileViewModel           from "../ViewModel/User/EditProfileViewModel";
import ForgetPasswordFormViewModel    from "../ViewModel/FogetPassword/ForgetPasswordFormViewModel";
import ForgetPasswordViewModel        from "../ViewModel/FogetPassword/ForgetPasswordViewModel";
import PolicyPrivacyViewModel         from "../ViewModel/PolicyPrivacyViewModel";


interface Route {
    auth?: boolean;
    component: React.ComponentType;
    exact?: boolean;
    middleware?: BaseMiddleware[];
    options?: any;
    name: string;
    path: string;
}

const replacer = (tpl: string, data: any) => {
    return tpl.replace(/\w*(:\w+\??)/g, ($1, $2) => data[$2.replace(":", "")]);
};

export const renderRoute = (routeName: PageNames, params?: any): string => {
    const route = routesList.find(item => item.name === routeName);

    if (!route) return "/";

    return replacer(route.path, params || {});
};

export enum PageNames {
    contactPage              = "contact-page",
    homePage                 = "home-page",
    loginPage                = "login-page",
    forgetPasswordPage       = "forget-password-page",
    forgetPasswordChangePage = "forget-password-change-page",
    promotionPage            = "promotion-page",
    promotionsPage           = "promotions-page",
    recommendationPersonPage = "recommendation-person",
    registerPage             = "register-page",
    tutorialDetailPage       = "tutorial-page-detail",
    tutorialPage             = "tutorial-page",
    whoWePage                = "who-page",
    productsPage             = "products-page",
    productDetailPage        = "product-detaila-page",
    policyPrivacy            = "policy-privacy",
    profilePage              = "profile-page",
    editProfilePage          = "edit-profile-page",
    carPage                  = "car-page",
    myDirectionsPage         = "my-directions-page",
    myOrdersPage             = "my-orders-page",
    orderDetailPage          = "order-detail-page",
    billElectronicPage       = "bill-electronic-page",
    selectDirectionsPage     = "select-direction-page",
    reviewOrderPage          = "review-order-page",
}

export const routesList: Route[] = [
    {
        auth     : false,
        component: HomeViewModel,
        exact    : true,
        name     : PageNames.homePage,
        path     : "/",
    },
    {
        auth     : false,
        component: PromotionsViewModel,
        exact    : true,
        name     : PageNames.promotionsPage,
        path     : "/promociones",
    },
    {
        auth     : false,
        component: PolicyPrivacyViewModel,
        exact    : true,
        name     : PageNames.policyPrivacy,
        path     : "/politicas-privacidad",
    },
    {
        auth     : false,
        component: PromotionViewModel,
        exact    : true,
        name     : PageNames.promotionPage,
        path     : "/promocion/:slug",
    },
    {
        auth     : false,
        component: PolicyPrivacyViewModel,
        exact    : true,
        name     : PageNames.policyPrivacy,
        path     : "/politicas-privacidad",
    },
    {
        auth     : false,
        component: TutorialsViewModel,
        exact    : true,
        name     : PageNames.tutorialPage,
        path     : "/tutoriales",
    },
    {
        auth     : false,
        component: TutorialDetailViewModel,
        exact    : true,
        name     : PageNames.tutorialDetailPage,
        path     : "/tutorial/:slug",
    },
    {
        auth     : false,
        component: AboutUsViewModel,
        exact    : true,
        name     : PageNames.whoWePage,
        path     : "/quienes-somos",
    },
    {
        auth     : false,
        component: RecommendationPersonsViewModel,
        exact    : true,
        name     : PageNames.recommendationPersonPage,
        path     : "/personas-recomendadas",
    },
    {
        auth     : false,
        component: ContactUsViewModel,
        exact    : true,
        name     : PageNames.contactPage,
        path     : "/contactenos",
    },
    {
        auth     : false,
        component: RegisterViewModel,
        exact    : true,
        name     : PageNames.registerPage,
        path     : "/registrarme",
    },
    {
        auth     : false,
        component: LoginViewModel,
        exact    : true,
        name     : PageNames.loginPage,
        path     : "/login",
    },
    {
        auth     : false,
        component: ForgetPasswordViewModel,
        exact    : true,
        name     : PageNames.forgetPasswordChangePage,
        path     : "/olvido-su-contrasena/:token",
    },
    {
        auth     : false,
        component: ForgetPasswordFormViewModel,
        exact    : true,
        name     : PageNames.forgetPasswordPage,
        path     : "/olvido-su-contrasena",
    },
    {
        auth     : false,
        component: ProductsViewModel,
        exact    : false,
        name     : PageNames.productDetailPage,
        path     : "/productos/:slug?",
    },
    {
        auth     : false,
        component: ProductsViewModel,
        exact    : false,
        name     : PageNames.productsPage,
        path     : "/productos",
    },
    {
        auth      : true,
        component : ProfileViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.profilePage,
        path      : "/mi-perfil",
    },
    {
        auth      : true,
        component : EditProfileViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.editProfilePage,
        path      : "/editar-perfil",
    },
    {
        auth      : false,
        component : CarViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.carPage,
        path      : "/mi-carrito",
    },
    {
        auth      : true,
        component : MyDirectionsViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.myDirectionsPage,
        path      : "/mis-direcciones",
    },
    {
        auth      : true,
        component : MyOrdersViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.myOrdersPage,
        path      : "/mis-ordenes",
    },
    {
        auth      : true,
        component : OrderDetailViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.orderDetailPage,
        path      : "/orden/:identifier",
    },
    {
        auth      : true,
        component : BillElectronicViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.billElectronicPage,
        path      : "/datos/factura-electronica",
    },
    {
        auth      : true,
        component : SelectDirectionViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.selectDirectionsPage,
        path      : "/detalles-carrito",
    },
    {
        auth      : true,
        component : ReviewOrderViewModel,
        exact     : true,
        middleware: [],
        name      : PageNames.reviewOrderPage,
        path      : "/confirmar-orden",
    },
];
