import { observable }                    from "mobx";
import { list, primitive, serializable } from "serializr";
import { Category }                      from "./Category";
import { contains, removeItem }          from "../../Utils/array";

export type ISort = "order" | "name" | "createdAt";

export class PrincipalSearch {
    @serializable
    @observable
    private page: number;

    @serializable
    @observable
    private pageSize: number;

    @serializable(list(primitive()))
    @observable
    private categories: string [] = [];

    @serializable(list(primitive()))
    @observable
    private subCategories: string [] = [];

    @observable
    private subCategoriesMap: Map<string, Category> = new Map<string, Category>();

    @serializable
    @observable
    private text: string;

    @serializable
    @observable
    private sort: ISort = "order";

    @serializable
    @observable
    private sortDirection: number = 1;

    public getPage(): number {
        return this.page;
    }

    public setPage(value: number) {
        this.page = value;
    }

    public getSort(): ISort {
        return this.sort;
    }

    public setSort(value: ISort) {
        this.sort = value;
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(value: number) {
        this.pageSize = value;
    }

    public getCategories(): string[] {
        return this.categories;
    }

    public setCategories(value: string[]) {
        this.categories = value;
    }

    public getSubCategories(): string[] {
        return this.subCategories;
    }

    public setSubCategories(value: string[]) {
        this.subCategories = value;
    }

    public getText(): string {
        return this.text;
    }

    public setText(value: string) {
        this.text = value;
    }

    public setSortDirection(value: number) {
        this.sortDirection = value;
    }

    public addCategory = (value: string) => {
        this.getCategories().push(value);
    };

    public removeCategory = (value: string) => {
        removeItem(this.getCategories(), value);
    };

    public existCategory = (value: string): boolean => {
        return !!contains(this.getCategories(), value);
    };

    public addSubCategory = (value: string) => {
        this.getSubCategories().push(value);
    };

    public removeSubCategory = (value: string) => {
        removeItem(this.getSubCategories(), value);
    };

    public existSubCategory = (value: string): boolean => {
        return !!contains(this.getSubCategories(), value);
    };

    public getSubCategoriesMap(): Map<string, Category> {
        return this.subCategoriesMap;
    }

    public setSubCategoriesMap(value: Map<string, Category>) {
        this.subCategoriesMap = value;
    }

    public addSubCategoryMap = (category: Category) => {
        this.subCategoriesMap.set(category.getId(), category);
    };

    public removeSubCategoryMap = (category: Category) => {
        this.subCategoriesMap.delete(category.getId());
    };

    public existSubCategoryMap = (category: Category): boolean => {
        return this.subCategoriesMap.has(category.getId());
    };
}