export const contains = <T extends any, K extends keyof T>(arr: T[], element: T, key?: K): T | undefined => {
    return arr.find(item => {
        if (key && item.hasOwnProperty(key)) {
            return item[key] === element[key];
        }

        if (key && typeof item.key === "function") {
            return item.key() === element.key();
        }

        return item == element;
    });
};

export const updateSaveItem = <T, K extends keyof T>(arr: T[], element: T, attr?: K): T[] => {
    const found: T | undefined = contains(arr, element, attr);

    if (!found) {
        arr.push(element);

        return arr;
    }

    const index = getIndex(arr, found);

    if (~index) arr[index] = element;

    return arr;

};

export const getIndex = <T>(arr: T[], element: T) => {
    return arr.indexOf(element);
};

export const removeItem = <T, K extends keyof T>(arr: T[], element: T, key?: K): T[] => {
    const found: T | undefined = contains(arr, element, key);

    if (!found) {
        return arr;
    }

    const index = getIndex(arr, found);

    if (~index) {
        arr.splice(index, 1);
    }

    return arr;
};

export const arrayIntoChunks = <T>(items: T[], perChunk: number = 10): T[][] => {
    return items.reduce((chunks: T[][], item: T, i: number) => {
        const chunk   = Math.floor(i / perChunk);
        chunks[chunk] = ([] as T[]).concat((chunks[chunk] || []), item);
        return chunks;
    }, []);
};
