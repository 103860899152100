import * as React                         from "react";
import { ConfigurationStore }             from "../../Store/ConfigurationStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import PageError                          from "../../Views/Pages/PageView";
import { inject }                         from "mobx-react";

interface ErrorProps extends WithNamespaces {
    ConfigurationStore: ConfigurationStore;
}

@inject(ConfigurationStore.NAME_STORE)
class Error404ViewModel extends React.Component<ErrorProps, any> {

    public componentWillMount() {
        this.configurationStore.getConfiguration().setShowFooter(false).setShowHeader(false);
    }

    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    public render(): React.ReactNode {
        const error = this.configurationStore.getConfiguration().getErrorContent(404);
        const {t}   = this.props;

        if (!error) return <></>;

        return (<PageError
            {...this.props}
            backgroundImage={error.getBackgroundImage()}
            title={error.getTitle()}
            content={error.getMessage()}
            buttonTitle={t("404.button")}
            buttonLink={error.getLink()}
        />);
    }
}

export default withNamespaces("error")(Error404ViewModel);
