import { pages }      from "../../config/Pages";
import {
    custom,
    object,
    serializable,
    raw
}                     from "serializr";
import { observable } from "mobx";
import { SeoData }    from "./SeoData";
import { date }       from "../../Serializer/date";

export class Page {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: pages = pages.home;

    @serializable(object(SeoData))
    @observable
    private seoData: SeoData;

    @serializable(raw())
    @observable
    private extraData: SeoData;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    constructor() {
        this.setSeoData(new SeoData());
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): pages {
        return this.name;
    }

    public setName(value: pages) {
        this.name = value;
    }

    public getSeoData(): SeoData {
        return this.seoData;
    }

    public setSeoData(value: SeoData) {
        this.seoData = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getExtraData(): any {
        return this.extraData;
    }

    public setExtraData(value: any) {
        this.extraData = value;
    }
}
