import { serializable } from "serializr";
import { observable }   from "mobx";
import uuid             from "uuid";

export class PriceByRange {
    @serializable
    @observable
    private initKm: number = 0;

    @serializable
    @observable
    private endKm: number = 0;

    @serializable
    @observable
    private priceByKm: number = 0;

    @serializable
    @observable
    private uuid: string = uuid();

    public getInitKm(): number {
        return this.initKm;
    }

    public setInitKm(value: number) {
        this.initKm = value;
    }

    public getEndKm(): number {
        return this.endKm;
    }

    public setEndKm(value: number) {
        this.endKm = value;
    }

    public getPriceByKm(): number {
        return this.priceByKm;
    }

    public setPriceByKm(value: number) {
        this.priceByKm = value;
    }


    public getUuid(): string {
        return this.uuid;
    }

    public setUuid(value: string) {
        this.uuid = value;
    }
}