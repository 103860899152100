import moment from "moment";

export class DateFormat {
    public static serializer(modelSchema: string): string | undefined {
        if (!modelSchema) return undefined;

        return moment(modelSchema).toISOString();
    }

    public static deserializer(modelSchema: string): Date | undefined {
        return modelSchema ? moment(modelSchema).toDate() : undefined;
    }
}

export const date = {
    deserializer: DateFormat.deserializer,
    serializer  : DateFormat.serializer
};
