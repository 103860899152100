import * as i18next          from "i18next";
import Languages             from "./languageConfig";
import * as LanguageDetector from "i18next-browser-languagedetector";

const intervalPlural = require("i18next-intervalplural-postprocessor");

i18next
    .use(LanguageDetector)
    .use(intervalPlural)
    .init({
    debug: process.env.REACT_APP_ENV === "dev",    //  false for production
    defaultNS: "translations",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
    lng: "en",
    // have a common namespace used around the full app
    // ns: ["translations"],
    ns: ["global", "main", "pages", "popups", "tabs", "notifications"],
    pluralSeparator: "_",
    react: {
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
        wait: false
    },
    resources: Languages,
});

export default i18next;