import * as React                from "react";
import { inject, observer }      from "mobx-react";
import { ProductStore }          from "../../Store/ProductStore";
import ProductListView           from "../../Views/Pages/Products/ProductListView";

interface IProductsViewModelProps {
    showMore: () => void;
    currentPage: number;
    pages: number;
    loading: boolean;
    openSlugProduct: string;
    ProductStore?: ProductStore;
}

@inject(ProductStore.NAME_STORE)
@observer
export class ProductsListViewModel extends React.Component<IProductsViewModelProps, {}> {

    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    public render(): React.ReactNode {
        const {
                  showMore,
                  currentPage,
                  pages,
                  openSlugProduct,
                  loading,
              } = this.props;

        return (
            <ProductListView
                products={this.productStore.getProducts()}
                showMore={showMore}
                currentPage={currentPage}
                pages={pages}
                loading={loading}
                openSlugProduct={openSlugProduct}
            />
        );
    }
}

export default ProductsListViewModel;