import { inject, observer }    from "mobx-react";
import * as React              from "react";
import OrderDetailView         from "../../Views/Pages/Order/OrderDetailView";
import { UserStore }           from "../../Store/UserStore";
import User                    from "../../Models/User/User";
import { observable }          from "mobx";
import { RouteComponentProps } from "react-router";
import { Order }               from "../../Models/Order/Order";
import Box                     from "@material-ui/core/Box";
import CircularProgress        from "@material-ui/core/CircularProgress";

interface IOrderDetailViewModelProps extends RouteComponentProps<any> {
    UserStore?: UserStore;

}

@inject(UserStore.NAME_STORE)
@observer
class OrderDetailViewModel extends React.Component<IOrderDetailViewModelProps, {}> {
    @observable
    private loading: boolean = true;

    private order: Order;

    public getOrder = (): Order => {
        return this.order;
    };

    public setOrder = (value: Order) => {
        this.order = value;
    };

    public constructor(props: IOrderDetailViewModelProps) {
        super(props);
    }

    public componentDidMount = async () => {
        const {identifier} = this.props.match.params,
              order        = await this.userStore.getOrderByIdentifier(identifier);

        // @TODO 404
        if (!order) return;

        this.setOrder(order);
        this.loading = false;
    };

    protected get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public render(): React.ReactNode {
        if (this.loading) return (
            <Box justifyContent="center" className="d-flex m-4">
                <CircularProgress color="secondary"/>
            </Box>
        );

        return (
            <OrderDetailView
                user={this.userStore.getUser() as User}
                order={this.getOrder()}
            />
        );
    }
}

export default OrderDetailViewModel;