import { observer }           from "mobx-react";
import * as React             from "react";
import InjectPrefix           from "../../../Decorators/InjectPrefix";
import { Col, Row }           from "reactstrap";
import CategoriesViewModel    from "../../../ViewModel/Categories/CategoriesViewModel";
import ProductsListViewModel  from "../../../ViewModel/Products/ProductsListViewModel";
import { Container }          from "@material-ui/core";
import CircularProgress       from "@material-ui/core/CircularProgress";
import Box                    from "@material-ui/core/Box";
import SearchProduct          from "../../Components/SearchProduct/SearchProduct";
import { PrincipalSearch }    from "../../../Models/Product/PrincipalSearch";
import { Category }           from "../../../Models/Product/Category";
import { Page }               from "../../../Models/Page/Page";
import Seo                    from "../../Components/Header/Seo";
import OrderProductsViewModel from "../../../ViewModel/Products/OrderProductsViewModel";

interface IProductsViewProps {
    search: () => void;
    loading: {
        products: boolean;
        categories: boolean;
    };
    showMore: () => void;
    currentPage: number;
    pages: number;
    principalSearch: PrincipalSearch;
    removeCategory: (category: Category) => void;
    page: Page | undefined;
    openSlugProduct: string;
    prefix?: string;
}

@InjectPrefix
@observer
class ProductsView extends React.Component<IProductsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public loadingPage = (): React.ReactNode => {
        return (
            <Box justifyContent="center" className="d-flex mb-4">
                <CircularProgress color="secondary"/>
            </Box>
        );
    };

    public getContent = (): React.ReactNode => {
        const {
                  search,
                  loading,
                  showMore,
                  currentPage,
                  pages,
                  openSlugProduct,
              } = this.props;
        return (
            <Row>
                <Col xs={12} md={3}>
                    {
                        loading.categories
                        ? <CircularProgress color="secondary"/>
                        : (
                            <>
                                <OrderProductsViewModel
                                    search={search}
                                />
                                <CategoriesViewModel
                                    search={search}
                                />
                            </>
                        )
                    }
                </Col>
                <Col xs={12} md={9}>
                    <ProductsListViewModel
                        showMore={showMore}
                        currentPage={currentPage}
                        pages={pages}
                        loading={loading.products}
                        openSlugProduct={openSlugProduct}
                    />
                </Col>
            </Row>
        );
    };

    public render(): React.ReactNode {
        const {
                  loading: {categories, products},
                  principalSearch,
                  search,
                  loading,
                  removeCategory,
                  page,
              } = this.props;

        return (
            <>
                <Seo seoData={page ? page.getSeoData() : undefined}/>
                <div className={`${this.prefix}-p-products ${this.prefix}-seventh-color-background`}>
                    <Container>
                        <SearchProduct
                            principalSearch={principalSearch}
                            search={search}
                            loading={loading.products}
                            removeCategory={removeCategory}
                        />
                        {categories && products ? this.loadingPage() : this.getContent()}
                    </Container>
                </div>
            </>

        );
    }
}

export default ProductsView;
