import { custom, serializable } from "serializr";
import { observable }           from "mobx";
import { date }                 from "../../Serializer/date";

export class Score {
    @serializable
    @observable
    private score: number = 0;

    @serializable
    @observable
    private comments: string = "";

    @serializable
    @observable
    private key: string = "";

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;


    public getScore(): number {
        return this.score;
    }

    public setScore(value: number) {
        this.score = value;
    }

    public getComments(): string {
        return this.comments;
    }

    public setComments(value: string) {
        this.comments = value;
    }

    public getKey(): string {
        return this.key;
    }

    public setKey(value: string) {
        this.key = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }
}
