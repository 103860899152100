import * as React                 from "react";
import { inject, observer }       from "mobx-react";
import User                       from "../../Models/User/User";
import EditProfileFormView        from "../../Views/Components/EditProfileForm/EditProfileFormView";
import { observable }             from "mobx";
import SimpleReactValidator       from "simple-react-validator";
import ResetPassword              from "../../Models/User/ResetPassword";
import { ERROR_CLASS, FormStore } from "../../Store/FormStore";
import { withNamespaces }         from "react-i18next";
import { UserStore }              from "../../Store/UserStore";
import { scrollToElement }        from "../../Utils/common";
import { PageNames, renderRoute } from "../../Routes/routes";
import { withRouter }             from "react-router-dom";
import IBaseProps                 from "../Props/IBaseProps";

interface IEditProfileFormViewModelProps extends IBaseProps {
    user: User;
    UserStore?: UserStore;
    FormStore?: FormStore;
}

@inject(
    UserStore.NAME_STORE,
    FormStore.NAME_STORE,
)
@observer
class EditProfileFormViewModel extends React.Component<IEditProfileFormViewModelProps, {}> {
    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private send: boolean = false;

    @observable
    private existUser: boolean = false;

    @observable
    private sending: boolean = false;

    @observable
    private resetPassword: ResetPassword = new ResetPassword();

    private errorDOMElement: HTMLElement | null;

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    constructor(props: IEditProfileFormViewModelProps) {
        super(props);

        const {t} = this.props;

        this.validator = this.formStore.getNewValidator(t);
        const {user}   = this.props;

        this.resetPassword.fill(user);
    }

    protected getSend = (): boolean => {
        return this.send;
    };

    protected setSend = (value: boolean) => {
        this.send = value;
    };

    protected getExistUser = (): boolean => {
        return this.existUser;
    };

    protected setExistUser = (value: boolean) => {
        this.existUser = value;
    };

    protected getSending = (): boolean => {
        return this.sending;
    };

    protected setSending = (value: boolean) => {
        this.sending = value;
    };

    protected getResetPassword = (): ResetPassword => {
        return this.resetPassword;
    };

    protected setResetPassword = (value: ResetPassword) => {
        this.resetPassword = value;
    };

    protected setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    protected scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) scrollToElement(this.errorDOMElement, 1000, 180);
    };

    protected submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.scrollOnError();
            });

            return;
        }

        this.sending = true;

        await this.userStore.updateProfile(this.getResetPassword());

        this.send    = true;
        this.sending = false;

        setTimeout(() => {
            this.props.history.push(renderRoute(PageNames.profilePage));
        }, 2000);
    };

    public render(): React.ReactNode {
        return (
            <EditProfileFormView
                resetPassword={this.getResetPassword()}
                validator={this.validator}
                submit={this.submit}
                send={this.send}
                sending={this.sending}
            />
        );
    }
}

export default withRouter(withNamespaces("forms")(EditProfileFormViewModel));