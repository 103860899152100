import * as React                         from "react";
import { inject, observer }               from "mobx-react";
import { ERROR_CLASS, FormStore }         from "../../Store/FormStore";
import { ContactUs }                      from "../../Models/Form/ContactUs";
import FormContactUsView                  from "../../Views/Components/Form/FormContactUsView";
import { observable }                     from "mobx";
import { withNamespaces, WithNamespaces } from "react-i18next";
import SimpleReactValidator               from "simple-react-validator";
import { scrollToElement }                from "../../Utils/common";

interface FormContactUsViewModelProps extends WithNamespaces {
    FormStore?: FormStore;
}

@inject(FormStore.NAME_STORE)
@observer
class FormContactUsViewModel extends React.Component<FormContactUsViewModelProps, {}> {
    @observable
    private readonly validator: SimpleReactValidator;

    @observable
    private send: boolean = false;

    @observable
    private sending: boolean = false;

    private errorDOMElement: HTMLElement | null;

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    private scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) scrollToElement(this.errorDOMElement, 1000, 180);
    };

    constructor(props: FormContactUsViewModelProps) {
        super(props);
        const {t} = props;

        this.formStore.setContactUs(new ContactUs());
        this.validator = this.formStore.getNewValidator(t);
    }

    private setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    private submit = async (form: React.Component) => {
        const valid = this.validator.allValid();

        if (!valid) {
            this.validator.showMessages();
            form.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.scrollOnError();
            });

            return;
        }

        this.sending = true;
        await this.formStore.saveContact(this.formStore.getContactUs());
        this.send    = true;
        this.sending = false;
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <FormContactUsView
                contactUs={this.formStore.getContactUs()}
                validator={this.validator}
                submit={this.submit}
                send={this.send}
                sending={this.sending}
            />
        );
    }
}

export default withNamespaces("forms")(FormContactUsViewModel);
