import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";

interface INoteMethodTransferProps extends WithNamespaces {

}

@InjectPrefix
@observer
class NoteMethodTransfer extends React.Component<INoteMethodTransferProps, {}> {
    public render(): React.ReactNode {
        const {
                  t,
              } = this.props;

        return (
            <div className="legend">
                <p>
                    Completa tu orden y deposita en el BAC, Banco Nacional o Scotiabank. Tienes 24 horas para realizar
                    el depósito.
                </p>
                <p className="font-weight-bolder">
                    MADERAS CAMACHO, S.A. / Cedula Jurídica 3-101-038423
                </p>
                <ul>
                    <li>BAC ¢: CR95010200009029616971</li>
                    <li>Banco Nacional ¢: CR23015103510010007687</li>
                    <li>Scotiabank ¢: CR44012300130008501000</li>
                </ul>
                <p>
                    También podes pagar con SINPE MOVIL al 8808-9082 si el monto de la orden es menor a
                    ¢100,000.
                </p>
                <p>
                    Esta información también la podrás ver en la siguiente pagina. Por favor enviar comprobante
                    a <a href="mailto:transferencias@maderascamacho.cr">transferencias@maderascamacho.cr</a>
                </p>
            </div>
        );
    }
}

export default withNamespaces("global")(NoteMethodTransfer);