import * as React                   from "react";
import { Container }                from "typedi";
import { observer }                 from "mobx-react";
import { Product }                  from "../../Models/Product/Product";
import { SnackBarAddedProductView } from "../../Views/Components/SnackBar/SnackBarAddedProductView";
import SnackBarService              from "../../Service/SnackBarService";
import { observable }               from "mobx";

interface ISnackBarAddedProductViewModelProps {
    product: Product;
}

@observer
export class SnackBarAddedProductViewModel extends React.Component<ISnackBarAddedProductViewModelProps, {}> {
    @observable
    protected open = true;

    protected snackBarService: SnackBarService;

    constructor(props: ISnackBarAddedProductViewModelProps) {
        super(props);
        this.snackBarService = Container.get(SnackBarService);
    }

    protected close = () => {
        this.snackBarService.closeSnackBar();
    };

    public render(): React.ReactNode {
        const {product} = this.props;

        return (
            <SnackBarAddedProductView
                close={this.close}
                open={this.snackBarService.getSnackBar().getOpen()}
                product={product}
            />
        );
    }
}

export default SnackBarAddedProductViewModel;