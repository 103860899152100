import { observable }                                         from "mobx";
import { persist }                                            from "mobx-persist";
import User                                                   from "../Models/User/User";
import BaseStore                                              from "./Base/BaseStore";
import AjaxService                                            from "../Service/AjaxService";
import Container                                              from "typedi";
import { date, deserialize, object, serializable, serialize } from "serializr";
import { TokenStore }                                         from "./TokenStore";
import moment                                                 from "moment";
import { Direction }                                          from "../Models/User/Direction";
import { BillElectronic }                                     from "../Models/User/BillElectronic";
import { Order }                                              from "../Models/Order/Order";
import ResetPassword                                          from "../Models/User/ResetPassword";
import { ApplicationStore }                                   from "./ApplicationStore";
import { generatePath }                                       from "react-router";

export class UserStore extends BaseStore {
    public static readonly NAME_STORE: string = "UserStore";

    @observable
    @persist("object", User)
    @serializable(object(User))
    private user: User | undefined;

    @observable
    @persist("object")
    @serializable(date())
    private previousCallAccess: Date;

    private callMe: boolean = false;

    @observable
    private newDirection: boolean = false;

    @observable
    private newBillElectronic: boolean = false;

    @observable
    private indexTab: number = 0;

    @observable
    private checkAboutDraft: boolean = true;

    @persist("object")
    protected createdStoreAt: Date;

    public resetStore() {
        this.setPreviousAccess(new Date());
        this.setUser(undefined);
    }

    public setNewBillElectronic = (value: boolean) => {
        this.newBillElectronic = value;
    };

    public getNewBillElectronic = (): boolean => {
        return this.newBillElectronic;
    };

    public setNewDirection = (value: boolean) => {
        this.newDirection = value;
    };

    public getNewDirection = (): boolean => {
        return this.newDirection;
    };

    protected init() {
        this.needPersistData = true;
    }

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public getPreviousAccess(): Date {
        return this.previousCallAccess;
    }

    public setPreviousAccess(currentDate: Date) {
        this.previousCallAccess = currentDate;
    }

    public setUser(user: User | undefined): this {
        this.user = user;

        return this;
    }

    public getUser(): User | undefined {
        const now = moment().add(2, "h");

        if (!this.getPreviousAccess() || now.isBefore(moment(this.getPreviousAccess()))) this.getUserMeApi();

        return this.user;
    }

    public async getUserMeApi(): Promise<boolean> {
        if (!this.getStore(TokenStore).getAccessToken()) return true; // Prevent if the app don't have
        // accessToken to call api.me
        if (this.callMe) return true; // Prevent call unlimited times api.me
        this.callMe    = true;
        const response = await this.getAjaxService().getMe();

        if (!response.data.success) {
            this.callMe = false;
            Container.get(ApplicationStore).clearStoreData(true);
            window.location.href = generatePath("/login");

            return false;
        }

        const user: User = deserialize(User, response.data.data);

        this.setUser(user)
            .setPreviousAccess(moment().toDate());

        this.callMe = false;

        return true;
    }

    public async postDirection(direction: Direction): Promise<User> {
        const data                         = serialize(direction),
              {data: {data: dataResponse}} = await this.getAjaxService().postDirection(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async updateDirection(direction: Direction): Promise<User> {
        const data                         = serialize(direction),
              {data: {data: dataResponse}} = await this.getAjaxService().updateDirection(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async deleteDirection(direction: Direction): Promise<User> {
        const data                         = serialize(direction),
              {data: {data: dataResponse}} = await this.getAjaxService().deleteDirection(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async postBillElectronic(billElectronic: BillElectronic): Promise<User> {
        const data                         = serialize(billElectronic),
              {data: {data: dataResponse}} = await this.getAjaxService().postBillElectronic(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async updateBillElectronic(billElectronic: BillElectronic): Promise<User> {
        const data                         = serialize(billElectronic),
              {data: {data: dataResponse}} = await this.getAjaxService().updateBillElectronic(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async deleteBillElectronic(billElectronic: BillElectronic): Promise<User> {
        const data                         = serialize(billElectronic),
              {data: {data: dataResponse}} = await this.getAjaxService().deleteBillElectronic(data);

        const user = deserialize(User, dataResponse);

        this.setUser(user);

        return this.getUser() as User;
    }

    public async getMyOrders(): Promise<Order[]> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getMyOrders();

        return dataResponse.map((item: any) => deserialize(Order, item));
    }

    public async getOrderByIdentifier(identifier: string): Promise<Order | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getOrderByIdentifier(identifier);

        if (dataResponse)
            return deserialize(Order, dataResponse);

        return;
    }

    public async updateProfile(resetPassword: ResetPassword): Promise<User> {
        const {data: {data: dataResponse}} = await this.getAjaxService().updateProfileUser(serialize(resetPassword)),
              user                         = deserialize(User, dataResponse);

        this.setUser(user);

        return user;
    }
}
