import * as React                 from "react";
import { GalleryPromotionDetail } from "../../Views/Components/Gallery/GalleryPromotionDetail";
import { inject, observer }       from "mobx-react";
import { Promotion }              from "../../Models/Promotion/Promotion";
import { GalleryStore }           from "../../Store/GalleryStore";

interface GalleryPromotionDetailViewModelProps {
    promotion: Promotion;
    GalleryStore?: GalleryStore;
}

@inject(GalleryStore.NAME_STORE)
@observer
export class GalleryPromotionDetailViewModel extends React.Component<GalleryPromotionDetailViewModelProps, {}> {
    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    public render(): React.ReactNode {
        const {
                  promotion
              } = this.props;

        if (!this.galleryStore.getCurrentImage() || !promotion) return <></>;

        return (
            <GalleryPromotionDetail
                promotion={promotion}
                currentImage={this.galleryStore.getCurrentImage()}
                setCurrentImage={this.galleryStore.setCurrentImage}
            />
        );
    }
}
