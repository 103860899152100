import { observable }  from "mobx";
import {
    object,
    serializable,
    list,
    custom,
}                      from "serializr";
import { GalleryItem } from "./GalleryItem";
import { removeItem }  from "../../Utils/array";
import { date }        from "../../Serializer/date";

export class Gallery {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string;

    @serializable
    @observable
    private slug: string;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    public page: boolean;

    @serializable(list(object(GalleryItem)))
    @observable
    private items: GalleryItem[] = [];

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    constructor() {
        this.page = false;
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getItems(): GalleryItem[] {
        return this.items;
    }

    public setItems(value: GalleryItem[]) {
        this.items = value;
    }

    public addItem(value: GalleryItem) {
        this.items.push(value);
    }

    public removeItem(value: GalleryItem) {
        removeItem(this.items, value, "key" as keyof GalleryItem);
    }

    public getFirstItem(): GalleryItem | undefined {
        return this.items[0];
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getPage(): boolean {
        return this.page;
    }

    public setPage(value: boolean) {
        this.page = value;
    }
}
