import BaseStore             from "./Base/BaseStore";
import { Container }         from "typedi";
import AjaxService           from "../Service/AjaxService";
import { Product }           from "../Models/Product/Product";
import { observable }        from "mobx";
import {
    contains,
    removeItem,
    updateSaveItem
}                            from "../Utils/array";
import {
    deserialize,
    list,
    object,
    serializable,
    serialize
}                            from "serializr";
import { ProductItemCar }    from "../Models/ProductItemCar/ProductItemCar";
import { persist }           from "mobx-persist";
import { MethodTransport }   from "../Models/MethodTransport/MethodTransport";
import { Order }             from "../Models/Order/Order";
import { CalculateShipping } from "../Models/CalculateShipping/CalculateShipping";
import { Color }             from "../Models/Color/Color";

export class CarStore extends BaseStore {
    public static readonly NAME_STORE: string = "CarStore";

    @observable
    @persist("list", ProductItemCar)
    @serializable(list(object(ProductItemCar)))
    private productStore: ProductItemCar[] = [];

    @observable
    @persist("object", Order)
    @serializable(object(Order))
    private order: Order;

    @observable
    @serializable(list(object(MethodTransport)))
    private methodTransports: MethodTransport[] = [];

    @observable
    @persist("object", CalculateShipping)
    @serializable(object(MethodTransport))
    private calculateShipping: CalculateShipping;

    @persist("object")
    protected createdStoreAt: Date;

    public resetStore() {
        this.setProductStore([]);
        // @ts-ignore
        this.setOrder(undefined);
        // @ts-ignore
        this.setMethodTransports(undefined);
        // @ts-ignore
        this.setCalculateShipping(undefined);
    }

    public getMethodTransports(): MethodTransport[] {
        return this.methodTransports;
    }

    public async initMethodTransports(): Promise<CarStore> {
        const {data: {data}} = await this.getAjaxService().getMethodTransports();

        this.setMethodTransports(data.map((item: any) => deserialize(MethodTransport, item)));

        return this;
    }

    public async getCalculatePriceShipping(params: {
        destiny: string,
        methodTransportId: string,
        productItemCar: ProductItemCar[],
    }): Promise<CalculateShipping> {
        params.productItemCar = serialize(this.getProductStore());
        const {data: {data}}  = await this.getAjaxService().getCalculatePriceShipping(params);

        return deserialize(CalculateShipping, data);
    }

    public setMethodTransports(value: MethodTransport[]) {
        this.methodTransports = value;
    }

    public getOrder(): Order {
        return this.order;
    }

    public setOrder(value: Order) {
        this.order = value;
    }

    public getCalculateShipping(): CalculateShipping {
        // @ts-ignore
        if (Array.isArray(this.calculateShipping)) this.setCalculateShipping(undefined);

        return this.calculateShipping;
    }

    public setCalculateShipping(value: CalculateShipping) {
        this.calculateShipping = value;
    }

    protected init() {
        this.needPersistData = true;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public getProductStore(): ProductItemCar[] {
        return this.productStore;
    }

    public setProductStore(value: ProductItemCar[]) {
        this.productStore = value;
    }

    public addProduct(product: Product, quantity: number, color?: Color): ProductItemCar[] {
        const item = new ProductItemCar()
            .setProduct(product)
            .setColor(color)
            .setQuantity(quantity);

        const contain = this.getProductStore().find((value: ProductItemCar) =>
            color
                ? value.getProductId() === item.getProductId() && value.getColor()?.getCode() === color.getCode()
                : value.getProductId() === item.getProductId()
        );

        if (contain) {
            contain.setQuantity(contain.getQuantity() + item.getQuantity());
        } else {
            this.productStore.push(item);
        }

        return this.productStore;
    }

    public removeProduct = (product: Product): ProductItemCar[] => {
        this.productStore = removeItem(this.productStore, {
            productId: product.getId()
        } as unknown as ProductItemCar, "productId" as keyof ProductItemCar);

        return this.productStore;
    };

    public getProductsInCar(): number {
        return this.getProductStore().reduce((previous, item: ProductItemCar): number => {
            return previous + item.getQuantity();
        }, 0);
    }

    public getTotalWeight(productItemCars: ProductItemCar[]): number {
        return productItemCars.reduce((accum: number, item: ProductItemCar) => {
            return accum + (item.getProduct().getWeight() * item.getQuantity());
        }, 0);
    }

    public getSubTotalProducts(productItemCars: ProductItemCar[]): number {
        return productItemCars.reduce((accum: number, productItemCar: ProductItemCar) => {
            return accum + (productItemCar.getQuantity() * productItemCar.getProduct().getPrice());
        }, 0);
    }

    public async generateOrder(order: Order, calculateShipping: CalculateShipping): Promise<Order | { status: number }> {
        const {data} = await this.getAjaxService().postOrder({
            calculateShipping: calculateShipping ? serialize(calculateShipping) : calculateShipping,
            order            : serialize(order),
        });

        if (data.success) {
            return deserialize(Order, data.data);
        }

        return data;
    }

    public async refreshProductStore() {
        if (this.getProductStore().length === 0) {
            return;
        }

        const productsIds: string[]                         = this.getProductStore().map(item => item.getProductId()),
              {data: {data: {products: productsSerialize}}} = await this.getAjaxService().getProductsByIdsList(productsIds),
              products: Product[]                           = productsSerialize.map((item: any) => deserialize(Product, item));

        this.getProductStore().forEach((value: ProductItemCar, index: number, array: ProductItemCar[]) => {
            const product = products.find(item => item.getId() === array[index].getProductId()) as Product;

            if (!product) {
                array.splice(index, 1);
                return;
            }

            array[index].setProduct(product);
        });

        if (this.getOrder()) {
            this.getOrder().setProductStore(this.getProductStore());
        }
    }
}
