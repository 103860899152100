import { serializable } from "serializr";
import { observable }   from "mobx";

export enum WishContact {
    email    = "email",
    phone    = "phone",
    whatsApp = "whatsApp"
}

export class ContactUs {
    @observable
    @serializable
    private name: string;

    @observable
    @serializable
    private lastName: string;

    @observable
    @serializable
    private email: string;

    @observable
    @serializable
    private phone: string;

    @observable
    @serializable
    private wishContact: WishContact = WishContact.email;

    @observable
    @serializable
    private message: string;

    public getName(): string {
        return this.name || "";
    }

    public setName(value: string) {
        this.name = value;
    }

    public getLastName(): string {
        return this.lastName || "";
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getEmail(): string {
        return this.email || "";
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getPhone(): string {
        return this.phone || "";
    }

    public setPhone(value: string) {
        this.phone = value;
    }

    public getMessage(): string {
        return this.message || "";
    }

    public setMessage(value: string) {
        this.message = value;
    }

    public getWishContact(): WishContact {
        return this.wishContact || "";
    }

    public setWishContact(value: WishContact) {
        this.wishContact = value;
    }
}
