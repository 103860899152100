import * as React                         from "react";
import { Container, Paper }               from "@material-ui/core";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import PromotionPresentation              from "../Components/Presentation/PromotionPresentation";
import { TableInformation }               from "../Components/Table/TableInformation";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Col, Row }                       from "reactstrap";
import CardPromotion                      from "../Components/Card/CardPromotion";
import { Promotion }                      from "../../Models/Promotion/Promotion";
import { observer }                       from "mobx-react";
import Seo                                from "../Components/Header/Seo";

interface PromotionViewProps extends WithNamespaces {
    prefix?: string;
    promotion: Promotion;
    nextPromotions: Promotion[];
    previousPromotions: Promotion[];
}

@InjectPrefix
@observer
class PromotionView extends React.Component<PromotionViewProps, {}> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  promotion,
                  nextPromotions,
                  previousPromotions
              } = this.props;

        return (
            <Container className={`${this.prefix}-p-promotion`}>
                <Seo seoData={promotion.getSeoData()}/>
                <PromotionPresentation promotion={promotion}/>
                <hr/>
                <h5 className={`${this.prefix}-secondary-color mb-3 d-block`}>
                    {t("promotion.information-promotion")}
                </h5>
                <div
                    dangerouslySetInnerHTML={{__html: promotion.getDescription() || ""}}
                    className="mt-4 mb-4"
                />
                <Paper className="mb-5">
                    <TableInformation information={promotion.getInformation()}/>
                </Paper>
                <h5 className={`mb-3 d-block`}>
                    {t("promotion.other-promotions")}
                </h5>
                <Row className={`${this.prefix}-container-cards`}>
                    {[...previousPromotions, ...nextPromotions].map((promotion: Promotion, key: number) => {
                        return (
                            <Col xs={12} sm={3} lg={3} key={key}>
                                <CardPromotion
                                    promotion={promotion}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        );
    }
}

export default withNamespaces("page")(PromotionView);
