import BaseStore          from "./Base/BaseStore";
import Container          from "typedi";
import { observable }     from "mobx";
import { deserialize }    from "serializr";
import { ColorPalette }   from "../Models/Color/ColorPalette";
import AjaxService        from "../Service/AjaxService";

export class ColorStore extends BaseStore {
    public static readonly NAME_STORE: string = "ColorStore";

    @observable
    private colorPalettes: Map<string, ColorPalette>;

    @observable
    private spinner: boolean = false;

    public init() {
        this.needPersistData = false;
        this.colorPalettes   = new Map<string, ColorPalette>();
    }

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public getColorPalettes = (): Map<string, ColorPalette> => {
        return this.colorPalettes;
    };

    public getColorPalette = async (id: string): Promise<ColorPalette> => {
        if (!this.getColorPalettes().has(id)) {
            const {data: {data}} = await this.getAjaxService().getColorPaletteById(id),
                  colorPalette   = deserialize(ColorPalette, data);

            this.addColorPalette(id, colorPalette);
        }

        return this.getColorPalettes().get(id) as ColorPalette;
    };

    public addColorPalette = (key: string, value: ColorPalette) => {
        this.getColorPalettes().set(key, value);
    };

    public removeColorPalette = (key: string) => {
        this.getColorPalettes().delete(key);
    };

    public existColorPalette = (key: string): boolean => {
        return this.getColorPalettes().has(key);
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public setSpinner = (value: boolean): this => {
        this.spinner = value;

        return this;
    };
}
