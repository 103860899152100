import React                                from "react";
import ReactDOM                             from "react-dom";
import {
    AppBar,
    Button,
    Container,
    CssBaseline,
    Hidden,
    Menu as MenuMaterial,
    MenuItem,
    Toolbar,
    useScrollTrigger,
}                                           from "@material-ui/core";
import classNames                           from "classnames";
import { ShoppingCart as ShoppingCartIcon } from "@material-ui/icons";
import { withNamespaces }                   from "react-i18next";
import { Link, withRouter }                 from "react-router-dom";
import Menu                                 from "./Menu";
import InjectPrefix                         from "../../../Decorators/InjectPrefix";
import { PageNames, renderRoute }           from "../../../Routes/routes";
import User                                 from "../../../Models/User/User";
import { disableShopCar }                   from "../../../Utils/common";
import IBaseProps                           from "../../../ViewModel/Props/IBaseProps";

interface Props {
    children: React.ReactElement;
}

function ElevationScroll(props: Props) {
    const {children} = props;
    const trigger    = useScrollTrigger({
                                            disableHysteresis: true,
                                            target           : window,
                                            threshold        : 0,
                                        });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

interface IHeaderProps extends IBaseProps {
    openMenuLeft: boolean;
    setOpenMenuLeft: (evt: boolean) => void;
    prefix?: string;
    node: Element;
    setNode: (evt: Element) => void;
    logout: () => void;
    currentPage: string;
    user: User | undefined;
    quantityProductsInCar: number;
    nodeMenuUser: Element;
    setNodeMenuUser: (value: Element | undefined) => void;
}

@InjectPrefix
class Header extends React.Component<IHeaderProps, {}> {
    private readonly refWrap: React.RefObject<HTMLDivElement>;

    constructor(props: IHeaderProps) {
        super(props);
        this.refWrap = React.createRef();
        window.addEventListener("resize", this.assignNode);
    }

    private assignNode = () => {
        this.props.setNode(ReactDOM.findDOMNode(this.refWrap.current) as Element);
        setTimeout(() => this.forceUpdate(), 100);
    };

    public componentDidMount = (): void => {
        setTimeout(this.assignNode, 300);
    };

    get prefix(): string {
        return this.props.prefix as string;
    }

    private registerComponent = () => {
        const {t} = this.props;

        return (
            <>
                <Link className={`login`} to={renderRoute(PageNames.loginPage)}>
                    {t("global:login")}
                </Link>
                <span>|</span>
                <Link className={`register`} to={renderRoute(PageNames.registerPage)}>
                    {t("global:register")}
                </Link>
            </>
        );
    };

    private closeMenuUser = () => {
        this.props.setNodeMenuUser(undefined);
    };

    private userComponent = () => {
        const {
                  nodeMenuUser,
                  user,
                  logout,
                  setNodeMenuUser,
                  history,
                  t,
              } = this.props;

        return (
            <>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(evt) => {
                        setNodeMenuUser(evt.currentTarget);
                    }}
                    className={"user-button"}
                >
                    {user?.getFirstName()}
                </Button>
                <MenuMaterial
                    id="simple-menu"
                    anchorEl={nodeMenuUser}
                    keepMounted
                    open={Boolean(nodeMenuUser)}
                    onClose={this.closeMenuUser}
                >
                    <MenuItem
                        onClick={() => {
                            history.push(renderRoute(PageNames.profilePage));
                            this.closeMenuUser();
                        }}
                    >Mi Perfil</MenuItem>
                    <MenuItem onClick={() => {
                        logout();
                        this.closeMenuUser();
                    }}>Logout</MenuItem>
                </MenuMaterial>
            </>
        );
    };

    protected renderCarCounter = () => {
        const {quantityProductsInCar} = this.props;

        return (
            <div className="car-buy-container">
                <Link to={renderRoute(PageNames.carPage)} className="car-buy">
                    <ShoppingCartIcon className="icon"/>
                    <span className="counter">{quantityProductsInCar}</span>
                </Link>
            </div>
        );
    };

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  node,
                  t,
                  currentPage,
                  user,
                  openMenuLeft,
                  setOpenMenuLeft,
                  logout,
              }          = this.props,
              // @ts-ignore
              heightNode = node ? node.offsetHeight : 0;

        return (
            <div className={`${this.prefix}-header`}>
                <CssBaseline/>
                <ElevationScroll {...this.props}>
                    <AppBar
                        color={"primary"}
                        ref={this.refWrap}
                        position={"fixed"}
                        component={"div" as unknown as React.ComponentClass}
                    >
                        <Toolbar
                            component={"div"}
                            disableGutters={true}
                            classes={{root: "toolbar"}}
                        >
                            <div className="top-menu">
                                <Container className="top-menu-container">
                                    <Hidden smUp>
                                        <Menu
                                            openMenuLeft={openMenuLeft}
                                            setOpenMenuLeft={setOpenMenuLeft}
                                            currentPage={currentPage}
                                            user={user}
                                            logout={logout}
                                        />
                                    </Hidden>
                                    <Link className={`image-logo ${this.prefix}-secondary-color`}
                                          to={renderRoute(PageNames.homePage)}>
                                        <img src="/assets/img/components/logo/logo.png" alt="" className="img-fluid"/>
                                        <Hidden xsDown>
                                            <span>
                                                {t("global:companyName")}
                                            </span>
                                        </Hidden>
                                    </Link>
                                    {
                                        disableShopCar()
                                        ? <React.Fragment/>
                                        : <Hidden xsDown>
                                            <div className="login-register">
                                                {user ? this.userComponent() : this.registerComponent()}
                                            </div>
                                            {this.renderCarCounter()}
                                        </Hidden>
                                    }
                                    <Hidden smUp>
                                        {
                                            disableShopCar()
                                            ? <div style={{minWidth: "64px"}}/>
                                            : this.renderCarCounter()
                                        }
                                    </Hidden>
                                </Container>
                            </div>
                            <Container className="d-none d-sm-block">
                                <ul className="menu-list">
                                    {[
                                        PageNames.productsPage,
                                        PageNames.promotionsPage,
                                        PageNames.tutorialPage,
                                        PageNames.whoWePage,
                                        PageNames.recommendationPersonPage,
                                        PageNames.contactPage
                                    ].map(item => (
                                        <li key={item}>
                                            <Button color="default"
                                                    component={Link}
                                                    to={renderRoute(item)}
                                                    className={classNames({active: item === currentPage})}
                                            >
                                                {t(`menu:${item}`)}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </Container>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                {node && <Toolbar style={{
                    minHeight: heightNode
                }}/>}
            </div>
        );
    }
}

export default withRouter(withNamespaces("header")(Header));
