import * as React    from "react";
import { Card }      from "@material-ui/core";
import { Direction } from "../../../Models/User/Direction";
import InjectPrefix  from "../../../Decorators/InjectPrefix";
import { observer }  from "mobx-react";

interface ICardDirectionProps {
    direction: Direction;
    setPrincipal: () => void;
    edit: () => void;
    deleteItem: () => void;
    prefix?: string;
}

@InjectPrefix
@observer
class CardDirection extends React.Component<ICardDirectionProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected defaultDirection = () => {
        const {direction} = this.props;

        if (!direction.getPrincipal())
            return <React.Fragment/>;

        return (
            <p className="font-weight-bold principal-direction">
                Direccion Principal
            </p>
        );
    };

    public render(): React.ReactNode {
        const {
                  direction,
                  edit,
                  deleteItem,
                  setPrincipal
              } = this.props;

        return (
            <Card variant="outlined" className={`card ${this.prefix}-card-direction`}>
                {this.defaultDirection()}
                <div className="content">
                    <p className="font-weight-bold">{direction.getFullName()}</p>
                    <ul className="list-unstyled">
                        <li>{direction.getDni()}</li>
                        <li>{direction.getProvincie()}</li>
                        <li>{direction.getCanton()}</li>
                        <li>{direction.getDirection()}</li>
                    </ul>
                    <ul className="list-unstyled options">
                        <li>
                            <a
                                onClick={edit}
                                href="javascript:void(0);"
                                className="action"
                            >Editar</a>
                        </li>
                        <li>
                            <span>&nbsp;|&nbsp;</span>
                        </li>
                        <li>
                            <a
                                onClick={deleteItem}
                                href="javascript:void(0);"
                                className="action"
                            >Eliminar</a>
                        </li>
                        {
                            !direction.getPrincipal() ?
                            <>
                                <li>
                                    <span>&nbsp;|&nbsp;</span>
                                </li>
                                <li>
                                    <a
                                        onClick={setPrincipal}
                                        href="javascript:void(0);"
                                        className="action"
                                    >Colocar como princial</a>
                                </li>
                            </>
                                                     : <React.Fragment/>
                        }
                    </ul>
                </div>
            </Card>
        );
    }
}

export default CardDirection;
