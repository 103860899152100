import { Service }    from "typedi";
import { Error }      from "tslint/lib/error";
import BaseMiddleware from "../Middleware/Base/BaseMiddleware";

@Service()
export class MiddlewareHandlerService {
    private middlewares: {
        [key: string]: BaseMiddleware
    } = {};

    public registerMiddleware(key: string, middleware: BaseMiddleware) {
        this.middlewares[key] = middleware;
    }

    public getMiddleware(middleware: string): BaseMiddleware {
        if (this.middlewares[middleware])
            return this.middlewares[middleware];
        else
            throw new Error(`The middleware ${middleware} isn't registered, please add the decorator Middleware to ${middleware}`);
    }
}