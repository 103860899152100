import { observable }                         from "mobx";
import { custom, list, object, serializable } from "serializr";
import { date }                               from "../../Serializer/date";
import { Direction }                          from "./Direction";
import { BillElectronic }                     from "./BillElectronic";

export default class User {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private active: string;

    @serializable
    @observable
    private firstName: string;

    @serializable
    @observable
    private lastName: string;

    @serializable
    @observable
    private username: string;

    @serializable
    @observable
    private email: string;

    @serializable(list(object(Direction)))
    @observable
    private directions: Direction[] = [];

    @serializable(list(object(BillElectronic)))
    @observable
    private billElectronics: BillElectronic[] = [];

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public hasRole(role: string): boolean {
        return true;
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getActive(): string {
        return this.active;
    }

    public setActive(value: string) {
        this.active = value;
    }

    public getFirstName(): string {
        return this.firstName;
    }

    public setFirstName(value: string) {
        this.firstName = value;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getUsername(): string {
        return this.username;
    }

    public setUsername(value: string) {
        this.username = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getDirections(): Direction[] {
        return this.directions;
    }

    public getBillElectronics(): BillElectronic[] {
        return this.billElectronics;
    }
}
