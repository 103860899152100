import * as React                          from "react";
import { observable }                      from "mobx";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TutorialDetailView                  from "../../Views/Pages/TutorialDetailView";
import { Tutorial }                        from "../../Models/Tutorial/Tutorial";
import Box                                 from "@material-ui/core/Box";
import CircularProgress                    from "@material-ui/core/CircularProgress";
import { TutorialStore }                   from "../../Store/TutorialStore";

interface TutorialDetailViewModelProps extends RouteComponentProps<any> {
    TutorialStore?: TutorialStore;
}

@inject(TutorialStore.NAME_STORE)
@observer
class TutorialDetailViewModel extends React.Component<TutorialDetailViewModelProps, any> {
    @observable
    private tutorial: Tutorial;

    get tutorialStore(): TutorialStore {
        return this.props.TutorialStore as TutorialStore;
    }

    public componentDidMount = async (): Promise<void> => {
        const slug = this.props.match.params.slug;
        this.tutorial = await this.tutorialStore.getBySlug(slug);
    };

    public render(): React.ReactNode {
        if (!this.tutorial) return (
            <Box justifyContent="center" className="d-flex m-4">
                <CircularProgress color="secondary"/>
            </Box>
        );

        return (
            <TutorialDetailView
                tutorial={this.tutorial}
            />
        );
    }
}

export default withRouter(TutorialDetailViewModel);
