import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import { Container }                      from "@material-ui/core";
import Carousel                           from "../Components/Carousel/Carousel";
import { Col, Row }                       from "reactstrap";
import FormContactUsViewModel             from "../../ViewModel/ContactUs/FormContactUsViewModel";
import LocationMapViewModel               from "../../ViewModel/Maps/LocationMapViewModel";
import { Gallery }                        from "../../Models/Gallery/Gallery";
import { Page }                           from "../../Models/Page/Page";
import Seo                                from "../Components/Header/Seo";

interface ContactUsViewProps extends WithNamespaces {
    prefix?: string;
    gallery: Gallery;
    page: Page | undefined;
}

@InjectPrefix
class ContactUsView extends React.Component<ContactUsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, gallery, page} = this.props;

        return (
            <div className={`${this.prefix}-p-contact-us`}>
                <Seo seoData={page ? page.getSeoData() : undefined}/>
                <Carousel
                    gallery={gallery}
                />
                <Container>
                    <h1 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("contact-us")}
                    </h1>
                    <hr/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormContactUsViewModel/>
                        </Col>
                        <Col xs={12} sm={6} className="maps">
                            <LocationMapViewModel/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withNamespaces("page")(ContactUsView);
