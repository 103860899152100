import InjectPrefix        from "../../../Decorators/InjectPrefix";
import { observer }        from "mobx-react";
import * as React          from "react";
import User                from "../../../Models/User/User";
import { MethodTransport } from "../../../Models/MethodTransport/MethodTransport";
import { Order }           from "../../../Models/Order/Order";

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from "@material-ui/core";

interface IChooseDirectionViewProps {
    methodTransports: MethodTransport[];
    onChangeMethodTransport: (value: MethodTransport) => void;
    user: User;
    order: Order;
    minWight: number;
    prefix?: string;
}

@InjectPrefix
@observer
class ChooseMethodTransportView extends React.Component<IChooseDirectionViewProps, {}> {
    protected renderMethodTransports = (): React.ReactNode => {
        const {
                  methodTransports,
                  onChangeMethodTransport,
                  order,
                  minWight,
              }                       = this.props,
              selectedMethodTransport = order.getMethodTransport(),
              direction               = order.getDirection();

        return (
            <FormControl component="fieldset">
                <RadioGroup aria-label="method-transport"
                            name="method-transport"
                            value={order.getMethodTransportId()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                onChangeMethodTransport(methodTransports.find(item => item.getId() === value) as MethodTransport);
                            }}>
                    {
                        methodTransports.map((methodTransport: MethodTransport) => {
                            const valid = (direction && methodTransport.isOptionValid(minWight, order.getDistance()))
                                || methodTransport.getPickUp();

                            return (
                                <FormControlLabel value={methodTransport.getId()}
                                                  control={<Radio/>}
                                                  label={`${methodTransport.getName()}`}
                                                  key={methodTransport.getId()}
                                                  disabled={!valid}
                                />
                            );
                        })
                    }
                </RadioGroup>

            </FormControl>
        );
    };


    public render(): React.ReactNode {
        const {} = this.props;

        return (
            <div>
                <h2 className="title">
                    Seleccion una metodo de transporte
                </h2>
                {this.renderMethodTransports()}
            </div>
        );
    }
}

export default ChooseMethodTransportView;