import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import * as React                         from "react";
import { Row }                            from "reactstrap";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Gallery }                        from "../../../Models/Gallery/Gallery";
import { GalleryItem }                    from "../../../Models/Gallery/GalleryItem";

interface IOurBrandsProps extends WithNamespaces {
    prefix?: string;
    galleryBrands: Gallery | undefined;
}

@InjectPrefix
class OurBrands extends React.Component<IOurBrandsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  galleryBrands
              } = this.props;


        return (
            <>
                <div className="col-12 text-center">
                    <h2 className={`${this.prefix}-tertiary-color ${this.prefix}-secondary-color-3-border`}>
                        {t("our-brands")}
                    </h2>
                </div>
                <Row className="our-brands">

                    {galleryBrands
                     ? galleryBrands.getItems().map((item: GalleryItem, key: number) => {
                            return (
                                <img src={item.getImageFullPath()} alt={"brand-" + item.getImage()}
                                     className="img-fluid mb-4 mb-lg-0 col-6 col-sm-4 col-lg-2"
                                     key={key}
                                />
                            );
                        })
                     : <React.Fragment/>}
                </Row>
            </>
        );
    }
}

export default withNamespaces("page")(OurBrands);
